export function loginUser(token) {
  return {
    type: "LOGIN_USER",
    payload: {
      logged: true,
      token
    }
  };
}

export function logoutUser() {
  return {
    type: "LOGOUT_USER",
    payload: {
      logged: false,
      token: undefined
    }
  };
}
