import React, { Fragment } from "react";
import ChartService from "../../service/ChartServices";
import Chart from "react-apexcharts";
import {
  Vendas,
  VendasPorMoeda,
  MaisVendidos,
  VendasPorHorario,
  VendasDiaSemana,
  MiniHistVendas,
  MiniTicket,
  MiniDescontos
} from "../charts/chartConfig";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import ThreeSixtyIcon from "@material-ui/icons/ThreeSixty";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { connect } from "react-redux";
import { Container } from "../ui/base";
import { NumberBoxes } from "../ui/numberBoxex";
import RgPeriodo from "../ui/rgPeriodo";
import ProductTable from "../ui/productTable";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { isMobile } from "react-device-detect";
import Collapse from "../ui/Collapse";
import moment from "moment";

class PoseidonDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dashboard: this.props.dashboard || {},
      typeDate: "periodo",
      startDate: "2020-03-01",
      endDate: "2020-03-30"
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (props.dashboard !== state.dashboard) {
      return {
        dashboard: props.dashboard
      };
    }
    return null;
  }

  handleChangeStartDate = e => {
    this.setState({
      startDate: e.target.value
    });
  };

  render() {
    const wraperStyle = {
      half: {
        width: "50%",
        float: "left"
      },
      full: {
        width: "98%",
        marginTop: "10px",
        display: "table"
      },
      quarter: {
        width: "23%",
        float: "left",
        marginLeft: "0.5%",
        padding: "5px"
      },
      paper: {
        padding: "10px",
        textAlign: "center",
        color: "#333"
      },
      root: {
        flexGrow: 1
      },
      textField: {
        marginLeft: "0.5%",
        marginRight: "0.5%",
        width: "48%"
      },
      button: {
        boxShadow: "0 0 0 0"
      }
    };

    const style = {
      display: "flex",
      justifyContent: "center",
      fontWeight: "bold",
      //color: "#2b4031",
      fontSmooth: "antialiased",
      fontFamily: "Poppins"
    };
    const italico = {
      display: "flex",
      justifyContent: "center",
      //color: "#2b4031",
      fontSmooth: "antialiased",
      fontFamily: "Consolas",
      fontStyle: "italic"
    };

    return (
      <div style={wraperStyle.root}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h4" style={style}>
                Dashboard
              </Typography>
              <p style={italico}>
                (Última consulta em tela -{" "}
                {moment(this.state.dashboard.updated_at).format(
                  "DD/MM/YYYY [às] HH:mm"
                )}
                )
              </p>
            </Grid>

            <Grid item xs={12} sm={12}>
              <RgPeriodo />
            </Grid>
            <Grid item xs={12} sm={12}>
              <NumberBoxes
                resOpera={this.state.dashboard.resOpera}
                faturamento={this.state.dashboard.fatTotal}
                fatPorCusto={this.state.dashboard.fatPeloCusto}
                ticketMedio={this.state.dashboard.ticketMedio}
                totalDescontos={this.state.dashboard.totalDsc}
                qtdPedidos={this.state.dashboard.qtdPedidos}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Collapse titulo={this.state.dashboard.vendas.options.title.text}>
                <div className="mixed-chart" style={{ width: "100%" }}>
                  <Chart
                    options={this.state.dashboard.vendas.options}
                    series={this.state.dashboard.vendas.series}
                    type="area"
                    width="100%"
                    height="300"
                  />
                </div>
              </Collapse>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Collapse
                titulo={this.state.dashboard.vendasDiaSemana.options.title.text}
              >
                <div className="mixed-chart" style={{ width: "100%" }}>
                  <Chart
                    options={this.state.dashboard.vendasDiaSemana.options}
                    series={this.state.dashboard.vendasDiaSemana.series}
                    type="bar"
                    width="100%"
                    height="400"
                  />
                </div>
              </Collapse>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Collapse
                titulo={
                  this.state.dashboard.vendasPorHorario.options.title.text
                }
              >
                <div className="mixed-chart" style={{ width: "100%" }}>
                  <Chart
                    options={this.state.dashboard.vendasPorHorario.options}
                    series={this.state.dashboard.vendasPorHorario.series}
                    type="bar"
                    width="100%"
                    height="400"
                  />
                </div>
              </Collapse>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Collapse
                titulo={this.state.dashboard.vendasPorMoeda.options.title.text}
              >
                <div className="mixed-chart" style={{ width: "100%" }}>
                  <Chart
                    options={this.state.dashboard.vendasPorMoeda.options}
                    series={this.state.dashboard.vendasPorMoeda.series}
                    type="pie"
                    width="100%"
                    height="300"
                  />
                </div>
              </Collapse>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Collapse
                titulo={this.state.dashboard.maisVendidos.options.title.text}
              >
                <div style={{ width: "100%" }}>
                  <Chart
                    options={this.state.dashboard.maisVendidos.options}
                    series={this.state.dashboard.maisVendidos.series}
                    type="donut"
                    width="100%"
                    height="300"
                  />
                </div>
              </Collapse>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Collapse
                titulo={this.state.dashboard.miniHistVendas.options.title.text}
              >
                <div className="mixed-chart" style={{ width: "100%" }}>
                  <Chart
                    options={this.state.dashboard.miniHistVendas.options}
                    series={this.state.dashboard.miniHistVendas.series}
                    type="bar"
                    width="100%"
                    height="300"
                  />
                </div>
              </Collapse>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Collapse
                titulo={this.state.dashboard.miniTicket.options.title.text}
              >
                <div className="mixed-chart" style={{ width: "100%" }}>
                  <Chart
                    options={this.state.dashboard.miniTicket.options}
                    series={this.state.dashboard.miniTicket.series}
                    type="bar"
                    width="100%"
                    height="300"
                  />
                </div>
              </Collapse>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Collapse
                titulo={this.state.dashboard.miniDescontos.options.title.text}
              >
                <div className="mixed-chart" style={{ width: "100%" }}>
                  <Chart
                    options={this.state.dashboard.miniDescontos.options}
                    series={this.state.dashboard.miniDescontos.series}
                    type="bar"
                    width="100%"
                    height="300"
                  />
                </div>
              </Collapse>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Collapse titulo="Produtos Mais Vendidos (em R$)">
                <ProductTable
                  dados={this.state.dashboard.prodTop10Val}
                  titulo="Produtos Mais Vendidos (em R$)"
                  tipo="moeda"
                />
              </Collapse>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Collapse titulo="Produtos Mais Vendidos (Quantidade)">
                <ProductTable
                  dados={this.state.dashboard.prodTop10Und}
                  titulo="Produtos Mais Vendidos (Quantidade)"
                  tipo="int"
                />
              </Collapse>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  dashboard: state.dashboard
});

export default connect(mapStateToProps)(PoseidonDashboard);
