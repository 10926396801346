import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListResControleComponent() {
  const colunas = [
    { title: "Codigo", field: "codmoeda" },
    { title: "Descrição", field: "descricao" },
    { title: "Tipo", field: "tipo" },
    { title: "Desconto", field: "perDesc" },
    { title: "Compensação", field: "diasComp" },
    { title: "ECF", field: "codecf" },
    { title: "Ver no Fech.", field: "naoVisFech" },
    { title: "Dados Pos", field: "dadosPos" },
    { title: "Baixar Sempre", field: "baixarSempre" },
    { title: "Dias", field: "numdias" }
  ];

  return (
    <div>
      <ListComponent route="moedas" listKeys={colunas} title="Moedas" />
    </div>
  );
}
