export const Vendas = {
  options: {
    chart: {
      id: "chart-vendas",
      toolbar: {
        show: false,
        autoSelected: "zoom"
      }
    },
    noData: {
      text: "Sem dados para exibir.",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 20,
      style: {
        color: "#666",
        fontSize: "14px",
        fontWeight: "bolder",
        fontFamily: "Arial"
      }
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      labels: {
        formatter: function(val) {
          return Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL"
          }).format(val);
        }
      }
    },
    xaxis: {
      type: "datetime",
      categories: [],
      labels: {
        show: true,
        format: "dd/MM",
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "dd MMM",
          hour: "HH:mm"
        }
      }
    },
    title: {
      text: "Evolução de vendas",
      align: "left",
      offsetX: 20,
      style: {
        fontSize: "0",
        fontFamily: "consolas",
        color: "#666"
      },
      show: false
    },
    subtitle: {
      text: "últimos 30 dias",
      align: "left",
      offsetX: 20,
      offsetY: 0,
      style: {
        fontSize: "11px",
        fontFamily: "consolas",
        color: "#999"
      }
    },
    stroke: {
      curve: "smooth" // "smooth" / "straight" / "stepline"
    },
    grid: {
      show: true
    }
  },
  series: []
};

export const VendasPorMoeda = {
  options: {
    chart: {
      id: "basic-pizza"
    },
    noData: {
      text: "Sem dados para exibir.",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 20,
      style: {
        color: "#666",
        fontSize: "14px",
        fontWeight: "bolder",
        fontFamily: "Arial"
      }
    },
    legend: {
      horizontalAlign: "left",
      fontSize: "11px"
    },
    yaxis: {
      labels: {
        formatter: function(val) {
          return Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL"
          }).format(val);
        }
      }
    },
    xaxis: {
      categories: []
    },
    title: {
      text: "Vendas por moeda",
      align: "left",
      offsetX: 0,
      style: {
        fontSize: "0",
        fontFamily: "consolas",
        color: "#666"
      }
    },
    subtitle: {
      text: "últimos 30 dias",
      align: "left",
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "11px",
        fontFamily: "consolas",
        color: "#999"
      }
    },
    stroke: {
      show: true,
      curve: "smooth", // "smooth" / "straight" / "stepline"
      lineCap: "butt", // round, butt , square
      width: 5,
      colors: undefined, // array of colors
      dashArray: 0 // single value or array of values
    },
    grid: {
      show: true
    }
  },
  series: []
};

export const MaisVendidos = {
  options: {
    plotOptions: {
      pie: {
        customScale: 0.9,
        donut: {
          size: "55%"
        }
      }
    },
    noData: {
      text: "Sem dados para exibir.",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 20,
      style: {
        color: "#666",
        fontSize: "14px",
        fontWeight: "bolder",
        fontFamily: "Arial"
      }
    },
    legend: {
      horizontalAlign: "left",
      fontSize: "11px",
      offsetY: 20
    },
    chart: {
      id: "basic-donut"
    },
    yaxis: {
      labels: {
        formatter: function(val) {
          return Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL"
          }).format(val);
        }
      }
    },
    xaxis: {
      categories: []
    },
    title: {
      text: "Grupos de produtos mais vendidos (TOP 10)",
      align: "left",
      offsetX: 0,
      style: {
        fontSize: "0",
        fontFamily: "consolas",
        color: "#666"
      }
    },
    subtitle: {
      text: "últimos 30 dias",
      align: "left",
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "11px",
        fontFamily: "consolas",
        color: "#999"
      }
    },
    stroke: {
      show: true,
      curve: "smooth", // "smooth" / "straight" / "stepline"
      lineCap: "butt", // round, butt , square
      width: 5,
      colors: undefined, // array of colors
      dashArray: 0 // single value or array of values
    },
    grid: {
      show: true
    }
  },
  series: []
};

export const VendasPorHorario = {
  options: {
    chart: {
      id: "basic-line",
      type: "line",
      stacked: false,
      shadow: {
        enabled: true,
        color: "#000",
        top: 3,
        left: 2,
        blur: 3,
        opacity: 1
      },
      noData: {
        text: "Sem dados para exibir.",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 20,
        style: {
          color: "#666",
          fontSize: "14px",
          fontWeight: "bolder",
          fontFamily: "Arial"
        }
      },
      toolbar: {
        show: false,
        autoSelected: "zoom"
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      labels: {
        show: true
      }
    },
    markers: {
      size: 5,
      opacity: 0.9,
      colors: ["#FFA41B"],
      strokeColor: "#fff",
      strokeWidth: 2,

      hover: {
        size: 7
      }
    },
    yaxis: [
      {
        seriesName: "Total em R$",
        axisBorder: {
          show: false
        },
        labels: {
          show: false,
          formatter: function(val) {
            return Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL"
            }).format(val);
          }
        }
      },
      {
        seriesName: "Total de Pedidos",
        axisTicks: {
          show: true
        },
        labels: {
          show: false,
          formatter: function(val) {
            return val;
          }
        }
      }
    ],
    title: {
      text: "Vendas por Hora do Dia",
      align: "left",
      style: {
        fontSize: "0",
        fontFamily: "consolas",
        color: "#666"
      }
    },
    subtitle: {
      text: "últimos 7 dias",
      align: "left",
      offsetY: 0,
      style: {
        fontSize: "11px",
        fontFamily: "consolas",
        color: "#999"
      }
    },
    colors: ["#035efc", "#1f6933"],
    stroke: {
      show: true,
      curve: "straight", // "smooth" / "straight" / "stepline"
      lineCap: "butt", // round, butt , square
      width: 4,
      colors: ["rgba(0, 0, 0, 0)", "#1f6933"], // array of colors
      dashArray: 0 // single value or array of values
    },
    grid: {
      show: true
    }
  },
  series: []
};

export const VendasDiaSemana = {
  options: {
    chart: {
      id: "basic-line",
      stacked: false,
      dropShadow: {
        enabled: false,
        color: "#000",
        top: 3,
        left: 2,
        blur: 3,
        opacity: 1
      },
      toolbar: {
        show: false,
        autoSelected: "zoom"
      }
    },
    noData: {
      text: "Sem dados para exibir.",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#666",
        fontSize: "14px",
        fontWeight: "bolder",
        fontFamily: "Arial"
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 5,
      opacity: 0.9,
      colors: ["#FFA41B"],
      strokeColor: "#fff",
      strokeWidth: 2,

      hover: {
        size: 7
      }
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      labels: {
        show: true
      }
    },
    yaxis: [
      {
        seriesName: "Total em R$",
        axisBorder: {
          show: false
        },
        labels: {
          show: false,
          formatter: function(val) {
            return Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL"
            }).format(val);
          }
        }
      },
      {
        seriesName: "Total de Pedidos",
        axisTicks: {
          show: true
        },
        labels: {
          show: false,
          formatter: function(val) {
            return val;
          }
        }
      }
    ],
    title: {
      text: "Vendas por Dia da Semana",
      align: "left",
      style: {
        fontSize: "0",
        fontFamily: "consolas",
        color: "#666"
      }
    },
    subtitle: {
      text: "últimos 7 dias",
      align: "left",
      style: {
        fontSize: "11px",
        fontFamily: "consolas",
        color: "#999"
      },
      offsetY: 0
    },
    colors: ["#035efc", "#1f6933"],
    stroke: {
      show: true,
      curve: "straight", // "smooth" / "straight" / "stepline"
      lineCap: "butt", // round, butt , square
      width: 4,
      colors: ["rgba(0, 0, 0, 0)", "#1f6933"], // array of colors
      dashArray: 0 // single value or array of values
    },
    grid: {
      show: true
    }
  },
  series: []
};

export const MiniHistVendas = {
  options: {
    chart: {
      id: "hist-vendas",
      stacked: true,
      shadow: {
        enabled: true,
        color: "#000",
        top: 3,
        left: 2,
        blur: 3,
        opacity: 1
      },
      toolbar: {
        show: false,
        autoSelected: "zoom"
      }
    },
    noData: {
      text: "Sem dados para exibir.",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 20,
      style: {
        color: "#666",
        fontSize: "14px",
        fontWeight: "bolder",
        fontFamily: "Arial"
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 4,
      opacity: 0.9,
      colors: ["#FFA41B"],
      strokeColor: "#fff",
      strokeWidth: 2,

      hover: {
        size: 7
      }
    },
    yaxis: {
      labels: {
        show: false,
        formatter: function(val) {
          return Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL"
          }).format(val);
        }
      }
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      labels: {
        show: true
      }
    },
    title: {
      text: "Histórico de Vendas",
      align: "left",
      style: {
        fontSize: "0",
        fontFamily: "consolas",
        color: "#666"
      }
    },
    subtitle: {
      text: "últimos 7 dias",
      align: "left",
      style: {
        fontSize: "11px",
        fontFamily: "consolas",
        color: "#999"
      },
      offsetY: 0
    },
    stroke: {
      show: false,
      curve: "smooth", // "smooth" / "straight" / "stepline"
      lineCap: "butt", // round, butt , square
      width: 5,
      colors: ["#1f6933"], // array of colors
      dashArray: 0 // single value or array of values
    },
    grid: {
      show: true
    }
  },
  series: [
    {
      name: "Total"
    }
  ]
};

export const MiniTicket = {
  options: {
    chart: {
      id: "hist-ticket-med",
      stacked: true,
      shadow: {
        enabled: true,
        color: "#000",
        top: 3,
        left: 2,
        blur: 3,
        opacity: 1
      },
      toolbar: {
        show: false,
        autoSelected: "zoom"
      }
    },
    noData: {
      text: "Sem dados para exibir.",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 20,
      style: {
        color: "#666",
        fontSize: "14px",
        fontWeight: "bolder",
        fontFamily: "Arial"
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 4,
      opacity: 0.9,
      colors: ["#FFA41B"],
      strokeColor: "#fff",
      strokeWidth: 2,

      hover: {
        size: 7
      }
    },
    yaxis: {
      labels: {
        show: false,
        formatter: function(val) {
          return Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL"
          }).format(val);
        }
      }
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      labels: {
        show: true
      }
    },
    title: {
      text: "Histórico de Ticket Médio",
      align: "left",
      style: {
        fontSize: "0",
        fontFamily: "consolas",
        color: "#666"
      }
    },
    subtitle: {
      text: "últimos 7 dias",
      align: "left",
      style: {
        fontSize: "11px",
        fontFamily: "consolas",
        color: "#999"
      },
      offsetY: 0
    },
    stroke: {
      show: false,
      curve: "smooth", // "smooth" / "straight" / "stepline"
      lineCap: "butt", // round, butt , square
      width: 5,
      colors: ["#1f6933"], // array of colors
      dashArray: 0 // single value or array of values
    },
    grid: {
      show: true
    }
  },
  series: [
    {
      name: "Ticket Médio"
    }
  ]
};

export const MiniDescontos = {
  options: {
    chart: {
      id: "hist-descontos",
      stacked: true,
      shadow: {
        enabled: true,
        color: "#000",
        top: 3,
        left: 2,
        blur: 3,
        opacity: 1
      },
      toolbar: {
        show: false,
        autoSelected: "zoom"
      }
    },
    noData: {
      text: "Sem dados para exibir.",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 20,
      style: {
        color: "#666",
        fontSize: "14px",
        fontWeight: "bolder",
        fontFamily: "Arial"
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 4,
      opacity: 0.9,
      colors: ["#FFA41B"],
      strokeColor: "#fff",
      strokeWidth: 2,

      hover: {
        size: 7
      }
    },
    yaxis: {
      labels: {
        show: false,
        formatter: function(val) {
          return Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL"
          }).format(val);
        }
      }
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      labels: {
        show: true
      }
    },
    title: {
      text: "Histórico de Descontos",
      align: "left",
      style: {
        fontSize: "0",
        fontFamily: "consolas",
        color: "#666"
      }
    },
    subtitle: {
      text: "últimos 7 dias",
      align: "left",
      style: {
        fontSize: "11px",
        fontFamily: "consolas",
        color: "#999"
      },
      offsetY: 0
    },
    stroke: {
      show: false,
      curve: "smooth", // "smooth" / "straight" / "stepline"
      lineCap: "butt", // round, butt , square
      width: 5,
      colors: ["#1f6933"], // array of colors
      dashArray: 0 // single value or array of values
    },
    grid: {
      show: true
    }
  },
  series: [
    {
      name: "Descontos"
    }
  ]
};
