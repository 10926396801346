export default function cadastros(state = [], action) {
  switch (action.type) {
    case "@cadastro/SUCCESS":
      return {
        ...state,
        lista: action.lista
      };

    case "@cadastro/ADD_SUCCESS":
      return {
        ...state,
        lista: action.lista
      };

    case "@cadastro/EDIT_SET":
      return {
        ...state,
        rota: action.rota,
        id: action.id,
        cadastro: action.cadastro
      };

    case "@cadastro/EDIT_SUCCESS":
      return {
        ...state,
        status: action.status,
        messagem: action.message
      };

    default:
      return state;
  }
}
