import React, { Component } from "react";
import PedvendaItemService from "../../service/PedvendaItemService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddPedvendaItemComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codpedvenda: "",
      codproduto: "",
      tipopreco: "",
      qtde: "",
      descvlr: "",
      vlrunit: "",
      codpesquisa: "",
      item: "",
      qtdeFat: "",
      origem: "",
      qtdeEntr: "",
      paciente: "",
      pedi: "",
      qtdeUnd: "",
      vlrUnd: "",
      custoProduto: "",
      perdesc: "",
      totalDesconto: "",
      codcontrole: "",
      tmp: "",
      status: "",
      descPropPed: "",
      vlrComissao1: "",
      vlrComissao2: "",
      trocaPropPed: "",
      antVlrComis1: "",
      antVlrComis2: "",
      vlrdif: "",
      acrescPropPed: "",
      vlr2: "",
      numlote: "",
      medico: "",
      medicoRp: "",
      medicoRpTipo: "",
      medicoRpUf: "",
      receitaDt: "",
      receitaTp: "",
      receitaNr: "",
      recNome: "",
      recId: "",
      recOrg: "",
      recUf: "",
      obs: "",
      statusEntrega: "",
      codpessoa: "",
      acrescvlr: "",
      peracresc: "",
      totalAcrescimo: "",
      revisao: "",
      codempresa: "",
      pendCompraGerada: "",
      preparacao: "",
      precoCompra: "",
      ultPrecoCompra: "",
      ultCusto: "",
      antCalcPrecoCompra: "",
      antCalcCustoProduto: "",
      aliqIpi: "",
      vlrIpi: "",
      aliqIcms: "",
      vlrIcms: "",
      bcIcms: "",
      bcIpi: "",
      entrega: "",
      codvendedor: "",
      codcfop: "",
      estoqueBaixado: "",
      receitaPacNome: "",
      receitaPacIdade: "",
      receitaPacSexo: "",
      qtdeReserva: "",
      qtdeCompra: "",
      perReduc: "",
      dataEntrega: "",
      reservaOk: "",
      fornecedor: "",
      codnfvendaitem: "",
      codpedcompraitem: "",
      person: "",
      message: null
    };
    this.savePedvendaItem = this.savePedvendaItem.bind(this);
  }

  savePedvendaItem = e => {
    e.preventDefault();
    let pedvendaItems = {
      codpedvenda: this.state.codpedvenda,
      codproduto: this.state.codproduto,
      tipopreco: this.state.tipopreco,
      qtde: this.state.qtde,
      descvlr: this.state.descvlr,
      vlrunit: this.state.vlrunit,
      codpesquisa: this.state.codpesquisa,
      item: this.state.item,
      qtdeFat: this.state.qtdeFat,
      origem: this.state.origem,
      qtdeEntr: this.state.qtdeEntr,
      paciente: this.state.paciente,
      pedi: this.state.pedi,
      qtdeUnd: this.state.qtdeUnd,
      vlrUnd: this.state.vlrUnd,
      custoProduto: this.state.custoProduto,
      perdesc: this.state.perdesc,
      totalDesconto: this.state.totalDesconto,
      codcontrole: this.state.codcontrole,
      tmp: this.state.tmp,
      status: this.state.status,
      descPropPed: this.state.descPropPed,
      vlrComissao1: this.state.vlrComissao1,
      vlrComissao2: this.state.vlrComissao2,
      trocaPropPed: this.state.trocaPropPed,
      antVlrComis1: this.state.antVlrComis1,
      antVlrComis2: this.state.antVlrComis2,
      vlrdif: this.state.vlrdif,
      acrescPropPed: this.state.acrescPropPed,
      vlr2: this.state.vlr2,
      numlote: this.state.numlote,
      medico: this.state.medico,
      medicoRp: this.state.medicoRp,
      medicoRpTipo: this.state.medicoRpTipo,
      medicoRpUf: this.state.medicoRpUf,
      receitaDt: this.state.receitaDt,
      receitaTp: this.state.receitaTp,
      receitaNr: this.state.receitaNr,
      recNome: this.state.recNome,
      recId: this.state.recId,
      recOrg: this.state.recOrg,
      recUf: this.state.recUf,
      obs: this.state.obs,
      statusEntrega: this.state.statusEntrega,
      codpessoa: this.state.codpessoa,
      acrescvlr: this.state.acrescvlr,
      peracresc: this.state.peracresc,
      totalAcrescimo: this.state.totalAcrescimo,
      revisao: this.state.revisao,
      codempresa: this.state.codempresa,
      pendCompraGerada: this.state.pendCompraGerada,
      preparacao: this.state.preparacao,
      precoCompra: this.state.precoCompra,
      ultPrecoCompra: this.state.ultPrecoCompra,
      ultCusto: this.state.ultCusto,
      antCalcPrecoCompra: this.state.antCalcPrecoCompra,
      antCalcCustoProduto: this.state.antCalcCustoProduto,
      aliqIpi: this.state.aliqIpi,
      vlrIpi: this.state.vlrIpi,
      aliqIcms: this.state.aliqIcms,
      vlrIcms: this.state.vlrIcms,
      bcIcms: this.state.bcIcms,
      bcIpi: this.state.bcIpi,
      entrega: this.state.entrega,
      codvendedor: this.state.codvendedor,
      codcfop: this.state.codcfop,
      estoqueBaixado: this.state.estoqueBaixado,
      receitaPacNome: this.state.receitaPacNome,
      receitaPacIdade: this.state.receitaPacIdade,
      receitaPacSexo: this.state.receitaPacSexo,
      qtdeReserva: this.state.qtdeReserva,
      qtdeCompra: this.state.qtdeCompra,
      perReduc: this.state.perReduc,
      dataEntrega: this.state.dataEntrega,
      reservaOk: this.state.reservaOk,
      fornecedor: this.state.fornecedor,
      codnfvendaitem: this.state.codnfvendaitem,
      codpedcompraitem: this.state.codpedcompraitem,
      person: this.state.person,
    };
    PedvendaItemService.addPedvendaItem(pedvendaItems).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/pedvendaitems");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar PedvendaItem
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="codpedvenda"
	            fullWidth
	            margin="normal"
	            name="codpedvenda"
	            value={this.state.codpedvenda}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codproduto"
	            fullWidth
	            margin="normal"
	            name="codproduto"
	            value={this.state.codproduto}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tipopreco"
	            fullWidth
	            margin="normal"
	            name="tipopreco"
	            value={this.state.tipopreco}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="qtde"
	            fullWidth
	            margin="normal"
	            name="qtde"
	            value={this.state.qtde}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="descvlr"
	            fullWidth
	            margin="normal"
	            name="descvlr"
	            value={this.state.descvlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrunit"
	            fullWidth
	            margin="normal"
	            name="vlrunit"
	            value={this.state.vlrunit}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codpesquisa"
	            fullWidth
	            margin="normal"
	            name="codpesquisa"
	            value={this.state.codpesquisa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="item"
	            fullWidth
	            margin="normal"
	            name="item"
	            value={this.state.item}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="qtdeFat"
	            fullWidth
	            margin="normal"
	            name="qtdeFat"
	            value={this.state.qtdeFat}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="origem"
	            fullWidth
	            margin="normal"
	            name="origem"
	            value={this.state.origem}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="qtdeEntr"
	            fullWidth
	            margin="normal"
	            name="qtdeEntr"
	            value={this.state.qtdeEntr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="paciente"
	            fullWidth
	            margin="normal"
	            name="paciente"
	            value={this.state.paciente}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="pedi"
	            fullWidth
	            margin="normal"
	            name="pedi"
	            value={this.state.pedi}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="qtdeUnd"
	            fullWidth
	            margin="normal"
	            name="qtdeUnd"
	            value={this.state.qtdeUnd}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrUnd"
	            fullWidth
	            margin="normal"
	            name="vlrUnd"
	            value={this.state.vlrUnd}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="custoProduto"
	            fullWidth
	            margin="normal"
	            name="custoProduto"
	            value={this.state.custoProduto}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="perdesc"
	            fullWidth
	            margin="normal"
	            name="perdesc"
	            value={this.state.perdesc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="totalDesconto"
	            fullWidth
	            margin="normal"
	            name="totalDesconto"
	            value={this.state.totalDesconto}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codcontrole"
	            fullWidth
	            margin="normal"
	            name="codcontrole"
	            value={this.state.codcontrole}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tmp"
	            fullWidth
	            margin="normal"
	            name="tmp"
	            value={this.state.tmp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="status"
	            fullWidth
	            margin="normal"
	            name="status"
	            value={this.state.status}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="descPropPed"
	            fullWidth
	            margin="normal"
	            name="descPropPed"
	            value={this.state.descPropPed}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrComissao1"
	            fullWidth
	            margin="normal"
	            name="vlrComissao1"
	            value={this.state.vlrComissao1}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrComissao2"
	            fullWidth
	            margin="normal"
	            name="vlrComissao2"
	            value={this.state.vlrComissao2}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="trocaPropPed"
	            fullWidth
	            margin="normal"
	            name="trocaPropPed"
	            value={this.state.trocaPropPed}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="antVlrComis1"
	            fullWidth
	            margin="normal"
	            name="antVlrComis1"
	            value={this.state.antVlrComis1}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="antVlrComis2"
	            fullWidth
	            margin="normal"
	            name="antVlrComis2"
	            value={this.state.antVlrComis2}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrdif"
	            fullWidth
	            margin="normal"
	            name="vlrdif"
	            value={this.state.vlrdif}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="acrescPropPed"
	            fullWidth
	            margin="normal"
	            name="acrescPropPed"
	            value={this.state.acrescPropPed}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlr2"
	            fullWidth
	            margin="normal"
	            name="vlr2"
	            value={this.state.vlr2}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="numlote"
	            fullWidth
	            margin="normal"
	            name="numlote"
	            value={this.state.numlote}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="medico"
	            fullWidth
	            margin="normal"
	            name="medico"
	            value={this.state.medico}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="medicoRp"
	            fullWidth
	            margin="normal"
	            name="medicoRp"
	            value={this.state.medicoRp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="medicoRpTipo"
	            fullWidth
	            margin="normal"
	            name="medicoRpTipo"
	            value={this.state.medicoRpTipo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="medicoRpUf"
	            fullWidth
	            margin="normal"
	            name="medicoRpUf"
	            value={this.state.medicoRpUf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="receitaDt"
	            fullWidth
	            margin="normal"
	            name="receitaDt"
	            value={this.state.receitaDt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="receitaTp"
	            fullWidth
	            margin="normal"
	            name="receitaTp"
	            value={this.state.receitaTp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="receitaNr"
	            fullWidth
	            margin="normal"
	            name="receitaNr"
	            value={this.state.receitaNr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="recNome"
	            fullWidth
	            margin="normal"
	            name="recNome"
	            value={this.state.recNome}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="recId"
	            fullWidth
	            margin="normal"
	            name="recId"
	            value={this.state.recId}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="recOrg"
	            fullWidth
	            margin="normal"
	            name="recOrg"
	            value={this.state.recOrg}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="recUf"
	            fullWidth
	            margin="normal"
	            name="recUf"
	            value={this.state.recUf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="obs"
	            fullWidth
	            margin="normal"
	            name="obs"
	            value={this.state.obs}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="statusEntrega"
	            fullWidth
	            margin="normal"
	            name="statusEntrega"
	            value={this.state.statusEntrega}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codpessoa"
	            fullWidth
	            margin="normal"
	            name="codpessoa"
	            value={this.state.codpessoa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="acrescvlr"
	            fullWidth
	            margin="normal"
	            name="acrescvlr"
	            value={this.state.acrescvlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="peracresc"
	            fullWidth
	            margin="normal"
	            name="peracresc"
	            value={this.state.peracresc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="totalAcrescimo"
	            fullWidth
	            margin="normal"
	            name="totalAcrescimo"
	            value={this.state.totalAcrescimo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="revisao"
	            fullWidth
	            margin="normal"
	            name="revisao"
	            value={this.state.revisao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codempresa"
	            fullWidth
	            margin="normal"
	            name="codempresa"
	            value={this.state.codempresa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="pendCompraGerada"
	            fullWidth
	            margin="normal"
	            name="pendCompraGerada"
	            value={this.state.pendCompraGerada}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="preparacao"
	            fullWidth
	            margin="normal"
	            name="preparacao"
	            value={this.state.preparacao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="precoCompra"
	            fullWidth
	            margin="normal"
	            name="precoCompra"
	            value={this.state.precoCompra}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="ultPrecoCompra"
	            fullWidth
	            margin="normal"
	            name="ultPrecoCompra"
	            value={this.state.ultPrecoCompra}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="ultCusto"
	            fullWidth
	            margin="normal"
	            name="ultCusto"
	            value={this.state.ultCusto}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="antCalcPrecoCompra"
	            fullWidth
	            margin="normal"
	            name="antCalcPrecoCompra"
	            value={this.state.antCalcPrecoCompra}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="antCalcCustoProduto"
	            fullWidth
	            margin="normal"
	            name="antCalcCustoProduto"
	            value={this.state.antCalcCustoProduto}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="aliqIpi"
	            fullWidth
	            margin="normal"
	            name="aliqIpi"
	            value={this.state.aliqIpi}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrIpi"
	            fullWidth
	            margin="normal"
	            name="vlrIpi"
	            value={this.state.vlrIpi}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="aliqIcms"
	            fullWidth
	            margin="normal"
	            name="aliqIcms"
	            value={this.state.aliqIcms}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrIcms"
	            fullWidth
	            margin="normal"
	            name="vlrIcms"
	            value={this.state.vlrIcms}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="bcIcms"
	            fullWidth
	            margin="normal"
	            name="bcIcms"
	            value={this.state.bcIcms}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="bcIpi"
	            fullWidth
	            margin="normal"
	            name="bcIpi"
	            value={this.state.bcIpi}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="entrega"
	            fullWidth
	            margin="normal"
	            name="entrega"
	            value={this.state.entrega}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codvendedor"
	            fullWidth
	            margin="normal"
	            name="codvendedor"
	            value={this.state.codvendedor}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codcfop"
	            fullWidth
	            margin="normal"
	            name="codcfop"
	            value={this.state.codcfop}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="estoqueBaixado"
	            fullWidth
	            margin="normal"
	            name="estoqueBaixado"
	            value={this.state.estoqueBaixado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="receitaPacNome"
	            fullWidth
	            margin="normal"
	            name="receitaPacNome"
	            value={this.state.receitaPacNome}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="receitaPacIdade"
	            fullWidth
	            margin="normal"
	            name="receitaPacIdade"
	            value={this.state.receitaPacIdade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="receitaPacSexo"
	            fullWidth
	            margin="normal"
	            name="receitaPacSexo"
	            value={this.state.receitaPacSexo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="qtdeReserva"
	            fullWidth
	            margin="normal"
	            name="qtdeReserva"
	            value={this.state.qtdeReserva}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="qtdeCompra"
	            fullWidth
	            margin="normal"
	            name="qtdeCompra"
	            value={this.state.qtdeCompra}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="perReduc"
	            fullWidth
	            margin="normal"
	            name="perReduc"
	            value={this.state.perReduc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataEntrega"
	            fullWidth
	            margin="normal"
	            name="dataEntrega"
	            value={this.state.dataEntrega}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="reservaOk"
	            fullWidth
	            margin="normal"
	            name="reservaOk"
	            value={this.state.reservaOk}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="fornecedor"
	            fullWidth
	            margin="normal"
	            name="fornecedor"
	            value={this.state.fornecedor}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codnfvendaitem"
	            fullWidth
	            margin="normal"
	            name="codnfvendaitem"
	            value={this.state.codnfvendaitem}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codpedcompraitem"
	            fullWidth
	            margin="normal"
	            name="codpedcompraitem"
	            value={this.state.codpedcompraitem}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="person"
	            fullWidth
	            margin="normal"
	            name="person"
	            value={this.state.person}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.savePedvendaItem}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddPedvendaItemComponent);
