import React, { Component } from "react";
import ProdutoGrupoService from "../../service/ProdutoGrupoService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddProdutoGrupoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descricao: "",
      ipiPadrao: "",
      icmsPadrao: "",
      grau1: "",
      grau2: "",
      grau3: "",
      grau4: "",
      grau5: "",
      grau6: "",
      ultimoNivel: "",
      numeracao: "",
      img: "",
      cor: "",
      ordem: "",
      desativarTouch: "",
      message: null
    };
    this.saveProdutoGrupo = this.saveProdutoGrupo.bind(this);
  }

  saveProdutoGrupo = e => {
    e.preventDefault();
    let produtoGrupos = {
      descricao: this.state.descricao,
      ipiPadrao: this.state.ipiPadrao,
      icmsPadrao: this.state.icmsPadrao,
      grau1: this.state.grau1,
      grau2: this.state.grau2,
      grau3: this.state.grau3,
      grau4: this.state.grau4,
      grau5: this.state.grau5,
      grau6: this.state.grau6,
      ultimoNivel: this.state.ultimoNivel,
      numeracao: this.state.numeracao,
      img: this.state.img,
      cor: this.state.cor,
      ordem: this.state.ordem,
      desativarTouch: this.state.desativarTouch,
    };
    ProdutoGrupoService.addProdutoGrupo(produtoGrupos).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/produtogrupos");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar ProdutoGrupo
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="descricao"
	            fullWidth
	            margin="normal"
	            name="descricao"
	            value={this.state.descricao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="ipiPadrao"
	            fullWidth
	            margin="normal"
	            name="ipiPadrao"
	            value={this.state.ipiPadrao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="icmsPadrao"
	            fullWidth
	            margin="normal"
	            name="icmsPadrao"
	            value={this.state.icmsPadrao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="grau1"
	            fullWidth
	            margin="normal"
	            name="grau1"
	            value={this.state.grau1}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="grau2"
	            fullWidth
	            margin="normal"
	            name="grau2"
	            value={this.state.grau2}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="grau3"
	            fullWidth
	            margin="normal"
	            name="grau3"
	            value={this.state.grau3}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="grau4"
	            fullWidth
	            margin="normal"
	            name="grau4"
	            value={this.state.grau4}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="grau5"
	            fullWidth
	            margin="normal"
	            name="grau5"
	            value={this.state.grau5}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="grau6"
	            fullWidth
	            margin="normal"
	            name="grau6"
	            value={this.state.grau6}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ultimoNivel"
	            fullWidth
	            margin="normal"
	            name="ultimoNivel"
	            value={this.state.ultimoNivel}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="numeracao"
	            fullWidth
	            margin="normal"
	            name="numeracao"
	            value={this.state.numeracao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="img"
	            fullWidth
	            margin="normal"
	            name="img"
	            value={this.state.img}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cor"
	            fullWidth
	            margin="normal"
	            name="cor"
	            value={this.state.cor}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ordem"
	            fullWidth
	            margin="normal"
	            name="ordem"
	            value={this.state.ordem}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="desativarTouch"
	            fullWidth
	            margin="normal"
	            name="desativarTouch"
	            value={this.state.desativarTouch}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.saveProdutoGrupo}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddProdutoGrupoComponent);
