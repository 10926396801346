import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Container } from "../ui/base";

import * as CadastrosActions from "../../redux/actions/cadastros";

export default function EditComponent({ title, data, colunas }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const id = useSelector(state => state.cadastros.id);
  const route = useSelector(state => state.cadastros.rota);
  const cadEdited = useSelector(state => state.cadastros.status);
  const cadEditedMessage = useSelector(state => state.cadastros.message);

  const [dados, setDados] = useState(data);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (cadEdited === true) {
      history.push("/users");
    } else if (cadEdited === false) {
      setMessage(cadEditedMessage);
    } else {
      setMessage("");
    }
  }, [cadEdited]);

  const styles = {
    style: {
      display: "flex",
      justifyContent: "center"
    },
    grid: {
      maxWidth: "600px",
      margin: "30px auto"
    },
    card: {
      minWidth: 275,
      maxWidth: 600,
      margin: "20px auto 10px",
      padding: "10px",
      background: "#FFF"
    },
    textField: {
      backgroundColor: "none",
      width: "95%",
      margin: "20px 10px"
    },
    formContainer: {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "flex-end"
    },
    button: {
      margin: "10px",
      width: "95%",
      backgroundColor: "#5a9495",
      maxWidth: "375px"
    }
  };

  const lista = colunas.map((item, key) => {
    return (
      <TextField
        type="text"
        label={item.title}
        fullWidth
        margin="normal"
        style={styles.textField}
        name={item.field}
        value={dados[item.field]}
        onChange={e => onChange(e)}
      />
    );
  });

  function onChange(e) {
    setDados({
      ...dados,
      [e.target.name]: e.target.value
    });
  }

  function save() {
    dispatch(CadastrosActions.alteraCadastroRequest(route, id, dados));
  }

  return (
    <div>
      <Container>
        <Typography variant="h4" style={styles.style}>
          Editando {title}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} style={styles.grid}>
            <Card style={styles.card}>
              <form style={styles.formContainer}>
                {lista}
                <Box display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    style={styles.button}
                    color="primary"
                    onClick={() => save()}
                  >
                    Salvar
                  </Button>
                </Box>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
