import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class BancoService {
  fetchBancos() {
    return axios.get(config.baseURL + '/bancos/', Headers);
  }

  fetchBancoById(codbanco) {
    return axios.get(config.baseURL + '/bancos/' + codbanco, Headers);
  }

  deleteBanco(codbanco) {
    return axios.delete(config.baseURL + '/bancos/' + codbanco, Headers);
  }

  addBanco(banco) {
    return axios.post(config.baseURL + '/bancos/', banco, Headers);
  }

  editBanco(banco) {
    return axios.put(config.baseURL + '/bancos/' + banco.codbanco, banco, Headers);
  }
}

export default new BancoService();