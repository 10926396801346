import React, { Component } from "react";
import BancoService from "../../service/BancoService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddBancoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descricao: "",
      saldoInicial: "",
      saldoAtual: "",
      contas: "",
      revisao: "",
      agencia: "",
      operacao: "",
      conta: "",
      dataInicial: "",
      saldoConciliado: "",
      saldoExtrato: "",
      dataExtrato: "",
      ativo: "",
      message: null
    };
    this.saveBanco = this.saveBanco.bind(this);
  }

  saveBanco = e => {
    e.preventDefault();
    let bancos = {
      descricao: this.state.descricao,
      saldoInicial: this.state.saldoInicial,
      saldoAtual: this.state.saldoAtual,
      contas: this.state.contas,
      revisao: this.state.revisao,
      agencia: this.state.agencia,
      operacao: this.state.operacao,
      conta: this.state.conta,
      dataInicial: this.state.dataInicial,
      saldoConciliado: this.state.saldoConciliado,
      saldoExtrato: this.state.saldoExtrato,
      dataExtrato: this.state.dataExtrato,
      ativo: this.state.ativo,
    };
    BancoService.addBanco(bancos).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/bancos");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar Banco
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="descricao"
	            fullWidth
	            margin="normal"
	            name="descricao"
	            value={this.state.descricao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="saldoInicial"
	            fullWidth
	            margin="normal"
	            name="saldoInicial"
	            value={this.state.saldoInicial}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="saldoAtual"
	            fullWidth
	            margin="normal"
	            name="saldoAtual"
	            value={this.state.saldoAtual}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="contas"
	            fullWidth
	            margin="normal"
	            name="contas"
	            value={this.state.contas}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="revisao"
	            fullWidth
	            margin="normal"
	            name="revisao"
	            value={this.state.revisao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="agencia"
	            fullWidth
	            margin="normal"
	            name="agencia"
	            value={this.state.agencia}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="operacao"
	            fullWidth
	            margin="normal"
	            name="operacao"
	            value={this.state.operacao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="conta"
	            fullWidth
	            margin="normal"
	            name="conta"
	            value={this.state.conta}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataInicial"
	            fullWidth
	            margin="normal"
	            name="dataInicial"
	            value={this.state.dataInicial}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="saldoConciliado"
	            fullWidth
	            margin="normal"
	            name="saldoConciliado"
	            value={this.state.saldoConciliado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="saldoExtrato"
	            fullWidth
	            margin="normal"
	            name="saldoExtrato"
	            value={this.state.saldoExtrato}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataExtrato"
	            fullWidth
	            margin="normal"
	            name="dataExtrato"
	            value={this.state.dataExtrato}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ativo"
	            fullWidth
	            margin="normal"
	            name="ativo"
	            value={this.state.ativo}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.saveBanco}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddBancoComponent);
