import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditComponent from "../ui/EditComponent";

export default function EditConfiguracaoGeralComponent() {
  const history = useHistory();
  const dadosRedux = useSelector(state => state.cadastros.cadastro);
  const dadosStorage = JSON.parse(localStorage.getItem("configuracaogerals"));

  const colunas = [
    { title: "chave", field: "chave" },
    { title: "descricao", field: "descricao" },
    { title: "vlr", field: "vlr" },
    { title: "tipo", field: "tipo" },
    { title: "funcionalidade", field: "funcionalidade" },
    { title: "md5", field: "md5" }
  ];

  var retorno;

  if (!dadosRedux && !dadosStorage) {
    retorno = <></>;
    history.push("configuracaogerals");
  } else {
    retorno = (
      <div>
        <EditComponent
          title="Configuração Geral"
          data={dadosRedux || dadosStorage}
          colunas={colunas}
        />
      </div>
    );
  }

  return retorno;
}
