import React, { Component } from "react";
import FormapagamentoService from "../../service/FormapagamentoService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddFormapagamentoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descricao: "",
      parcelas: "",
      numdias: "",
      numdiasDemais: "",
      codmoeda: "",
      perAcres: "",
      separar: "",
      vrp: "",
      tipopreco: "",
      vlrMin: "",
      perDesc: "",
      message: null
    };
    this.saveFormapagamento = this.saveFormapagamento.bind(this);
  }

  saveFormapagamento = e => {
    e.preventDefault();
    let formapagamentos = {
      descricao: this.state.descricao,
      parcelas: this.state.parcelas,
      numdias: this.state.numdias,
      numdiasDemais: this.state.numdiasDemais,
      codmoeda: this.state.codmoeda,
      perAcres: this.state.perAcres,
      separar: this.state.separar,
      vrp: this.state.vrp,
      tipopreco: this.state.tipopreco,
      vlrMin: this.state.vlrMin,
      perDesc: this.state.perDesc,
    };
    FormapagamentoService.addFormapagamento(formapagamentos).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/formapagamentos");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar Formapagamento
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="descricao"
	            fullWidth
	            margin="normal"
	            name="descricao"
	            value={this.state.descricao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="parcelas"
	            fullWidth
	            margin="normal"
	            name="parcelas"
	            value={this.state.parcelas}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="numdias"
	            fullWidth
	            margin="normal"
	            name="numdias"
	            value={this.state.numdias}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="numdiasDemais"
	            fullWidth
	            margin="normal"
	            name="numdiasDemais"
	            value={this.state.numdiasDemais}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codmoeda"
	            fullWidth
	            margin="normal"
	            name="codmoeda"
	            value={this.state.codmoeda}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="perAcres"
	            fullWidth
	            margin="normal"
	            name="perAcres"
	            value={this.state.perAcres}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="separar"
	            fullWidth
	            margin="normal"
	            name="separar"
	            value={this.state.separar}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="vrp"
	            fullWidth
	            margin="normal"
	            name="vrp"
	            value={this.state.vrp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tipopreco"
	            fullWidth
	            margin="normal"
	            name="tipopreco"
	            value={this.state.tipopreco}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrMin"
	            fullWidth
	            margin="normal"
	            name="vlrMin"
	            value={this.state.vlrMin}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="perDesc"
	            fullWidth
	            margin="normal"
	            name="perDesc"
	            value={this.state.perDesc}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.saveFormapagamento}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddFormapagamentoComponent);
