import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import { Container } from "../ui/base";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { bindActionCreators } from "redux";
import * as UserActions from "../../redux/actions/user";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import InputMask from "react-input-mask";

import { connect } from "react-redux";

function AdicionaUsuario({ addUserRequest, userAdded, userAddedMessage }) {
  const [pass, setPass] = useState("");
  const [cell, setCell] = useState("");
  const [login, setLogin] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");

  function handleChangeLogin(e) {
    setLogin(e.target.value);
  }
  function handleChangeCell(e) {
    setCell(e.target.value);
  }
  function handleChangePass(e) {
    setPass(e.target.value);
  }
  function handleChangeName(e) {
    setName(e.target.value);
  }
  function handleChangeCell(e) {
    setCell(e.target.value);
  }

  function justDoIt() {
    if (login === "") {
      setMessage("Digite seu login.");
      return;
    }
    if (pass.length < 3) {
      setMessage("A senha deve conter pelo menos 3 caracteres");
      return;
    }
    if (cell.length <= 9) {
      setMessage("Número de celular inválido.");
      return;
    }
    handleAdicionaUsuario();
  }

  const history = useHistory();

  useEffect(() => {
    if (userAdded === true) {
      history.push("/users");
    } else if (userAdded === false) {
      setMessage(userAddedMessage);
    } else {
      setMessage("");
    }
  }, [userAdded]);

  const handleAdicionaUsuario = async () => {
    const body = {
      name,
      login,
      cellphone: cell
        .replace("(", "")
        .replace(")", "")
        .replace(/\s+/g, ""),
      password: pass
    };
    addUserRequest(body);
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h3" style={styles.style}>
            Adicionar Usuário
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Card style={styles.card}>
            <CardContent>
              <form>
                <input type="hidden" value="prayer" />
                <TextField
                  style={styles.textField}
                  type="text"
                  label="E-MAIL"
                  margin="normal"
                  name="livmealone"
                  autoComplete="new-password"
                  value={login}
                  onChange={handleChangeLogin}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Email do usuário"
                />
                <TextField
                  style={styles.textField}
                  type="text"
                  label="NOME"
                  margin="normal"
                  name="livmealone"
                  autoComplete="new-password"
                  value={name}
                  onChange={handleChangeName}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Nome do usuário"
                />
                <InputMask
                  mask="(99) 99999 9999"
                  value={cell}
                  onChange={handleChangeCell}
                  style={styles.textField}
                >
                  {() => (
                    <TextField
                      label="CELULAR"
                      name="livmealone"
                      style={styles.textField}
                      margin="normal"
                      type="text"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIphoneIcon />
                          </InputAdornment>
                        )
                      }}
                      placeholder="Número do celular"
                    />
                  )}
                </InputMask>
                <TextField
                  style={styles.textField}
                  type="text"
                  label="SENHA"
                  margin="normal"
                  name="livmealone"
                  autoComplete="new-password"
                  value={pass}
                  onChange={handleChangePass}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeyIcon />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Escolha uma senha"
                />
                <Box display="flex" justifyContent="center"></Box>

                <h5 style={styles.notification}>{message}</h5>
                <Box display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => justDoIt()}
                    style={styles.button}
                    size="large"
                  >
                    CRIAR USUÁRIO
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

const styles = {
  titCard: {
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: "Poppins"
  },
  style: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Poppins",
    fontWeight: "bold",
    margin: "50px auto 10px",
    color: "#5a9495"
  },
  center: {
    display: "flex",
    justifyContent: "center"
  },
  button: {
    margin: "10px",
    width: "95%",
    backgroundColor: "#5a9495",
    maxWidth: "375px"
  },
  buttonToken: {
    width: "30%",
    height: "100%",
    backgroundColor: "#5a9495",
    maxWidth: "375px",
    margin: "auto"
  },
  notification: {
    display: "flex",
    justifyContent: "center",
    color: "#dc3545",
    minHeight: "10px"
  },
  card: {
    minWidth: 275,
    maxWidth: 600,
    margin: "20px auto 10px",
    padding: "10px",
    background: "#FFF"
  },
  pos: {
    marginBottom: 12
  },
  textField: {
    backgroundColor: "none",
    width: "95%",
    margin: "20px 10px"
  },
  textFieldToken: {
    backgroundColor: "none",
    width: "60%",
    margin: "20px 10px"
  },
  input: {
    backgroundColor: "none"
  },
  margin: {
    margin: "5% auto",
    width: "100%"
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    backgroundSize: "contain",
    margin: "50px"
  }
};

const mapStateToProps = state => ({
  userAdded: state.user.status,
  userAddedMessage: state.user.message
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdicionaUsuario);
