import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditComponent from "../ui/EditComponent";

export default function EditParRestauranteComponent() {
  const history = useHistory();
  const dadosRedux = useSelector(state => state.cadastros.cadastro);
  const dadosStorage = JSON.parse(localStorage.getItem("parrestaurante"));

  const colunas = [
    { title: "Código", field: "codparrestaurante" },
    { title: "Controle Nome", field: "controleNome" },
    { title: "Trabalhar Restaurante", field: "trabalharRest" },
    { title: "Imp. Cozinha", field: "impCozinha" },
    { title: "Linha Final Impressão", field: "linhaFinalImpressao" },
    { title: "Imp. Extrato", field: "impExtrato" },
    { title: "Valor Couvert", field: "vlrCovert" },
    { title: "Imprimir Teim.", field: "imprimirIeim" },
    { title: "Selecionar Garçon", field: "selecionarGarcon" },
    { title: "Cod. Pesq. Venda", field: "codpesqVenda" }
  ];

  var retorno;

  if (!dadosRedux && !dadosStorage) {
    retorno = <></>;
    history.push("parrestaurante");
  } else {
    retorno = (
      <div>
        <EditComponent
          title="Parâmetro Restaurante"
          data={dadosRedux || dadosStorage}
          colunas={colunas}
        />
      </div>
    );
  }

  return retorno;
}
