import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListParVendaComponent() {
  const colunas = [
    { title: "Código", field: "codparvenda" },
    { title: "Default", field: "default" },
    { title: "ECF Modelo", field: "ecfModelo" },
    { title: "ECF Porta", field: "ecfPorta" },
    { title: "ECF Timeout", field: "ecfTimeout" },
    { title: "ECF Intervalo", field: "ecfIntervalo" },
    { title: "ECF Tent. Nova", field: "ecfTentNova" },
    { title: "ECF Bloq. Tecl. Mouse", field: "ecfBloqTeclMouse" },
    { title: "ECF Exib. Mens. Aguarde", field: "ecfExibMsgAguarde" },
    { title: "ECF Arred. Qtd.", field: "ecfArreQtde" }
  ];

  return (
    <div>
      <ListComponent
        route="parvendas"
        listKeys={colunas}
        title="Parâmetros Venda"
      />
    </div>
  );
}
