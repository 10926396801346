import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditComponent from "../ui/EditComponent";

export default function EditCfopComponent() {
  const history = useHistory();
  const dadosRedux = useSelector(state => state.cadastros.cadastro);
  const dadosStorage = JSON.parse(localStorage.getItem("cfops"));

  const colunas = [
    { title: "Código", field: "codcfop" },
    { title: "Descrição", field: "descricao" },
    { title: "Gera Financeiro?", field: "gerafinanceiro" },
    { title: "Classificacao", field: "classificacao" },
    { title: "Tipo", field: "tipo" }
  ];

  var retorno;

  if (!dadosRedux && !dadosStorage) {
    retorno = <></>;
    history.push("cfops");
  } else {
    retorno = (
      <div>
        <EditComponent
          title="CFOP"
          data={dadosRedux || dadosStorage}
          colunas={colunas}
        />
      </div>
    );
  }

  return retorno;
}
