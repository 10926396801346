import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListPessoaGrupoComponent() {
  const colunas = [
    { title: "codpessoagrupo", field: "codpessoagrupo" },
    { title: "descricao", field: "descricao" },
    { title: "taxa", field: "taxa" },
    { title: "percDesconto", field: "percDesconto" }
  ];

  return (
    <div>
      <ListComponent
        route="pessoagrupos"
        listKeys={colunas}
        title="Grupos de Pessoa"
      />
    </div>
  );
}
