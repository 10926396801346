import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListCentrocustoComponent() {
  const colunas = [
    { title: "Código", field: "codcentcusto" },
    { title: "Descrição", field: "descricao" },
    { title: "Grau 1", field: "grau1" },
    { title: "Grau 2", field: "grau2" },
    { title: "Grau 3", field: "grau3" },
    { title: "Grau 4", field: "grau4" },
    { title: "Grau 5", field: "grau5" },
    { title: "Grau 6", field: "grau6" },
    { title: "Último Nível", field: "ultimoNivel" },
    { title: "Numeração", field: "numeracao" }
  ];

  return (
    <div>
      <ListComponent
        route="centcustos"
        listKeys={colunas}
        title="Centro de Custo"
      />
    </div>
  );
}
