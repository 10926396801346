import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditComponent from "../ui/EditComponent";

export default function EditPessoaGrupoComponent() {
  const history = useHistory();
  const dadosRedux = useSelector(state => state.cadastros.cadastro);
  const dadosStorage = JSON.parse(localStorage.getItem("pessoas"));

  const colunas = [
    { title: "Código", field: "codpessoa" },
    { title: "Tipo", field: "tipoPessoa" },
    { title: "Nome", field: "nome" },
    { title: "Razão Social", field: "razaoSocial" },
    { title: "Doc.", field: "cpfcnpj" },
    { title: "IE", field: "ie" },
    { title: "IM", field: "im" },
    { title: "Endereço", field: "endereco" },
    { title: "Número", field: "enderecoNumero" },
    { title: "Complemento", field: "enderecoComplemento" }
  ];

  var retorno;

  if (!dadosRedux && !dadosStorage) {
    retorno = <></>;
    history.push("pessoas");
  } else {
    retorno = (
      <div>
        <EditComponent
          title="Pessoa"
          data={dadosRedux || dadosStorage}
          colunas={colunas}
        />
      </div>
    );
  }

  return retorno;
}
