import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListResControleComponent() {
  const colunas = [
    { title: "Código", field: "codgarcon" },
    { title: "Nome", field: "nome" },
    { title: "Senha", field: "senha" },
    { title: "Percentual", field: "perc" },
    { title: "Entregador?", field: "entregador" }
  ];

  return (
    <div>
      <ListComponent route="resgarcons" listKeys={colunas} title="Garçons" />
    </div>
  );
}
