import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListResControleComponent() {
  const colunas = [
    { title: "Código", field: "codprodutounidade" },
    { title: "Descrição", field: "descricao" },
    { title: "sigla", field: "sigla" }
  ];

  return (
    <div>
      <ListComponent
        route="produtounidades"
        listKeys={colunas}
        title="Unidades"
      />
    </div>
  );
}
