import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class ProdutoService {
  fetchProdutos() {
    return axios.get(config.baseURL + '/produtos/', Headers);
  }

  fetchProdutoById(codproduto) {
    return axios.get(config.baseURL + '/produtos/' + codproduto, Headers);
  }

  deleteProduto(codproduto) {
    return axios.delete(config.baseURL + '/produtos/' + codproduto, Headers);
  }

  addProduto(produto) {
    return axios.post(config.baseURL + '/produtos/', produto, Headers);
  }

  editProduto(produto) {
    return axios.put(config.baseURL + '/produtos/' + produto.codproduto, produto, Headers);
  }
}

export default new ProdutoService();