import React, { Component } from "react";
import ResControleService from "../../service/ResControleService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import * as CadastrosActions from "../../redux/actions/cadastros";
import { bindActionCreators } from "redux";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddResControleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      codControle: "",
      cobrarServico: "",
      message: null
    };
    this.saveResControle = this.saveResControle.bind(this);
  }

  saveResControle = e => {
    e.preventDefault();
    let resControles = {
      codcontrole: this.state.codControle,
      status: this.state.status,
      cobrarServico: this.state.cobrarServico
    };

    const { adicionaCadastroRequest } = this.props;
    adicionaCadastroRequest("rescontroles", resControles);
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
          <Typography variant="h4" style={style}>
            Adicionar ResControle
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} style={styles.grid}>
              <Card style={styles.card}>
                <form style={formContainer}>
                  <TextField
                    type="text"
                    style={styles.textField}
                    label="Código"
                    fullWidth
                    margin="normal"
                    name="codControle"
                    value={this.state.codControle}
                    onChange={this.onChange}
                  />
                  <TextField
                    type="text"
                    style={styles.textField}
                    label="status"
                    fullWidth
                    margin="normal"
                    name="status"
                    value={this.state.status}
                    onChange={this.onChange}
                  />
                  <TextField
                    type="text"
                    style={styles.textField}
                    label="cobrarServico"
                    fullWidth
                    margin="normal"
                    name="cobrarServico"
                    value={this.state.cobrarServico}
                    onChange={this.onChange}
                  />
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      style={styles.button}
                      color="primary"
                      onClick={this.saveResControle}
                      size="large"
                    >
                      Salvar
                    </Button>
                  </Box>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "flex-end"
};

const style = {
  display: "flex",
  justifyContent: "center",
  fontFamily: "Poppins",
  fontWeight: "bold",
  margin: "50px auto 10px",
  color: "#5a9495"
};

const styles = {
  grid: {
    maxWidth: "600px",
    margin: "30px auto"
  },
  button: {
    margin: "10px",
    width: "95%",
    backgroundColor: "#5a9495",
    maxWidth: "375px"
  },
  card: {
    minWidth: 275,
    maxWidth: 600,
    margin: "20px auto 10px",
    padding: "10px",
    background: "#FFF"
  },
  textField: {
    backgroundColor: "none",
    width: "95%",
    margin: "20px 10px"
  }
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(CadastrosActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddResControleComponent);
