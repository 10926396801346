import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class CentcustoService {
  fetchCentcustos() {
    return axios.get(config.baseURL + '/centcustos/', Headers);
  }

  fetchCentcustoById(codcentcusto) {
    return axios.get(config.baseURL + '/centcustos/' + codcentcusto, Headers);
  }

  deleteCentcusto(codcentcusto) {
    return axios.delete(config.baseURL + '/centcustos/' + codcentcusto, Headers);
  }

  addCentcusto(centcusto) {
    return axios.post(config.baseURL + '/centcustos/', centcusto, Headers);
  }

  editCentcusto(centcusto) {
    return axios.put(config.baseURL + '/centcustos/' + centcusto.codcentcusto, centcusto, Headers);
  }
}

export default new CentcustoService();