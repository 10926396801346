export default function auth(state = [], action) {
  switch (action.type) {
    case "SET_PERIOD":
      return {
        ...state,
        typeDate: action.payload.periodo,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate
      };
    case "UPDATE_DATA":
      return {
        ...state,
        ...action.payload.data,
        loaded: true,
        updated_at: action.payload.at
      };

    default:
      return state;
  }
}
