import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class ParVendaService {
  fetchParVendas() {
    return axios.get(config.baseURL + '/parvendas/', Headers);
  }

  fetchParVendaById(codparvenda) {
    return axios.get(config.baseURL + '/parvendas/' + codparvenda, Headers);
  }

  deleteParVenda(codparvenda) {
    return axios.delete(config.baseURL + '/parvendas/' + codparvenda, Headers);
  }

  addParVenda(parVenda) {
    return axios.post(config.baseURL + '/parvendas/', parVenda, Headers);
  }

  editParVenda(parVenda) {
    return axios.put(config.baseURL + '/parvendas/' + parVenda.codparvenda, parVenda, Headers);
  }
}

export default new ParVendaService();