import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Spinner from "./Spinner";
import { connect } from "react-redux";

function ResponsiveDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  var loading = props.loading || false;
  return (
    <div>
      <Dialog
        /*fullScreen={fullScreen}*/
        open={props.loading}
        aria-labelledby="responsive-dialog-title"
        /*disableBackdropClick={props.disableBackdropClick}*/
      >
        <DialogContent>
          <DialogContentText>
            <Spinner />
          </DialogContentText>
        </DialogContent>
        <DialogActions disableSpacing={true}></DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => ({
  loading: state.spinner.spinner
});

export default connect(mapStateToProps)(ResponsiveDialog);
