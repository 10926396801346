import React, { Component } from "react";
import ProdutoService from "../../service/ProdutoService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddProdutoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codpesq1: "",
      descricao: "",
      ativo: "",
      tipoProduto: "",
      codprodutogrupo: "",
      codprodutogruposubgrp: "",
      codprodutounidade: "",
      estqAtual: "",
      estqMin: "",
      calcPrecoCompra: "",
      calcFreteVlr: "",
      calcIcmsPerc: "",
      calcIpiPer: "",
      calcOutrosVlr: "",
      calcCustoProduto: "",
      anteriorPreco01: "",
      anteriorCalcPrecoCompra: "",
      anteriorCalcCustoProdut: "",
      dataUltCompra: "",
      markp01: "",
      markp02: "",
      markp03: "",
      markp04: "",
      markp05: "",
      preco01: "",
      preco02: "",
      preco03: "",
      preco04: "",
      preco05: "",
      estqInicial: "",
      imagem: "",
      aliqImp: "",
      altPreco: "",
      stTrib: "",
      comp: "",
      undcompQtde: "",
      undcompQtdeemb: "",
      codprodutocatesp: "",
      codfabricante: "",
      lote: "",
      tipolista: "",
      licBoapratica: "",
      licRegistro: "",
      impCozinha: "",
      codclassfiscal: "",
      siTribApolo: "",
      codmarca: "",
      aliqIcms: "",
      codprodutocor: "",
      codprodutocurvatura: "",
      codprodutodiametro: "",
      codprodutograu: "",
      regIcms: "",
      vlrCustoMargem: "",
      codprodutotipolente: "",
      codprodutoadicao: "",
      codprodutoeixo: "",
      codfab: "",
      codprodutocilindro: "",
      codcor: "",
      codprodutoclone: "",
      lente: "",
      servico: "",
      composto: "",
      impBar: "",
      codbarComposto: "",
      localizacao: "",
      origem: "",
      referencia: "",
      comissao1: "",
      comissao2: "",
      ncm: "",
      diasEntrega: "",
      impPizza: "",
      descMax: "",
      remControlado: "",
      regMs: "",
      remGenerico: "",
      controlaLote: "",
      naoAtualizaCusto: "",
      compPedido: "",
      compPedidoAnterior: "",
      stMva: "",
      st: "",
      stIcmsVlr: "",
      calcIcmsVlr: "",
      calcIpiVlr: "",
      anteriorIcmsVlr: "",
      anteriorIpiVlr: "",
      anteriorStIcmsVlr: "",
      anteriorOutrosVlr: "",
      anteriorFreteVlr: "",
      calcImpFront: "",
      calcIcmsBase: "",
      calcFreteIcmsVlr: "",
      calcFreteIcmsAliq: "",
      stIcmsBase: "",
      ultPrecoCompra: "",
      ultIpivlr: "",
      ultFretevlr: "",
      ultIcmssub: "",
      ultOutrosvlr: "",
      ultIcmsvlr: "",
      ultImpFront: "",
      ultCusto: "",
      anteriorImpFront: "",
      visual: "",
      tipoSaida: "",
      ocorrencia: "",
      filial: "",
      stMva7: "",
      stMva12: "",
      estoqMinZero: "",
      estqMax: "",
      preco01Ant: "",
      dtCadastro: "",
      atualiz: "",
      confirmarEntrega: "",
      cst: "",
      ferramenta: "",
      epi: "",
      matConsumo: "",
      revisao: "",
      estqDataUltEntrada: "",
      estqDataUltSaida: "",
      curvaAbc: "",
      descricaoTemp: "",
      icmsOrigem: "",
      vlrServico: "",
      descTecnica: "",
      preco06: "",
      preco07: "",
      preco08: "",
      preco09: "",
      preco10: "",
      preco11: "",
      preco12: "",
      preco13: "",
      preco14: "",
      prazoEntrega: "",
      tipoImpressao: "",
      categoria: "",
      aliqIpi: "",
      npermSemestoq: "",
      npermFracionado: "",
      codcfop: "",
      aliqIcmsFora: "",
      obsNfc: "",
      normal: "",
      consumo: "",
      patrimonio: "",
      calibrado: "",
      contribuinte: "",
      ultDescvlrNf: "",
      anteriorDescvlrNf: "",
      calcDescvlrNf: "",
      estqReservado: "",
      icmsTipo: "",
      ivaTipo: "",
      aliqIcmsNcont: "",
      aliqIcmsForaNcont: "",
      perReduc: "",
      perReducFora: "",
      perReducNcont: "",
      perReducForaNcont: "",
      vendedorCustomizado: "",
      vlrOutros: "",
      vlrAtualBem: "",
      perDepreciacao: "",
      repistolar: "",
      balanca: "",
      diasValidade: "",
      iat: "",
      ippt: "",
      gtin: "",
      ncmEx: "",
      ncmTabela: "",
      ncmAliq: "",
      dataUltVenda: "",
      descricaoMenor: "",
      personalizado: "",
      descDinheiro: "",
      descOutros: "",
      promoTipo: "",
      promoDtini: "",
      promoDtfinal: "",
      promoSegChk: "",
      promoTerChk: "",
      promoQuaChk: "",
      promoQuiChk: "",
      promoSexChk: "",
      promoSabChk: "",
      promoDomChk: "",
      promoSegHrini: "",
      promoSegHrfinal: "",
      promoTerHrini: "",
      promoTerHrfinal: "",
      promoQuaHrini: "",
      promoQuaHrfinal: "",
      promoQuiHrini: "",
      promoQuiHrfinal: "",
      promoSexHrini: "",
      promoSexHrfinal: "",
      promoSabHrini: "",
      promoSabHrfinal: "",
      promoDomHrini: "",
      promoDomHrfinal: "",
      ordem: "",
      estqAtualAntes: "",
      impChurrasqueira: "",
      csosn: "",
      cest: "",
      altQtdCmp: "",
      qtdCmp: "",
      ean13: "",
      message: null
    };
    this.saveProduto = this.saveProduto.bind(this);
  }

  saveProduto = e => {
    e.preventDefault();
    let produtos = {
      codpesq1: this.state.codpesq1,
      descricao: this.state.descricao,
      ativo: this.state.ativo,
      tipoProduto: this.state.tipoProduto,
      codprodutogrupo: this.state.codprodutogrupo,
      codprodutogruposubgrp: this.state.codprodutogruposubgrp,
      codprodutounidade: this.state.codprodutounidade,
      estqAtual: this.state.estqAtual,
      estqMin: this.state.estqMin,
      calcPrecoCompra: this.state.calcPrecoCompra,
      calcFreteVlr: this.state.calcFreteVlr,
      calcIcmsPerc: this.state.calcIcmsPerc,
      calcIpiPer: this.state.calcIpiPer,
      calcOutrosVlr: this.state.calcOutrosVlr,
      calcCustoProduto: this.state.calcCustoProduto,
      anteriorPreco01: this.state.anteriorPreco01,
      anteriorCalcPrecoCompra: this.state.anteriorCalcPrecoCompra,
      anteriorCalcCustoProdut: this.state.anteriorCalcCustoProdut,
      dataUltCompra: this.state.dataUltCompra,
      markp01: this.state.markp01,
      markp02: this.state.markp02,
      markp03: this.state.markp03,
      markp04: this.state.markp04,
      markp05: this.state.markp05,
      preco01: this.state.preco01,
      preco02: this.state.preco02,
      preco03: this.state.preco03,
      preco04: this.state.preco04,
      preco05: this.state.preco05,
      estqInicial: this.state.estqInicial,
      imagem: this.state.imagem,
      aliqImp: this.state.aliqImp,
      altPreco: this.state.altPreco,
      stTrib: this.state.stTrib,
      comp: this.state.comp,
      undcompQtde: this.state.undcompQtde,
      undcompQtdeemb: this.state.undcompQtdeemb,
      codprodutocatesp: this.state.codprodutocatesp,
      codfabricante: this.state.codfabricante,
      lote: this.state.lote,
      tipolista: this.state.tipolista,
      licBoapratica: this.state.licBoapratica,
      licRegistro: this.state.licRegistro,
      impCozinha: this.state.impCozinha,
      codclassfiscal: this.state.codclassfiscal,
      siTribApolo: this.state.siTribApolo,
      codmarca: this.state.codmarca,
      aliqIcms: this.state.aliqIcms,
      codprodutocor: this.state.codprodutocor,
      codprodutocurvatura: this.state.codprodutocurvatura,
      codprodutodiametro: this.state.codprodutodiametro,
      codprodutograu: this.state.codprodutograu,
      regIcms: this.state.regIcms,
      vlrCustoMargem: this.state.vlrCustoMargem,
      codprodutotipolente: this.state.codprodutotipolente,
      codprodutoadicao: this.state.codprodutoadicao,
      codprodutoeixo: this.state.codprodutoeixo,
      codfab: this.state.codfab,
      codprodutocilindro: this.state.codprodutocilindro,
      codcor: this.state.codcor,
      codprodutoclone: this.state.codprodutoclone,
      lente: this.state.lente,
      servico: this.state.servico,
      composto: this.state.composto,
      impBar: this.state.impBar,
      codbarComposto: this.state.codbarComposto,
      localizacao: this.state.localizacao,
      origem: this.state.origem,
      referencia: this.state.referencia,
      comissao1: this.state.comissao1,
      comissao2: this.state.comissao2,
      ncm: this.state.ncm,
      diasEntrega: this.state.diasEntrega,
      impPizza: this.state.impPizza,
      descMax: this.state.descMax,
      remControlado: this.state.remControlado,
      regMs: this.state.regMs,
      remGenerico: this.state.remGenerico,
      controlaLote: this.state.controlaLote,
      naoAtualizaCusto: this.state.naoAtualizaCusto,
      compPedido: this.state.compPedido,
      compPedidoAnterior: this.state.compPedidoAnterior,
      stMva: this.state.stMva,
      st: this.state.st,
      stIcmsVlr: this.state.stIcmsVlr,
      calcIcmsVlr: this.state.calcIcmsVlr,
      calcIpiVlr: this.state.calcIpiVlr,
      anteriorIcmsVlr: this.state.anteriorIcmsVlr,
      anteriorIpiVlr: this.state.anteriorIpiVlr,
      anteriorStIcmsVlr: this.state.anteriorStIcmsVlr,
      anteriorOutrosVlr: this.state.anteriorOutrosVlr,
      anteriorFreteVlr: this.state.anteriorFreteVlr,
      calcImpFront: this.state.calcImpFront,
      calcIcmsBase: this.state.calcIcmsBase,
      calcFreteIcmsVlr: this.state.calcFreteIcmsVlr,
      calcFreteIcmsAliq: this.state.calcFreteIcmsAliq,
      stIcmsBase: this.state.stIcmsBase,
      ultPrecoCompra: this.state.ultPrecoCompra,
      ultIpivlr: this.state.ultIpivlr,
      ultFretevlr: this.state.ultFretevlr,
      ultIcmssub: this.state.ultIcmssub,
      ultOutrosvlr: this.state.ultOutrosvlr,
      ultIcmsvlr: this.state.ultIcmsvlr,
      ultImpFront: this.state.ultImpFront,
      ultCusto: this.state.ultCusto,
      anteriorImpFront: this.state.anteriorImpFront,
      visual: this.state.visual,
      tipoSaida: this.state.tipoSaida,
      ocorrencia: this.state.ocorrencia,
      filial: this.state.filial,
      stMva7: this.state.stMva7,
      stMva12: this.state.stMva12,
      estoqMinZero: this.state.estoqMinZero,
      estqMax: this.state.estqMax,
      preco01Ant: this.state.preco01Ant,
      dtCadastro: this.state.dtCadastro,
      atualiz: this.state.atualiz,
      confirmarEntrega: this.state.confirmarEntrega,
      cst: this.state.cst,
      ferramenta: this.state.ferramenta,
      epi: this.state.epi,
      matConsumo: this.state.matConsumo,
      revisao: this.state.revisao,
      estqDataUltEntrada: this.state.estqDataUltEntrada,
      estqDataUltSaida: this.state.estqDataUltSaida,
      curvaAbc: this.state.curvaAbc,
      descricaoTemp: this.state.descricaoTemp,
      icmsOrigem: this.state.icmsOrigem,
      vlrServico: this.state.vlrServico,
      descTecnica: this.state.descTecnica,
      preco06: this.state.preco06,
      preco07: this.state.preco07,
      preco08: this.state.preco08,
      preco09: this.state.preco09,
      preco10: this.state.preco10,
      preco11: this.state.preco11,
      preco12: this.state.preco12,
      preco13: this.state.preco13,
      preco14: this.state.preco14,
      prazoEntrega: this.state.prazoEntrega,
      tipoImpressao: this.state.tipoImpressao,
      categoria: this.state.categoria,
      aliqIpi: this.state.aliqIpi,
      npermSemestoq: this.state.npermSemestoq,
      npermFracionado: this.state.npermFracionado,
      codcfop: this.state.codcfop,
      aliqIcmsFora: this.state.aliqIcmsFora,
      obsNfc: this.state.obsNfc,
      normal: this.state.normal,
      consumo: this.state.consumo,
      patrimonio: this.state.patrimonio,
      calibrado: this.state.calibrado,
      contribuinte: this.state.contribuinte,
      ultDescvlrNf: this.state.ultDescvlrNf,
      anteriorDescvlrNf: this.state.anteriorDescvlrNf,
      calcDescvlrNf: this.state.calcDescvlrNf,
      estqReservado: this.state.estqReservado,
      icmsTipo: this.state.icmsTipo,
      ivaTipo: this.state.ivaTipo,
      aliqIcmsNcont: this.state.aliqIcmsNcont,
      aliqIcmsForaNcont: this.state.aliqIcmsForaNcont,
      perReduc: this.state.perReduc,
      perReducFora: this.state.perReducFora,
      perReducNcont: this.state.perReducNcont,
      perReducForaNcont: this.state.perReducForaNcont,
      vendedorCustomizado: this.state.vendedorCustomizado,
      vlrOutros: this.state.vlrOutros,
      vlrAtualBem: this.state.vlrAtualBem,
      perDepreciacao: this.state.perDepreciacao,
      repistolar: this.state.repistolar,
      balanca: this.state.balanca,
      diasValidade: this.state.diasValidade,
      iat: this.state.iat,
      ippt: this.state.ippt,
      gtin: this.state.gtin,
      ncmEx: this.state.ncmEx,
      ncmTabela: this.state.ncmTabela,
      ncmAliq: this.state.ncmAliq,
      dataUltVenda: this.state.dataUltVenda,
      descricaoMenor: this.state.descricaoMenor,
      personalizado: this.state.personalizado,
      descDinheiro: this.state.descDinheiro,
      descOutros: this.state.descOutros,
      promoTipo: this.state.promoTipo,
      promoDtini: this.state.promoDtini,
      promoDtfinal: this.state.promoDtfinal,
      promoSegChk: this.state.promoSegChk,
      promoTerChk: this.state.promoTerChk,
      promoQuaChk: this.state.promoQuaChk,
      promoQuiChk: this.state.promoQuiChk,
      promoSexChk: this.state.promoSexChk,
      promoSabChk: this.state.promoSabChk,
      promoDomChk: this.state.promoDomChk,
      promoSegHrini: this.state.promoSegHrini,
      promoSegHrfinal: this.state.promoSegHrfinal,
      promoTerHrini: this.state.promoTerHrini,
      promoTerHrfinal: this.state.promoTerHrfinal,
      promoQuaHrini: this.state.promoQuaHrini,
      promoQuaHrfinal: this.state.promoQuaHrfinal,
      promoQuiHrini: this.state.promoQuiHrini,
      promoQuiHrfinal: this.state.promoQuiHrfinal,
      promoSexHrini: this.state.promoSexHrini,
      promoSexHrfinal: this.state.promoSexHrfinal,
      promoSabHrini: this.state.promoSabHrini,
      promoSabHrfinal: this.state.promoSabHrfinal,
      promoDomHrini: this.state.promoDomHrini,
      promoDomHrfinal: this.state.promoDomHrfinal,
      ordem: this.state.ordem,
      estqAtualAntes: this.state.estqAtualAntes,
      impChurrasqueira: this.state.impChurrasqueira,
      csosn: this.state.csosn,
      cest: this.state.cest,
      altQtdCmp: this.state.altQtdCmp,
      qtdCmp: this.state.qtdCmp,
      ean13: this.state.ean13,
    };
    ProdutoService.addProduto(produtos).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/produtos");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar Produto
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="codpesq1"
	            fullWidth
	            margin="normal"
	            name="codpesq1"
	            value={this.state.codpesq1}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="descricao"
	            fullWidth
	            margin="normal"
	            name="descricao"
	            value={this.state.descricao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ativo"
	            fullWidth
	            margin="normal"
	            name="ativo"
	            value={this.state.ativo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tipoProduto"
	            fullWidth
	            margin="normal"
	            name="tipoProduto"
	            value={this.state.tipoProduto}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codprodutogrupo"
	            fullWidth
	            margin="normal"
	            name="codprodutogrupo"
	            value={this.state.codprodutogrupo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codprodutogruposubgrp"
	            fullWidth
	            margin="normal"
	            name="codprodutogruposubgrp"
	            value={this.state.codprodutogruposubgrp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codprodutounidade"
	            fullWidth
	            margin="normal"
	            name="codprodutounidade"
	            value={this.state.codprodutounidade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="estqAtual"
	            fullWidth
	            margin="normal"
	            name="estqAtual"
	            value={this.state.estqAtual}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="estqMin"
	            fullWidth
	            margin="normal"
	            name="estqMin"
	            value={this.state.estqMin}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="calcPrecoCompra"
	            fullWidth
	            margin="normal"
	            name="calcPrecoCompra"
	            value={this.state.calcPrecoCompra}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="calcFreteVlr"
	            fullWidth
	            margin="normal"
	            name="calcFreteVlr"
	            value={this.state.calcFreteVlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="calcIcmsPerc"
	            fullWidth
	            margin="normal"
	            name="calcIcmsPerc"
	            value={this.state.calcIcmsPerc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="calcIpiPer"
	            fullWidth
	            margin="normal"
	            name="calcIpiPer"
	            value={this.state.calcIpiPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="calcOutrosVlr"
	            fullWidth
	            margin="normal"
	            name="calcOutrosVlr"
	            value={this.state.calcOutrosVlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="calcCustoProduto"
	            fullWidth
	            margin="normal"
	            name="calcCustoProduto"
	            value={this.state.calcCustoProduto}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="anteriorPreco01"
	            fullWidth
	            margin="normal"
	            name="anteriorPreco01"
	            value={this.state.anteriorPreco01}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="anteriorCalcPrecoCompra"
	            fullWidth
	            margin="normal"
	            name="anteriorCalcPrecoCompra"
	            value={this.state.anteriorCalcPrecoCompra}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="anteriorCalcCustoProdut"
	            fullWidth
	            margin="normal"
	            name="anteriorCalcCustoProdut"
	            value={this.state.anteriorCalcCustoProdut}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataUltCompra"
	            fullWidth
	            margin="normal"
	            name="dataUltCompra"
	            value={this.state.dataUltCompra}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="markp01"
	            fullWidth
	            margin="normal"
	            name="markp01"
	            value={this.state.markp01}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="markp02"
	            fullWidth
	            margin="normal"
	            name="markp02"
	            value={this.state.markp02}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="markp03"
	            fullWidth
	            margin="normal"
	            name="markp03"
	            value={this.state.markp03}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="markp04"
	            fullWidth
	            margin="normal"
	            name="markp04"
	            value={this.state.markp04}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="markp05"
	            fullWidth
	            margin="normal"
	            name="markp05"
	            value={this.state.markp05}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="preco01"
	            fullWidth
	            margin="normal"
	            name="preco01"
	            value={this.state.preco01}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="preco02"
	            fullWidth
	            margin="normal"
	            name="preco02"
	            value={this.state.preco02}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="preco03"
	            fullWidth
	            margin="normal"
	            name="preco03"
	            value={this.state.preco03}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="preco04"
	            fullWidth
	            margin="normal"
	            name="preco04"
	            value={this.state.preco04}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="preco05"
	            fullWidth
	            margin="normal"
	            name="preco05"
	            value={this.state.preco05}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="estqInicial"
	            fullWidth
	            margin="normal"
	            name="estqInicial"
	            value={this.state.estqInicial}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="imagem"
	            fullWidth
	            margin="normal"
	            name="imagem"
	            value={this.state.imagem}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="aliqImp"
	            fullWidth
	            margin="normal"
	            name="aliqImp"
	            value={this.state.aliqImp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="altPreco"
	            fullWidth
	            margin="normal"
	            name="altPreco"
	            value={this.state.altPreco}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="stTrib"
	            fullWidth
	            margin="normal"
	            name="stTrib"
	            value={this.state.stTrib}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="comp"
	            fullWidth
	            margin="normal"
	            name="comp"
	            value={this.state.comp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="undcompQtde"
	            fullWidth
	            margin="normal"
	            name="undcompQtde"
	            value={this.state.undcompQtde}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="undcompQtdeemb"
	            fullWidth
	            margin="normal"
	            name="undcompQtdeemb"
	            value={this.state.undcompQtdeemb}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codprodutocatesp"
	            fullWidth
	            margin="normal"
	            name="codprodutocatesp"
	            value={this.state.codprodutocatesp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codfabricante"
	            fullWidth
	            margin="normal"
	            name="codfabricante"
	            value={this.state.codfabricante}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="lote"
	            fullWidth
	            margin="normal"
	            name="lote"
	            value={this.state.lote}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tipolista"
	            fullWidth
	            margin="normal"
	            name="tipolista"
	            value={this.state.tipolista}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="licBoapratica"
	            fullWidth
	            margin="normal"
	            name="licBoapratica"
	            value={this.state.licBoapratica}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="licRegistro"
	            fullWidth
	            margin="normal"
	            name="licRegistro"
	            value={this.state.licRegistro}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="impCozinha"
	            fullWidth
	            margin="normal"
	            name="impCozinha"
	            value={this.state.impCozinha}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codclassfiscal"
	            fullWidth
	            margin="normal"
	            name="codclassfiscal"
	            value={this.state.codclassfiscal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="siTribApolo"
	            fullWidth
	            margin="normal"
	            name="siTribApolo"
	            value={this.state.siTribApolo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codmarca"
	            fullWidth
	            margin="normal"
	            name="codmarca"
	            value={this.state.codmarca}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="aliqIcms"
	            fullWidth
	            margin="normal"
	            name="aliqIcms"
	            value={this.state.aliqIcms}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codprodutocor"
	            fullWidth
	            margin="normal"
	            name="codprodutocor"
	            value={this.state.codprodutocor}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codprodutocurvatura"
	            fullWidth
	            margin="normal"
	            name="codprodutocurvatura"
	            value={this.state.codprodutocurvatura}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codprodutodiametro"
	            fullWidth
	            margin="normal"
	            name="codprodutodiametro"
	            value={this.state.codprodutodiametro}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codprodutograu"
	            fullWidth
	            margin="normal"
	            name="codprodutograu"
	            value={this.state.codprodutograu}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="regIcms"
	            fullWidth
	            margin="normal"
	            name="regIcms"
	            value={this.state.regIcms}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrCustoMargem"
	            fullWidth
	            margin="normal"
	            name="vlrCustoMargem"
	            value={this.state.vlrCustoMargem}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codprodutotipolente"
	            fullWidth
	            margin="normal"
	            name="codprodutotipolente"
	            value={this.state.codprodutotipolente}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codprodutoadicao"
	            fullWidth
	            margin="normal"
	            name="codprodutoadicao"
	            value={this.state.codprodutoadicao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codprodutoeixo"
	            fullWidth
	            margin="normal"
	            name="codprodutoeixo"
	            value={this.state.codprodutoeixo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codfab"
	            fullWidth
	            margin="normal"
	            name="codfab"
	            value={this.state.codfab}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codprodutocilindro"
	            fullWidth
	            margin="normal"
	            name="codprodutocilindro"
	            value={this.state.codprodutocilindro}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codcor"
	            fullWidth
	            margin="normal"
	            name="codcor"
	            value={this.state.codcor}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codprodutoclone"
	            fullWidth
	            margin="normal"
	            name="codprodutoclone"
	            value={this.state.codprodutoclone}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="lente"
	            fullWidth
	            margin="normal"
	            name="lente"
	            value={this.state.lente}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="servico"
	            fullWidth
	            margin="normal"
	            name="servico"
	            value={this.state.servico}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="composto"
	            fullWidth
	            margin="normal"
	            name="composto"
	            value={this.state.composto}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="impBar"
	            fullWidth
	            margin="normal"
	            name="impBar"
	            value={this.state.impBar}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codbarComposto"
	            fullWidth
	            margin="normal"
	            name="codbarComposto"
	            value={this.state.codbarComposto}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="localizacao"
	            fullWidth
	            margin="normal"
	            name="localizacao"
	            value={this.state.localizacao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="origem"
	            fullWidth
	            margin="normal"
	            name="origem"
	            value={this.state.origem}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="referencia"
	            fullWidth
	            margin="normal"
	            name="referencia"
	            value={this.state.referencia}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="comissao1"
	            fullWidth
	            margin="normal"
	            name="comissao1"
	            value={this.state.comissao1}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="comissao2"
	            fullWidth
	            margin="normal"
	            name="comissao2"
	            value={this.state.comissao2}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ncm"
	            fullWidth
	            margin="normal"
	            name="ncm"
	            value={this.state.ncm}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="diasEntrega"
	            fullWidth
	            margin="normal"
	            name="diasEntrega"
	            value={this.state.diasEntrega}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="impPizza"
	            fullWidth
	            margin="normal"
	            name="impPizza"
	            value={this.state.impPizza}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="descMax"
	            fullWidth
	            margin="normal"
	            name="descMax"
	            value={this.state.descMax}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="remControlado"
	            fullWidth
	            margin="normal"
	            name="remControlado"
	            value={this.state.remControlado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="regMs"
	            fullWidth
	            margin="normal"
	            name="regMs"
	            value={this.state.regMs}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="remGenerico"
	            fullWidth
	            margin="normal"
	            name="remGenerico"
	            value={this.state.remGenerico}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="controlaLote"
	            fullWidth
	            margin="normal"
	            name="controlaLote"
	            value={this.state.controlaLote}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="naoAtualizaCusto"
	            fullWidth
	            margin="normal"
	            name="naoAtualizaCusto"
	            value={this.state.naoAtualizaCusto}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="compPedido"
	            fullWidth
	            margin="normal"
	            name="compPedido"
	            value={this.state.compPedido}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="compPedidoAnterior"
	            fullWidth
	            margin="normal"
	            name="compPedidoAnterior"
	            value={this.state.compPedidoAnterior}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="stMva"
	            fullWidth
	            margin="normal"
	            name="stMva"
	            value={this.state.stMva}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="st"
	            fullWidth
	            margin="normal"
	            name="st"
	            value={this.state.st}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="stIcmsVlr"
	            fullWidth
	            margin="normal"
	            name="stIcmsVlr"
	            value={this.state.stIcmsVlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="calcIcmsVlr"
	            fullWidth
	            margin="normal"
	            name="calcIcmsVlr"
	            value={this.state.calcIcmsVlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="calcIpiVlr"
	            fullWidth
	            margin="normal"
	            name="calcIpiVlr"
	            value={this.state.calcIpiVlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="anteriorIcmsVlr"
	            fullWidth
	            margin="normal"
	            name="anteriorIcmsVlr"
	            value={this.state.anteriorIcmsVlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="anteriorIpiVlr"
	            fullWidth
	            margin="normal"
	            name="anteriorIpiVlr"
	            value={this.state.anteriorIpiVlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="anteriorStIcmsVlr"
	            fullWidth
	            margin="normal"
	            name="anteriorStIcmsVlr"
	            value={this.state.anteriorStIcmsVlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="anteriorOutrosVlr"
	            fullWidth
	            margin="normal"
	            name="anteriorOutrosVlr"
	            value={this.state.anteriorOutrosVlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="anteriorFreteVlr"
	            fullWidth
	            margin="normal"
	            name="anteriorFreteVlr"
	            value={this.state.anteriorFreteVlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="calcImpFront"
	            fullWidth
	            margin="normal"
	            name="calcImpFront"
	            value={this.state.calcImpFront}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="calcIcmsBase"
	            fullWidth
	            margin="normal"
	            name="calcIcmsBase"
	            value={this.state.calcIcmsBase}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="calcFreteIcmsVlr"
	            fullWidth
	            margin="normal"
	            name="calcFreteIcmsVlr"
	            value={this.state.calcFreteIcmsVlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="calcFreteIcmsAliq"
	            fullWidth
	            margin="normal"
	            name="calcFreteIcmsAliq"
	            value={this.state.calcFreteIcmsAliq}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="stIcmsBase"
	            fullWidth
	            margin="normal"
	            name="stIcmsBase"
	            value={this.state.stIcmsBase}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="ultPrecoCompra"
	            fullWidth
	            margin="normal"
	            name="ultPrecoCompra"
	            value={this.state.ultPrecoCompra}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="ultIpivlr"
	            fullWidth
	            margin="normal"
	            name="ultIpivlr"
	            value={this.state.ultIpivlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="ultFretevlr"
	            fullWidth
	            margin="normal"
	            name="ultFretevlr"
	            value={this.state.ultFretevlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="ultIcmssub"
	            fullWidth
	            margin="normal"
	            name="ultIcmssub"
	            value={this.state.ultIcmssub}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="ultOutrosvlr"
	            fullWidth
	            margin="normal"
	            name="ultOutrosvlr"
	            value={this.state.ultOutrosvlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="ultIcmsvlr"
	            fullWidth
	            margin="normal"
	            name="ultIcmsvlr"
	            value={this.state.ultIcmsvlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="ultImpFront"
	            fullWidth
	            margin="normal"
	            name="ultImpFront"
	            value={this.state.ultImpFront}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="ultCusto"
	            fullWidth
	            margin="normal"
	            name="ultCusto"
	            value={this.state.ultCusto}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="anteriorImpFront"
	            fullWidth
	            margin="normal"
	            name="anteriorImpFront"
	            value={this.state.anteriorImpFront}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="visual"
	            fullWidth
	            margin="normal"
	            name="visual"
	            value={this.state.visual}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tipoSaida"
	            fullWidth
	            margin="normal"
	            name="tipoSaida"
	            value={this.state.tipoSaida}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ocorrencia"
	            fullWidth
	            margin="normal"
	            name="ocorrencia"
	            value={this.state.ocorrencia}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="filial"
	            fullWidth
	            margin="normal"
	            name="filial"
	            value={this.state.filial}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="stMva7"
	            fullWidth
	            margin="normal"
	            name="stMva7"
	            value={this.state.stMva7}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="stMva12"
	            fullWidth
	            margin="normal"
	            name="stMva12"
	            value={this.state.stMva12}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="estoqMinZero"
	            fullWidth
	            margin="normal"
	            name="estoqMinZero"
	            value={this.state.estoqMinZero}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="estqMax"
	            fullWidth
	            margin="normal"
	            name="estqMax"
	            value={this.state.estqMax}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="preco01Ant"
	            fullWidth
	            margin="normal"
	            name="preco01Ant"
	            value={this.state.preco01Ant}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dtCadastro"
	            fullWidth
	            margin="normal"
	            name="dtCadastro"
	            value={this.state.dtCadastro}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="atualiz"
	            fullWidth
	            margin="normal"
	            name="atualiz"
	            value={this.state.atualiz}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="confirmarEntrega"
	            fullWidth
	            margin="normal"
	            name="confirmarEntrega"
	            value={this.state.confirmarEntrega}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cst"
	            fullWidth
	            margin="normal"
	            name="cst"
	            value={this.state.cst}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ferramenta"
	            fullWidth
	            margin="normal"
	            name="ferramenta"
	            value={this.state.ferramenta}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="epi"
	            fullWidth
	            margin="normal"
	            name="epi"
	            value={this.state.epi}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="matConsumo"
	            fullWidth
	            margin="normal"
	            name="matConsumo"
	            value={this.state.matConsumo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="revisao"
	            fullWidth
	            margin="normal"
	            name="revisao"
	            value={this.state.revisao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="estqDataUltEntrada"
	            fullWidth
	            margin="normal"
	            name="estqDataUltEntrada"
	            value={this.state.estqDataUltEntrada}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="estqDataUltSaida"
	            fullWidth
	            margin="normal"
	            name="estqDataUltSaida"
	            value={this.state.estqDataUltSaida}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="curvaAbc"
	            fullWidth
	            margin="normal"
	            name="curvaAbc"
	            value={this.state.curvaAbc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="descricaoTemp"
	            fullWidth
	            margin="normal"
	            name="descricaoTemp"
	            value={this.state.descricaoTemp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="icmsOrigem"
	            fullWidth
	            margin="normal"
	            name="icmsOrigem"
	            value={this.state.icmsOrigem}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrServico"
	            fullWidth
	            margin="normal"
	            name="vlrServico"
	            value={this.state.vlrServico}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="descTecnica"
	            fullWidth
	            margin="normal"
	            name="descTecnica"
	            value={this.state.descTecnica}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="preco06"
	            fullWidth
	            margin="normal"
	            name="preco06"
	            value={this.state.preco06}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="preco07"
	            fullWidth
	            margin="normal"
	            name="preco07"
	            value={this.state.preco07}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="preco08"
	            fullWidth
	            margin="normal"
	            name="preco08"
	            value={this.state.preco08}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="preco09"
	            fullWidth
	            margin="normal"
	            name="preco09"
	            value={this.state.preco09}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="preco10"
	            fullWidth
	            margin="normal"
	            name="preco10"
	            value={this.state.preco10}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="preco11"
	            fullWidth
	            margin="normal"
	            name="preco11"
	            value={this.state.preco11}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="preco12"
	            fullWidth
	            margin="normal"
	            name="preco12"
	            value={this.state.preco12}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="preco13"
	            fullWidth
	            margin="normal"
	            name="preco13"
	            value={this.state.preco13}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="preco14"
	            fullWidth
	            margin="normal"
	            name="preco14"
	            value={this.state.preco14}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="prazoEntrega"
	            fullWidth
	            margin="normal"
	            name="prazoEntrega"
	            value={this.state.prazoEntrega}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tipoImpressao"
	            fullWidth
	            margin="normal"
	            name="tipoImpressao"
	            value={this.state.tipoImpressao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="categoria"
	            fullWidth
	            margin="normal"
	            name="categoria"
	            value={this.state.categoria}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="aliqIpi"
	            fullWidth
	            margin="normal"
	            name="aliqIpi"
	            value={this.state.aliqIpi}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="npermSemestoq"
	            fullWidth
	            margin="normal"
	            name="npermSemestoq"
	            value={this.state.npermSemestoq}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="npermFracionado"
	            fullWidth
	            margin="normal"
	            name="npermFracionado"
	            value={this.state.npermFracionado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codcfop"
	            fullWidth
	            margin="normal"
	            name="codcfop"
	            value={this.state.codcfop}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="aliqIcmsFora"
	            fullWidth
	            margin="normal"
	            name="aliqIcmsFora"
	            value={this.state.aliqIcmsFora}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="obsNfc"
	            fullWidth
	            margin="normal"
	            name="obsNfc"
	            value={this.state.obsNfc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="normal"
	            fullWidth
	            margin="normal"
	            name="normal"
	            value={this.state.normal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="consumo"
	            fullWidth
	            margin="normal"
	            name="consumo"
	            value={this.state.consumo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="patrimonio"
	            fullWidth
	            margin="normal"
	            name="patrimonio"
	            value={this.state.patrimonio}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="calibrado"
	            fullWidth
	            margin="normal"
	            name="calibrado"
	            value={this.state.calibrado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="contribuinte"
	            fullWidth
	            margin="normal"
	            name="contribuinte"
	            value={this.state.contribuinte}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="ultDescvlrNf"
	            fullWidth
	            margin="normal"
	            name="ultDescvlrNf"
	            value={this.state.ultDescvlrNf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="anteriorDescvlrNf"
	            fullWidth
	            margin="normal"
	            name="anteriorDescvlrNf"
	            value={this.state.anteriorDescvlrNf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="calcDescvlrNf"
	            fullWidth
	            margin="normal"
	            name="calcDescvlrNf"
	            value={this.state.calcDescvlrNf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="estqReservado"
	            fullWidth
	            margin="normal"
	            name="estqReservado"
	            value={this.state.estqReservado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="icmsTipo"
	            fullWidth
	            margin="normal"
	            name="icmsTipo"
	            value={this.state.icmsTipo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ivaTipo"
	            fullWidth
	            margin="normal"
	            name="ivaTipo"
	            value={this.state.ivaTipo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="aliqIcmsNcont"
	            fullWidth
	            margin="normal"
	            name="aliqIcmsNcont"
	            value={this.state.aliqIcmsNcont}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="aliqIcmsForaNcont"
	            fullWidth
	            margin="normal"
	            name="aliqIcmsForaNcont"
	            value={this.state.aliqIcmsForaNcont}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="perReduc"
	            fullWidth
	            margin="normal"
	            name="perReduc"
	            value={this.state.perReduc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="perReducFora"
	            fullWidth
	            margin="normal"
	            name="perReducFora"
	            value={this.state.perReducFora}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="perReducNcont"
	            fullWidth
	            margin="normal"
	            name="perReducNcont"
	            value={this.state.perReducNcont}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="perReducForaNcont"
	            fullWidth
	            margin="normal"
	            name="perReducForaNcont"
	            value={this.state.perReducForaNcont}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="vendedorCustomizado"
	            fullWidth
	            margin="normal"
	            name="vendedorCustomizado"
	            value={this.state.vendedorCustomizado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrOutros"
	            fullWidth
	            margin="normal"
	            name="vlrOutros"
	            value={this.state.vlrOutros}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrAtualBem"
	            fullWidth
	            margin="normal"
	            name="vlrAtualBem"
	            value={this.state.vlrAtualBem}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="perDepreciacao"
	            fullWidth
	            margin="normal"
	            name="perDepreciacao"
	            value={this.state.perDepreciacao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="repistolar"
	            fullWidth
	            margin="normal"
	            name="repistolar"
	            value={this.state.repistolar}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balanca"
	            fullWidth
	            margin="normal"
	            name="balanca"
	            value={this.state.balanca}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="diasValidade"
	            fullWidth
	            margin="normal"
	            name="diasValidade"
	            value={this.state.diasValidade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="iat"
	            fullWidth
	            margin="normal"
	            name="iat"
	            value={this.state.iat}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ippt"
	            fullWidth
	            margin="normal"
	            name="ippt"
	            value={this.state.ippt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="gtin"
	            fullWidth
	            margin="normal"
	            name="gtin"
	            value={this.state.gtin}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ncmEx"
	            fullWidth
	            margin="normal"
	            name="ncmEx"
	            value={this.state.ncmEx}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ncmTabela"
	            fullWidth
	            margin="normal"
	            name="ncmTabela"
	            value={this.state.ncmTabela}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="ncmAliq"
	            fullWidth
	            margin="normal"
	            name="ncmAliq"
	            value={this.state.ncmAliq}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataUltVenda"
	            fullWidth
	            margin="normal"
	            name="dataUltVenda"
	            value={this.state.dataUltVenda}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="descricaoMenor"
	            fullWidth
	            margin="normal"
	            name="descricaoMenor"
	            value={this.state.descricaoMenor}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="personalizado"
	            fullWidth
	            margin="normal"
	            name="personalizado"
	            value={this.state.personalizado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="descDinheiro"
	            fullWidth
	            margin="normal"
	            name="descDinheiro"
	            value={this.state.descDinheiro}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="descOutros"
	            fullWidth
	            margin="normal"
	            name="descOutros"
	            value={this.state.descOutros}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoTipo"
	            fullWidth
	            margin="normal"
	            name="promoTipo"
	            value={this.state.promoTipo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoDtini"
	            fullWidth
	            margin="normal"
	            name="promoDtini"
	            value={this.state.promoDtini}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoDtfinal"
	            fullWidth
	            margin="normal"
	            name="promoDtfinal"
	            value={this.state.promoDtfinal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoSegChk"
	            fullWidth
	            margin="normal"
	            name="promoSegChk"
	            value={this.state.promoSegChk}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoTerChk"
	            fullWidth
	            margin="normal"
	            name="promoTerChk"
	            value={this.state.promoTerChk}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoQuaChk"
	            fullWidth
	            margin="normal"
	            name="promoQuaChk"
	            value={this.state.promoQuaChk}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoQuiChk"
	            fullWidth
	            margin="normal"
	            name="promoQuiChk"
	            value={this.state.promoQuiChk}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoSexChk"
	            fullWidth
	            margin="normal"
	            name="promoSexChk"
	            value={this.state.promoSexChk}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoSabChk"
	            fullWidth
	            margin="normal"
	            name="promoSabChk"
	            value={this.state.promoSabChk}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoDomChk"
	            fullWidth
	            margin="normal"
	            name="promoDomChk"
	            value={this.state.promoDomChk}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoSegHrini"
	            fullWidth
	            margin="normal"
	            name="promoSegHrini"
	            value={this.state.promoSegHrini}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoSegHrfinal"
	            fullWidth
	            margin="normal"
	            name="promoSegHrfinal"
	            value={this.state.promoSegHrfinal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoTerHrini"
	            fullWidth
	            margin="normal"
	            name="promoTerHrini"
	            value={this.state.promoTerHrini}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoTerHrfinal"
	            fullWidth
	            margin="normal"
	            name="promoTerHrfinal"
	            value={this.state.promoTerHrfinal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoQuaHrini"
	            fullWidth
	            margin="normal"
	            name="promoQuaHrini"
	            value={this.state.promoQuaHrini}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoQuaHrfinal"
	            fullWidth
	            margin="normal"
	            name="promoQuaHrfinal"
	            value={this.state.promoQuaHrfinal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoQuiHrini"
	            fullWidth
	            margin="normal"
	            name="promoQuiHrini"
	            value={this.state.promoQuiHrini}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoQuiHrfinal"
	            fullWidth
	            margin="normal"
	            name="promoQuiHrfinal"
	            value={this.state.promoQuiHrfinal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoSexHrini"
	            fullWidth
	            margin="normal"
	            name="promoSexHrini"
	            value={this.state.promoSexHrini}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoSexHrfinal"
	            fullWidth
	            margin="normal"
	            name="promoSexHrfinal"
	            value={this.state.promoSexHrfinal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoSabHrini"
	            fullWidth
	            margin="normal"
	            name="promoSabHrini"
	            value={this.state.promoSabHrini}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoSabHrfinal"
	            fullWidth
	            margin="normal"
	            name="promoSabHrfinal"
	            value={this.state.promoSabHrfinal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoDomHrini"
	            fullWidth
	            margin="normal"
	            name="promoDomHrini"
	            value={this.state.promoDomHrini}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="promoDomHrfinal"
	            fullWidth
	            margin="normal"
	            name="promoDomHrfinal"
	            value={this.state.promoDomHrfinal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ordem"
	            fullWidth
	            margin="normal"
	            name="ordem"
	            value={this.state.ordem}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="estqAtualAntes"
	            fullWidth
	            margin="normal"
	            name="estqAtualAntes"
	            value={this.state.estqAtualAntes}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="impChurrasqueira"
	            fullWidth
	            margin="normal"
	            name="impChurrasqueira"
	            value={this.state.impChurrasqueira}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="csosn"
	            fullWidth
	            margin="normal"
	            name="csosn"
	            value={this.state.csosn}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cest"
	            fullWidth
	            margin="normal"
	            name="cest"
	            value={this.state.cest}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="altQtdCmp"
	            fullWidth
	            margin="normal"
	            name="altQtdCmp"
	            value={this.state.altQtdCmp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="qtdCmp"
	            fullWidth
	            margin="normal"
	            name="qtdCmp"
	            value={this.state.qtdCmp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ean13"
	            fullWidth
	            margin="normal"
	            name="ean13"
	            value={this.state.ean13}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.saveProduto}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddProdutoComponent);
