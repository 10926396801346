import axios from "axios";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import {
  listaCadastrosSuccess,
  adicionaCadastroSuccess,
  alteraCadastroSuccess
} from "../actions/cadastros";
import { Config } from "../../constants/config";

function* listaCadastros({ rota }) {
  const token = yield select(state => state.auth.token);
  const Headers = { headers: { Authorization: "Bearer " + token } };
  const response = yield call(axios.get, `${Config.baseURL}/${rota}/`, Headers);

  yield put(listaCadastrosSuccess(response.data.data));
}

function* adicionaCadastro({ rota, cadastro }) {
  const token = yield select(state => state.auth.token);
  const Headers = { headers: { Authorization: "Bearer " + token } };
  const response = yield call(
    axios.post,
    `${Config.baseURL}/${rota}/`,
    cadastro,
    Headers
  );

  yield put(adicionaCadastroSuccess(response.data));
}

function* alteraCadastro({ rota, id, cadastro }) {
  const token = yield select(state => state.auth.token);
  const Headers = { headers: { Authorization: "Bearer " + token } };
  const response = yield call(
    axios.put,
    `${Config.baseURL}/${rota}/${id}`,
    cadastro,
    Headers
  );
  if (response.status === 200) {
    yield put(alteraCadastroSuccess(true, "Cadastro alterado com sucesso."));
  } else {
    yield put(
      alteraCadastroSuccess(false, "Não foi possível alterar o cadastro.")
    );
  }
}

export default all([
  takeLatest("@cadastro/REQUEST", listaCadastros),
  takeLatest("@cadastro/ADD_REQUEST", adicionaCadastro),
  takeLatest("@cadastro/EDIT_REQUEST", alteraCadastro)
]);
