import axios from 'axios';
import { Config } from '../constants/config';

const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class ResControleService {

  fetchResControles() {
      return axios.get(config.baseURL + '/rescontroles/', { 
        headers: { 
          Authorization: "Bearer " + token
        } 
      });
    }
  fetchResControleById(codcontrole) {
      return axios.get(config.baseURL + '/rescontroles/' + codcontrole, Headers);
    }

  deleteResControle(codcontrole) {
      return axios.delete(config.baseURL + '/rescontroles/' + codcontrole, Headers);
    }

  addResControle(resControle) {
      return axios.post(config.baseURL + '/rescontroles/', resControle, Headers);
    }

  editResControle(resControle) {
      return axios.put(config.baseURL + '/rescontroles/' + resControle.codcontrole, resControle, Headers);
    }
}


export default new ResControleService();
