import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditComponent from "../ui/EditComponent";

export default function EditParRestauranteComponent() {
  const history = useHistory();
  const dadosRedux = useSelector(state => state.cadastros.cadastro);
  const dadosStorage = JSON.parse(localStorage.getItem("pedvendas"));

  const colunas = [
    { title: "Código", field: "codpedvenda" },
    { title: "Pessoa", field: "codpessoa" },
    { title: "Tipo Preço", field: "tipopreco" },
    { title: "Data Entrada", field: "dataEntrada" },
    { title: "Status", field: "status" },
    { title: "Desconto Valor", field: "descVlr" },
    { title: "Desconto Percentual", field: "descPerc" },
    { title: "Acréscimo Percentual", field: "acrePerc" },
    { title: "Acréscimo Valor", field: "acreVlr" },
    { title: "Total de Produtos", field: "totalProdutos" }
  ];

  var retorno;

  if (!dadosRedux && !dadosStorage) {
    retorno = <></>;
    history.push("pedvendas");
  } else {
    retorno = (
      <div>
        <EditComponent
          title="Pedido de Venda"
          data={dadosRedux || dadosStorage}
          colunas={colunas}
        />
      </div>
    );
  }

  return retorno;
}
