import React, { Component } from "react";
import ConfiguracaoGeralService from "../../service/ConfiguracaoGeralService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddConfiguracaoGeralComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descricao: "",
      vlr: "",
      tipo: "",
      funcionalidade: "",
      md5: "",
      message: null
    };
    this.saveConfiguracaoGeral = this.saveConfiguracaoGeral.bind(this);
  }

  saveConfiguracaoGeral = e => {
    e.preventDefault();
    let configuracaoGerals = {
      descricao: this.state.descricao,
      vlr: this.state.vlr,
      tipo: this.state.tipo,
      funcionalidade: this.state.funcionalidade,
      md5: this.state.md5,
    };
    ConfiguracaoGeralService.addConfiguracaoGeral(configuracaoGerals).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/configuracaogerals");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar ConfiguracaoGeral
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="descricao"
	            fullWidth
	            margin="normal"
	            name="descricao"
	            value={this.state.descricao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="vlr"
	            fullWidth
	            margin="normal"
	            name="vlr"
	            value={this.state.vlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tipo"
	            fullWidth
	            margin="normal"
	            name="tipo"
	            value={this.state.tipo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="funcionalidade"
	            fullWidth
	            margin="normal"
	            name="funcionalidade"
	            value={this.state.funcionalidade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="md5"
	            fullWidth
	            margin="normal"
	            name="md5"
	            value={this.state.md5}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.saveConfiguracaoGeral}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddConfiguracaoGeralComponent);
