export default function user(state = [], action) {
  switch (action.type) {
    case "@user/ADD_SUCCESS":
      return {
        ...state,
        status: action.status,
        message: action.message
      };

    case "@user/REMOVE_SUCCESS":
      return {
        ...state,
        onDeleting: false,
        status: action.status,
        message: action.message
      };

    case "@user/EDIT_SET":
      return {
        ...state,
        payload: action.payload
      };

    case "@user/EDIT_SUCCESS":
      return {
        ...state,
        onDeleting: false,
        status: action.status,
        message: action.message
      };

    default:
      return state;
  }
}
