import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListCfopComponent() {
  const colunas = [
    { title: "Código", field: "codcfop" },
    { title: "Descrição", field: "descricao" },
    { title: "Gera Financeiro?", field: "gerafinanceiro" },
    { title: "Classificacao", field: "classificacao" },
    { title: "Tipo", field: "tipo" }
  ];

  return (
    <div>
      <ListComponent route="cfops" listKeys={colunas} title="CFOP" />
    </div>
  );
}
