import React, { Component } from "react";
import PedvendaService from "../../service/PedvendaService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddPedvendaComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codpessoa: "",
      tipopreco: "",
      dataEntrada: "",
      status: "",
      descVlr: "",
      descPerc: "",
      acrePerc: "",
      acreVlr: "",
      totalProdutos: "",
      codformapagamento: "",
      codvendedor: "",
      totalPedido: "",
      externo: "",
      venIntComis: "",
      venExtComis: "",
      entrega: "",
      tipo: "",
      contato: "",
      telefone: "",
      email: "",
      ddd: "",
      obs: "",
      nf: "",
      rapida: "",
      vlrtroca: "",
      totalComissao: "",
      reimpressao: "",
      descPadrao: "",
      ecf: "",
      usuario: "",
      vlrcovert: "",
      vlrservico: "",
      ecfData: "",
      nrPedido: "",
      dataFaturamento: "",
      horaPedido: "",
      vlr2: "",
      sedexVlr: "",
      acrescimoFinanceiro: "",
      compPedidoAtualizado: "",
      codcaixaabertura: "",
      dataFinalizacao: "",
      softwarePara: "",
      qtdeComputadores: "",
      diaVctoMensalidade: "",
      mesAnoReferencia: "",
      dataVctoInicial: "",
      comarca: "",
      contratoGerado: "",
      valorMensalidade: "",
      qtdeMeses: "",
      codempresa: "",
      atualiz: "",
      totalCustoProdutos: "",
      categoria: "",
      token: "",
      payerid: "",
      paypalStatus: "",
      dataInstalacao: "",
      horaInstalacao: "",
      impresso: "",
      alteracaoDatahora: "",
      alteracaoUsuario: "",
      exclusaoDatahora: "",
      exclusaoUsuario: "",
      revisao: "",
      pesagemGerada: "",
      pesoTotalBruto: "",
      pesoTotalLiquido: "",
      qtdeAnimaisClassif: "",
      conferido: "",
      usuarioConferencia: "",
      datahoraConferencia: "",
      animaisEstoqueBaixado: "",
      codanimalpesagem: "",
      arquivado: "",
      datahoraArquivamento: "",
      usuarioArquivamento: "",
      aprovacaoDatahora: "",
      aprovacaoUsuario: "",
      dataPrevEntrega: "",
      totalDespesas: "",
      tipoPesagem: "",
      consumo: "",
      alternativa: "",
      anterior: "",
      revisado: "",
      nrRevisao: "",
      datahoraRevisao: "",
      usuarioRevisao: "",
      totalBcIcms: "",
      totalIcms: "",
      totalIpi: "",
      tipoFrete: "",
      codossimples: "",
      placa: "",
      km: "",
      modelo: "",
      dataSaidaAnimal: "",
      dataAbateAnimal: "",
      pedCli: "",
      nrEmpreitada: "",
      exportado: "",
      datahoraExportacao: "",
      codpedvendaExportado: "",
      estoquista: "",
      segunda: "",
      codpargeral: "",
      usuarioFaturamento: "",
      naoGerarFinanceiro: "",
      dataLiberacao: "",
      dataEntrega: "",
      dataEntregaFim: "",
      codgarconentregador: "",
      codentrega: "",
      descontoFinanceiro: "",
      liberado: "",
      liberadoMtv: "",
      liberadoUsr: "",
      marca: "",
      cor: "",
      ano: "",
      osDtaprov: "",
      osStatus: "",
      geradoEntrega: "",
      codmedico: "",
      codfidelidade: "",
      codcentcusto: "",
      codmoeda: "",
      message: null
    };
    this.savePedvenda = this.savePedvenda.bind(this);
  }

  savePedvenda = e => {
    e.preventDefault();
    let pedvendas = {
      codpessoa: this.state.codpessoa,
      tipopreco: this.state.tipopreco,
      dataEntrada: this.state.dataEntrada,
      status: this.state.status,
      descVlr: this.state.descVlr,
      descPerc: this.state.descPerc,
      acrePerc: this.state.acrePerc,
      acreVlr: this.state.acreVlr,
      totalProdutos: this.state.totalProdutos,
      codformapagamento: this.state.codformapagamento,
      codvendedor: this.state.codvendedor,
      totalPedido: this.state.totalPedido,
      externo: this.state.externo,
      venIntComis: this.state.venIntComis,
      venExtComis: this.state.venExtComis,
      entrega: this.state.entrega,
      tipo: this.state.tipo,
      contato: this.state.contato,
      telefone: this.state.telefone,
      email: this.state.email,
      ddd: this.state.ddd,
      obs: this.state.obs,
      nf: this.state.nf,
      rapida: this.state.rapida,
      vlrtroca: this.state.vlrtroca,
      totalComissao: this.state.totalComissao,
      reimpressao: this.state.reimpressao,
      descPadrao: this.state.descPadrao,
      ecf: this.state.ecf,
      usuario: this.state.usuario,
      vlrcovert: this.state.vlrcovert,
      vlrservico: this.state.vlrservico,
      ecfData: this.state.ecfData,
      nrPedido: this.state.nrPedido,
      dataFaturamento: this.state.dataFaturamento,
      horaPedido: this.state.horaPedido,
      vlr2: this.state.vlr2,
      sedexVlr: this.state.sedexVlr,
      acrescimoFinanceiro: this.state.acrescimoFinanceiro,
      compPedidoAtualizado: this.state.compPedidoAtualizado,
      codcaixaabertura: this.state.codcaixaabertura,
      dataFinalizacao: this.state.dataFinalizacao,
      softwarePara: this.state.softwarePara,
      qtdeComputadores: this.state.qtdeComputadores,
      diaVctoMensalidade: this.state.diaVctoMensalidade,
      mesAnoReferencia: this.state.mesAnoReferencia,
      dataVctoInicial: this.state.dataVctoInicial,
      comarca: this.state.comarca,
      contratoGerado: this.state.contratoGerado,
      valorMensalidade: this.state.valorMensalidade,
      qtdeMeses: this.state.qtdeMeses,
      codempresa: this.state.codempresa,
      atualiz: this.state.atualiz,
      totalCustoProdutos: this.state.totalCustoProdutos,
      categoria: this.state.categoria,
      token: this.state.token,
      payerid: this.state.payerid,
      paypalStatus: this.state.paypalStatus,
      dataInstalacao: this.state.dataInstalacao,
      horaInstalacao: this.state.horaInstalacao,
      impresso: this.state.impresso,
      alteracaoDatahora: this.state.alteracaoDatahora,
      alteracaoUsuario: this.state.alteracaoUsuario,
      exclusaoDatahora: this.state.exclusaoDatahora,
      exclusaoUsuario: this.state.exclusaoUsuario,
      revisao: this.state.revisao,
      pesagemGerada: this.state.pesagemGerada,
      pesoTotalBruto: this.state.pesoTotalBruto,
      pesoTotalLiquido: this.state.pesoTotalLiquido,
      qtdeAnimaisClassif: this.state.qtdeAnimaisClassif,
      conferido: this.state.conferido,
      usuarioConferencia: this.state.usuarioConferencia,
      datahoraConferencia: this.state.datahoraConferencia,
      animaisEstoqueBaixado: this.state.animaisEstoqueBaixado,
      codanimalpesagem: this.state.codanimalpesagem,
      arquivado: this.state.arquivado,
      datahoraArquivamento: this.state.datahoraArquivamento,
      usuarioArquivamento: this.state.usuarioArquivamento,
      aprovacaoDatahora: this.state.aprovacaoDatahora,
      aprovacaoUsuario: this.state.aprovacaoUsuario,
      dataPrevEntrega: this.state.dataPrevEntrega,
      totalDespesas: this.state.totalDespesas,
      tipoPesagem: this.state.tipoPesagem,
      consumo: this.state.consumo,
      alternativa: this.state.alternativa,
      anterior: this.state.anterior,
      revisado: this.state.revisado,
      nrRevisao: this.state.nrRevisao,
      datahoraRevisao: this.state.datahoraRevisao,
      usuarioRevisao: this.state.usuarioRevisao,
      totalBcIcms: this.state.totalBcIcms,
      totalIcms: this.state.totalIcms,
      totalIpi: this.state.totalIpi,
      tipoFrete: this.state.tipoFrete,
      codossimples: this.state.codossimples,
      placa: this.state.placa,
      km: this.state.km,
      modelo: this.state.modelo,
      dataSaidaAnimal: this.state.dataSaidaAnimal,
      dataAbateAnimal: this.state.dataAbateAnimal,
      pedCli: this.state.pedCli,
      nrEmpreitada: this.state.nrEmpreitada,
      exportado: this.state.exportado,
      datahoraExportacao: this.state.datahoraExportacao,
      codpedvendaExportado: this.state.codpedvendaExportado,
      estoquista: this.state.estoquista,
      segunda: this.state.segunda,
      codpargeral: this.state.codpargeral,
      usuarioFaturamento: this.state.usuarioFaturamento,
      naoGerarFinanceiro: this.state.naoGerarFinanceiro,
      dataLiberacao: this.state.dataLiberacao,
      dataEntrega: this.state.dataEntrega,
      dataEntregaFim: this.state.dataEntregaFim,
      codgarconentregador: this.state.codgarconentregador,
      codentrega: this.state.codentrega,
      descontoFinanceiro: this.state.descontoFinanceiro,
      liberado: this.state.liberado,
      liberadoMtv: this.state.liberadoMtv,
      liberadoUsr: this.state.liberadoUsr,
      marca: this.state.marca,
      cor: this.state.cor,
      ano: this.state.ano,
      osDtaprov: this.state.osDtaprov,
      osStatus: this.state.osStatus,
      geradoEntrega: this.state.geradoEntrega,
      codmedico: this.state.codmedico,
      codfidelidade: this.state.codfidelidade,
      codcentcusto: this.state.codcentcusto,
      codmoeda: this.state.codmoeda,
    };
    PedvendaService.addPedvenda(pedvendas).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/pedvendas");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar Pedvenda
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="codpessoa"
	            fullWidth
	            margin="normal"
	            name="codpessoa"
	            value={this.state.codpessoa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tipopreco"
	            fullWidth
	            margin="normal"
	            name="tipopreco"
	            value={this.state.tipopreco}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataEntrada"
	            fullWidth
	            margin="normal"
	            name="dataEntrada"
	            value={this.state.dataEntrada}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="status"
	            fullWidth
	            margin="normal"
	            name="status"
	            value={this.state.status}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="descVlr"
	            fullWidth
	            margin="normal"
	            name="descVlr"
	            value={this.state.descVlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="descPerc"
	            fullWidth
	            margin="normal"
	            name="descPerc"
	            value={this.state.descPerc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="acrePerc"
	            fullWidth
	            margin="normal"
	            name="acrePerc"
	            value={this.state.acrePerc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="acreVlr"
	            fullWidth
	            margin="normal"
	            name="acreVlr"
	            value={this.state.acreVlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="totalProdutos"
	            fullWidth
	            margin="normal"
	            name="totalProdutos"
	            value={this.state.totalProdutos}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codformapagamento"
	            fullWidth
	            margin="normal"
	            name="codformapagamento"
	            value={this.state.codformapagamento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codvendedor"
	            fullWidth
	            margin="normal"
	            name="codvendedor"
	            value={this.state.codvendedor}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="totalPedido"
	            fullWidth
	            margin="normal"
	            name="totalPedido"
	            value={this.state.totalPedido}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="externo"
	            fullWidth
	            margin="normal"
	            name="externo"
	            value={this.state.externo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="venIntComis"
	            fullWidth
	            margin="normal"
	            name="venIntComis"
	            value={this.state.venIntComis}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="venExtComis"
	            fullWidth
	            margin="normal"
	            name="venExtComis"
	            value={this.state.venExtComis}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="entrega"
	            fullWidth
	            margin="normal"
	            name="entrega"
	            value={this.state.entrega}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tipo"
	            fullWidth
	            margin="normal"
	            name="tipo"
	            value={this.state.tipo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="contato"
	            fullWidth
	            margin="normal"
	            name="contato"
	            value={this.state.contato}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="telefone"
	            fullWidth
	            margin="normal"
	            name="telefone"
	            value={this.state.telefone}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="email"
	            fullWidth
	            margin="normal"
	            name="email"
	            value={this.state.email}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ddd"
	            fullWidth
	            margin="normal"
	            name="ddd"
	            value={this.state.ddd}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="obs"
	            fullWidth
	            margin="normal"
	            name="obs"
	            value={this.state.obs}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nf"
	            fullWidth
	            margin="normal"
	            name="nf"
	            value={this.state.nf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="rapida"
	            fullWidth
	            margin="normal"
	            name="rapida"
	            value={this.state.rapida}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrtroca"
	            fullWidth
	            margin="normal"
	            name="vlrtroca"
	            value={this.state.vlrtroca}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="totalComissao"
	            fullWidth
	            margin="normal"
	            name="totalComissao"
	            value={this.state.totalComissao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="reimpressao"
	            fullWidth
	            margin="normal"
	            name="reimpressao"
	            value={this.state.reimpressao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="descPadrao"
	            fullWidth
	            margin="normal"
	            name="descPadrao"
	            value={this.state.descPadrao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecf"
	            fullWidth
	            margin="normal"
	            name="ecf"
	            value={this.state.ecf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="usuario"
	            fullWidth
	            margin="normal"
	            name="usuario"
	            value={this.state.usuario}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrcovert"
	            fullWidth
	            margin="normal"
	            name="vlrcovert"
	            value={this.state.vlrcovert}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrservico"
	            fullWidth
	            margin="normal"
	            name="vlrservico"
	            value={this.state.vlrservico}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfData"
	            fullWidth
	            margin="normal"
	            name="ecfData"
	            value={this.state.ecfData}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nrPedido"
	            fullWidth
	            margin="normal"
	            name="nrPedido"
	            value={this.state.nrPedido}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataFaturamento"
	            fullWidth
	            margin="normal"
	            name="dataFaturamento"
	            value={this.state.dataFaturamento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="horaPedido"
	            fullWidth
	            margin="normal"
	            name="horaPedido"
	            value={this.state.horaPedido}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlr2"
	            fullWidth
	            margin="normal"
	            name="vlr2"
	            value={this.state.vlr2}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="sedexVlr"
	            fullWidth
	            margin="normal"
	            name="sedexVlr"
	            value={this.state.sedexVlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="acrescimoFinanceiro"
	            fullWidth
	            margin="normal"
	            name="acrescimoFinanceiro"
	            value={this.state.acrescimoFinanceiro}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="compPedidoAtualizado"
	            fullWidth
	            margin="normal"
	            name="compPedidoAtualizado"
	            value={this.state.compPedidoAtualizado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codcaixaabertura"
	            fullWidth
	            margin="normal"
	            name="codcaixaabertura"
	            value={this.state.codcaixaabertura}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataFinalizacao"
	            fullWidth
	            margin="normal"
	            name="dataFinalizacao"
	            value={this.state.dataFinalizacao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="softwarePara"
	            fullWidth
	            margin="normal"
	            name="softwarePara"
	            value={this.state.softwarePara}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="qtdeComputadores"
	            fullWidth
	            margin="normal"
	            name="qtdeComputadores"
	            value={this.state.qtdeComputadores}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="diaVctoMensalidade"
	            fullWidth
	            margin="normal"
	            name="diaVctoMensalidade"
	            value={this.state.diaVctoMensalidade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="mesAnoReferencia"
	            fullWidth
	            margin="normal"
	            name="mesAnoReferencia"
	            value={this.state.mesAnoReferencia}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataVctoInicial"
	            fullWidth
	            margin="normal"
	            name="dataVctoInicial"
	            value={this.state.dataVctoInicial}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="comarca"
	            fullWidth
	            margin="normal"
	            name="comarca"
	            value={this.state.comarca}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="contratoGerado"
	            fullWidth
	            margin="normal"
	            name="contratoGerado"
	            value={this.state.contratoGerado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="valorMensalidade"
	            fullWidth
	            margin="normal"
	            name="valorMensalidade"
	            value={this.state.valorMensalidade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="qtdeMeses"
	            fullWidth
	            margin="normal"
	            name="qtdeMeses"
	            value={this.state.qtdeMeses}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codempresa"
	            fullWidth
	            margin="normal"
	            name="codempresa"
	            value={this.state.codempresa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="atualiz"
	            fullWidth
	            margin="normal"
	            name="atualiz"
	            value={this.state.atualiz}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="totalCustoProdutos"
	            fullWidth
	            margin="normal"
	            name="totalCustoProdutos"
	            value={this.state.totalCustoProdutos}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="categoria"
	            fullWidth
	            margin="normal"
	            name="categoria"
	            value={this.state.categoria}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="token"
	            fullWidth
	            margin="normal"
	            name="token"
	            value={this.state.token}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="payerid"
	            fullWidth
	            margin="normal"
	            name="payerid"
	            value={this.state.payerid}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="paypalStatus"
	            fullWidth
	            margin="normal"
	            name="paypalStatus"
	            value={this.state.paypalStatus}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataInstalacao"
	            fullWidth
	            margin="normal"
	            name="dataInstalacao"
	            value={this.state.dataInstalacao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="horaInstalacao"
	            fullWidth
	            margin="normal"
	            name="horaInstalacao"
	            value={this.state.horaInstalacao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="impresso"
	            fullWidth
	            margin="normal"
	            name="impresso"
	            value={this.state.impresso}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="alteracaoDatahora"
	            fullWidth
	            margin="normal"
	            name="alteracaoDatahora"
	            value={this.state.alteracaoDatahora}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="alteracaoUsuario"
	            fullWidth
	            margin="normal"
	            name="alteracaoUsuario"
	            value={this.state.alteracaoUsuario}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="exclusaoDatahora"
	            fullWidth
	            margin="normal"
	            name="exclusaoDatahora"
	            value={this.state.exclusaoDatahora}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="exclusaoUsuario"
	            fullWidth
	            margin="normal"
	            name="exclusaoUsuario"
	            value={this.state.exclusaoUsuario}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="revisao"
	            fullWidth
	            margin="normal"
	            name="revisao"
	            value={this.state.revisao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="pesagemGerada"
	            fullWidth
	            margin="normal"
	            name="pesagemGerada"
	            value={this.state.pesagemGerada}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="pesoTotalBruto"
	            fullWidth
	            margin="normal"
	            name="pesoTotalBruto"
	            value={this.state.pesoTotalBruto}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="pesoTotalLiquido"
	            fullWidth
	            margin="normal"
	            name="pesoTotalLiquido"
	            value={this.state.pesoTotalLiquido}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="qtdeAnimaisClassif"
	            fullWidth
	            margin="normal"
	            name="qtdeAnimaisClassif"
	            value={this.state.qtdeAnimaisClassif}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="conferido"
	            fullWidth
	            margin="normal"
	            name="conferido"
	            value={this.state.conferido}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="usuarioConferencia"
	            fullWidth
	            margin="normal"
	            name="usuarioConferencia"
	            value={this.state.usuarioConferencia}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="datahoraConferencia"
	            fullWidth
	            margin="normal"
	            name="datahoraConferencia"
	            value={this.state.datahoraConferencia}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="animaisEstoqueBaixado"
	            fullWidth
	            margin="normal"
	            name="animaisEstoqueBaixado"
	            value={this.state.animaisEstoqueBaixado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codanimalpesagem"
	            fullWidth
	            margin="normal"
	            name="codanimalpesagem"
	            value={this.state.codanimalpesagem}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="arquivado"
	            fullWidth
	            margin="normal"
	            name="arquivado"
	            value={this.state.arquivado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="datahoraArquivamento"
	            fullWidth
	            margin="normal"
	            name="datahoraArquivamento"
	            value={this.state.datahoraArquivamento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="usuarioArquivamento"
	            fullWidth
	            margin="normal"
	            name="usuarioArquivamento"
	            value={this.state.usuarioArquivamento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="aprovacaoDatahora"
	            fullWidth
	            margin="normal"
	            name="aprovacaoDatahora"
	            value={this.state.aprovacaoDatahora}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="aprovacaoUsuario"
	            fullWidth
	            margin="normal"
	            name="aprovacaoUsuario"
	            value={this.state.aprovacaoUsuario}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataPrevEntrega"
	            fullWidth
	            margin="normal"
	            name="dataPrevEntrega"
	            value={this.state.dataPrevEntrega}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="totalDespesas"
	            fullWidth
	            margin="normal"
	            name="totalDespesas"
	            value={this.state.totalDespesas}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tipoPesagem"
	            fullWidth
	            margin="normal"
	            name="tipoPesagem"
	            value={this.state.tipoPesagem}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="consumo"
	            fullWidth
	            margin="normal"
	            name="consumo"
	            value={this.state.consumo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="alternativa"
	            fullWidth
	            margin="normal"
	            name="alternativa"
	            value={this.state.alternativa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="anterior"
	            fullWidth
	            margin="normal"
	            name="anterior"
	            value={this.state.anterior}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="revisado"
	            fullWidth
	            margin="normal"
	            name="revisado"
	            value={this.state.revisado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nrRevisao"
	            fullWidth
	            margin="normal"
	            name="nrRevisao"
	            value={this.state.nrRevisao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="datahoraRevisao"
	            fullWidth
	            margin="normal"
	            name="datahoraRevisao"
	            value={this.state.datahoraRevisao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="usuarioRevisao"
	            fullWidth
	            margin="normal"
	            name="usuarioRevisao"
	            value={this.state.usuarioRevisao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="totalBcIcms"
	            fullWidth
	            margin="normal"
	            name="totalBcIcms"
	            value={this.state.totalBcIcms}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="totalIcms"
	            fullWidth
	            margin="normal"
	            name="totalIcms"
	            value={this.state.totalIcms}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="totalIpi"
	            fullWidth
	            margin="normal"
	            name="totalIpi"
	            value={this.state.totalIpi}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tipoFrete"
	            fullWidth
	            margin="normal"
	            name="tipoFrete"
	            value={this.state.tipoFrete}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codossimples"
	            fullWidth
	            margin="normal"
	            name="codossimples"
	            value={this.state.codossimples}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="placa"
	            fullWidth
	            margin="normal"
	            name="placa"
	            value={this.state.placa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="km"
	            fullWidth
	            margin="normal"
	            name="km"
	            value={this.state.km}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="modelo"
	            fullWidth
	            margin="normal"
	            name="modelo"
	            value={this.state.modelo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataSaidaAnimal"
	            fullWidth
	            margin="normal"
	            name="dataSaidaAnimal"
	            value={this.state.dataSaidaAnimal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataAbateAnimal"
	            fullWidth
	            margin="normal"
	            name="dataAbateAnimal"
	            value={this.state.dataAbateAnimal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="pedCli"
	            fullWidth
	            margin="normal"
	            name="pedCli"
	            value={this.state.pedCli}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nrEmpreitada"
	            fullWidth
	            margin="normal"
	            name="nrEmpreitada"
	            value={this.state.nrEmpreitada}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="exportado"
	            fullWidth
	            margin="normal"
	            name="exportado"
	            value={this.state.exportado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="datahoraExportacao"
	            fullWidth
	            margin="normal"
	            name="datahoraExportacao"
	            value={this.state.datahoraExportacao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codpedvendaExportado"
	            fullWidth
	            margin="normal"
	            name="codpedvendaExportado"
	            value={this.state.codpedvendaExportado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="estoquista"
	            fullWidth
	            margin="normal"
	            name="estoquista"
	            value={this.state.estoquista}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="segunda"
	            fullWidth
	            margin="normal"
	            name="segunda"
	            value={this.state.segunda}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codpargeral"
	            fullWidth
	            margin="normal"
	            name="codpargeral"
	            value={this.state.codpargeral}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="usuarioFaturamento"
	            fullWidth
	            margin="normal"
	            name="usuarioFaturamento"
	            value={this.state.usuarioFaturamento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="naoGerarFinanceiro"
	            fullWidth
	            margin="normal"
	            name="naoGerarFinanceiro"
	            value={this.state.naoGerarFinanceiro}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataLiberacao"
	            fullWidth
	            margin="normal"
	            name="dataLiberacao"
	            value={this.state.dataLiberacao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataEntrega"
	            fullWidth
	            margin="normal"
	            name="dataEntrega"
	            value={this.state.dataEntrega}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataEntregaFim"
	            fullWidth
	            margin="normal"
	            name="dataEntregaFim"
	            value={this.state.dataEntregaFim}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codgarconentregador"
	            fullWidth
	            margin="normal"
	            name="codgarconentregador"
	            value={this.state.codgarconentregador}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codentrega"
	            fullWidth
	            margin="normal"
	            name="codentrega"
	            value={this.state.codentrega}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="descontoFinanceiro"
	            fullWidth
	            margin="normal"
	            name="descontoFinanceiro"
	            value={this.state.descontoFinanceiro}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="liberado"
	            fullWidth
	            margin="normal"
	            name="liberado"
	            value={this.state.liberado}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="liberadoMtv"
	            fullWidth
	            margin="normal"
	            name="liberadoMtv"
	            value={this.state.liberadoMtv}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="liberadoUsr"
	            fullWidth
	            margin="normal"
	            name="liberadoUsr"
	            value={this.state.liberadoUsr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="marca"
	            fullWidth
	            margin="normal"
	            name="marca"
	            value={this.state.marca}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cor"
	            fullWidth
	            margin="normal"
	            name="cor"
	            value={this.state.cor}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ano"
	            fullWidth
	            margin="normal"
	            name="ano"
	            value={this.state.ano}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="osDtaprov"
	            fullWidth
	            margin="normal"
	            name="osDtaprov"
	            value={this.state.osDtaprov}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="osStatus"
	            fullWidth
	            margin="normal"
	            name="osStatus"
	            value={this.state.osStatus}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="geradoEntrega"
	            fullWidth
	            margin="normal"
	            name="geradoEntrega"
	            value={this.state.geradoEntrega}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codmedico"
	            fullWidth
	            margin="normal"
	            name="codmedico"
	            value={this.state.codmedico}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codfidelidade"
	            fullWidth
	            margin="normal"
	            name="codfidelidade"
	            value={this.state.codfidelidade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codcentcusto"
	            fullWidth
	            margin="normal"
	            name="codcentcusto"
	            value={this.state.codcentcusto}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codmoeda"
	            fullWidth
	            margin="normal"
	            name="codmoeda"
	            value={this.state.codmoeda}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.savePedvenda}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddPedvendaComponent);
