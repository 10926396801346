import axios from "axios";
import { Config } from "../constants/config";
const config = Config;

class ChartService {
  fetchHome(token, start, end) {
    return axios.get(
      config.baseURL + "/dashboards/principal/" + start + "/" + end,
      {
        headers: { Authorization: "Bearer " + token }
      }
    );
  }
}

export default new ChartService();
