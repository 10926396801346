import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListPedvendaItemComponent() {
  const colunas = [
    { title: "Código", field: "codpedvendaitem" },
    { title: "Cód. Pedido de Venda.", field: "codpedvenda" },
    { title: "Produto", field: "codproduto" },
    { title: "Tipo Preço", field: "tipopreco" },
    { title: "Qtde.", field: "qtde" },
    { title: "Valor Desconto", field: "descvlr" },
    { title: "Valor Unitário", field: "vlrunit" },
    { title: "Cód. Pequisa", field: "codpesquisa" },
    { title: "Item", field: "item" },
    { title: "Qtde. Faturada.", field: "qtdeFat" }
  ];

  return (
    <div>
      <ListComponent
        route="pedvendaitems"
        listKeys={colunas}
        title="Pedidos de Venda - Itens"
      />
    </div>
  );
}
