import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class PedvendaService {
  fetchPedvendas() {
    return axios.get(config.baseURL + '/pedvendas/', Headers);
  }

  fetchPedvendaById(codpedvenda) {
    return axios.get(config.baseURL + '/pedvendas/' + codpedvenda, Headers);
  }

  deletePedvenda(codpedvenda) {
    return axios.delete(config.baseURL + '/pedvendas/' + codpedvenda, Headers);
  }

  addPedvenda(pedvenda) {
    return axios.post(config.baseURL + '/pedvendas/', pedvenda, Headers);
  }

  editPedvenda(pedvenda) {
    return axios.put(config.baseURL + '/pedvendas/' + pedvenda.codpedvenda, pedvenda, Headers);
  }
}

export default new PedvendaService();