import React, { Component } from "react";
import MoedaService from "../../service/MoedaService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddMoedaComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descricao: "",
      tipo: "",
      perDesc: "",
      diasComp: "",
      codecf: "",
      naoVisFech: "",
      dadosPos: "",
      baixarSempre: "",
      numdias: "",
      numdiasDemais: "",
      conChe: "",
      gp: "",
      descricaoEcf: "",
      codbanco: "",
      nfePag: "",
      nfeBand: "",
      nfeIntegrado: "",
      message: null
    };
    this.saveMoeda = this.saveMoeda.bind(this);
  }

  saveMoeda = e => {
    e.preventDefault();
    let moedas = {
      descricao: this.state.descricao,
      tipo: this.state.tipo,
      perDesc: this.state.perDesc,
      diasComp: this.state.diasComp,
      codecf: this.state.codecf,
      naoVisFech: this.state.naoVisFech,
      dadosPos: this.state.dadosPos,
      baixarSempre: this.state.baixarSempre,
      numdias: this.state.numdias,
      numdiasDemais: this.state.numdiasDemais,
      conChe: this.state.conChe,
      gp: this.state.gp,
      descricaoEcf: this.state.descricaoEcf,
      codbanco: this.state.codbanco,
      nfePag: this.state.nfePag,
      nfeBand: this.state.nfeBand,
      nfeIntegrado: this.state.nfeIntegrado,
    };
    MoedaService.addMoeda(moedas).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/moedas");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar Moeda
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="descricao"
	            fullWidth
	            margin="normal"
	            name="descricao"
	            value={this.state.descricao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tipo"
	            fullWidth
	            margin="normal"
	            name="tipo"
	            value={this.state.tipo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="perDesc"
	            fullWidth
	            margin="normal"
	            name="perDesc"
	            value={this.state.perDesc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="diasComp"
	            fullWidth
	            margin="normal"
	            name="diasComp"
	            value={this.state.diasComp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codecf"
	            fullWidth
	            margin="normal"
	            name="codecf"
	            value={this.state.codecf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="naoVisFech"
	            fullWidth
	            margin="normal"
	            name="naoVisFech"
	            value={this.state.naoVisFech}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dadosPos"
	            fullWidth
	            margin="normal"
	            name="dadosPos"
	            value={this.state.dadosPos}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="baixarSempre"
	            fullWidth
	            margin="normal"
	            name="baixarSempre"
	            value={this.state.baixarSempre}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="numdias"
	            fullWidth
	            margin="normal"
	            name="numdias"
	            value={this.state.numdias}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="numdiasDemais"
	            fullWidth
	            margin="normal"
	            name="numdiasDemais"
	            value={this.state.numdiasDemais}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="conChe"
	            fullWidth
	            margin="normal"
	            name="conChe"
	            value={this.state.conChe}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="gp"
	            fullWidth
	            margin="normal"
	            name="gp"
	            value={this.state.gp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="descricaoEcf"
	            fullWidth
	            margin="normal"
	            name="descricaoEcf"
	            value={this.state.descricaoEcf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codbanco"
	            fullWidth
	            margin="normal"
	            name="codbanco"
	            value={this.state.codbanco}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfePag"
	            fullWidth
	            margin="normal"
	            name="nfePag"
	            value={this.state.nfePag}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfeBand"
	            fullWidth
	            margin="normal"
	            name="nfeBand"
	            value={this.state.nfeBand}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfeIntegrado"
	            fullWidth
	            margin="normal"
	            name="nfeIntegrado"
	            value={this.state.nfeIntegrado}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.saveMoeda}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddMoedaComponent);
