import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListResControleComponent() {
  const colunas = [
    { title: "Codigo", field: "codformapagamento" },
    { title: "Descrição", field: "descricao" },
    { title: "Parcelas", field: "parcelas" },
    { title: "Dias 1ª parc.", field: "numdias" },
    { title: "Dias Demais parc.", field: "numdiasDemais" },
    { title: "Código Moeda", field: "codmoeda" },
    { title: "Acréscimo", field: "perAcres" },
    { title: "Separar", field: "separar" },
    { title: "VRP", field: "vrp" },
    { title: "Tipo de Preço", field: "tipopreco" }
  ];

  return (
    <div>
      <ListComponent
        route="formapagamentos"
        listKeys={colunas}
        title="Formas de Pagamento"
      />
    </div>
  );
}
