import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListPedvendaFechamentoComponent() {
  const colunas = [
    { title: "Código", field: "codpedvendfechamento" },
    { title: "Cód. Ped. Venda", field: "codpedvenda" },
    { title: "Cod. Moeda", field: "codmoeda" },
    { title: "Valor", field: "vlr" },
    { title: "Cod. Aber. Caixa", field: "codcaixaabertura" },
    { title: "Pos. Nr. Autorização", field: "posNrAutorizacao" },
    { title: "Pos. Pessoa", field: "posPessoa" },
    { title: "Qtde", field: "qtde" },
    { title: "Revisão", field: "revisao" },
    { title: "Cod. Empresa", field: "codempresa" }
  ];

  return (
    <div>
      <ListComponent
        route="pedvendafechamentos"
        listKeys={colunas}
        title="Pedidos de Venda - Fechamento"
      />
    </div>
  );
}
