import React, { Fragment, useState } from "react";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import { Container } from "../ui/base";
import { isMobile } from "react-device-detect";
import moment from "moment";

export default function GeneralSettings() {

    const auth = useSelector(state => state.auth);
     const [pass, setPass] = useState("");
     const [cpass, setCPass] = useState("");
     const [login, setLogin] = useState("");
     const [message, setMessage] = useState("");


     function handleChangeLogin(e) {
         setLogin(e.target.value);
     }
     function handleChangeCPass(e) {
        setCPass(e.target.value);
    }
    function handleChangePass(e) {
        setPass(e.target.value);
    }
    function justDoIt(){
        
        if(login === ""){
            setMessage("Digite seu login.");
            return;
        }
        if(pass !== cpass){
            setMessage("As senhas não conferem.");
            return;
        }

        setMessage("Senha alterada com sucesso!");

    }


    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h3" style={styles.style}>
                        Configurações
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                <Card style={styles.card}>
                
            <CardContent>
            <Typography variant="h4" style={styles.titCard}>
                        Alterar senha
                    </Typography>
              <form>
              <input type="hidden" value="prayer" />
                <TextField
                  style={styles.textField}
                  type="text"
                  label="E-MAIL"
                  margin="normal"
                  name="livmealone"
                  autoComplete="new-password"
                  value={login}
                  onChange={handleChangeLogin}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Digite seu login"
                />
                <TextField
                  style={styles.textField}
                  type="password"
                  margin="normal"
                  name="pass"
                  autoComplete="new-password"
                  value={pass}
                  onChange={handleChangePass}
                  label="NOVA SENHA"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeyIcon />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Digite a nova senha"
                />
                <TextField
                  style={styles.textField}
                  type="password"
                  margin="normal"
                  name="cpass"
                  autoComplete="new-password"
                  value={cpass}
                  onChange={handleChangeCPass}
                  label="CONFIRME A NOVA SENHA"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeyIcon />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Confirme a nova senha"
                />
                <h5 style={styles.notification}>{message}</h5>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => justDoIt()}
                  style={styles.button}
                  size="large"
                >
                  ALTERAR SENHA
                </Button>
              </form>
            </CardContent>
          </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

const styles = {
    titCard : {
        fontSize: "14px",
        fontWeight: "600",
        fontFamily: "Poppins"
    },
    style: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Poppins",
    fontWeight: "bold",
    margin: "20px"
  },
  center: {
    display: "flex",
    justifyContent: "center"
  },
  button: {
    margin: "10px",
    width: "95%",
    backgroundColor: "#5a9495"
  },
  notification: {
    display: "flex",
    justifyContent: "center",
    color: "#dc3545",
    minHeight: "10px"
  },
  card: {
    minWidth: 275,
    maxWidth: 350,
    margin: "50px auto",
    padding: "10px",
    background: "#FFF"
  },
  pos: {
    marginBottom: 12
  },
  textField: {
    backgroundColor: "none",
    width: "95%",
    margin: "20px 10px"
  },
  input: {
    backgroundColor: "none"
  },
  margin: {
    margin: "5% auto",
    width: "100%"
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    backgroundSize: "contain",
    margin: "50px"
  }
}
