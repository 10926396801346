import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditComponent from "../ui/EditComponent";

export default function EditPessoaGrupoComponent() {
  const history = useHistory();
  const dadosRedux = useSelector(state => state.cadastros.cadastro);
  const dadosStorage = JSON.parse(localStorage.getItem("bancos"));

  const colunas = [
    { title: "Código", field: "codbanco" },
    { title: "Descrição", field: "descricao" },
    { title: "Saldo Inicial", field: "saldoInicial" },
    { title: "Saldo Atual", field: "saldoAtual" },
    { title: "Contas", field: "contas" },
    { title: "Revisão", field: "revisao" },
    { title: "Agência", field: "agencia" },
    { title: "Operação", field: "operacao" },
    { title: "Conta", field: "conta" },
    { title: "Data Inicial", field: "dataInicial" }
  ];

  var retorno;

  if (!dadosRedux && !dadosStorage) {
    retorno = <></>;
    history.push("bancos");
  } else {
    retorno = (
      <div>
        <EditComponent
          title="Banco"
          data={dadosRedux || dadosStorage}
          colunas={colunas}
        />
      </div>
    );
  }

  return retorno;
}
