import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class PedvendaFechamentoService {
  fetchPedvendaFechamentos() {
    return axios.get(config.baseURL + '/pedvendafechamentos/', Headers);
  }

  fetchPedvendaFechamentoById(codpedvendfechamento) {
    return axios.get(config.baseURL + '/pedvendafechamentos/' + codpedvendfechamento, Headers);
  }

  deletePedvendaFechamento(codpedvendfechamento) {
    return axios.delete(config.baseURL + '/pedvendafechamentos/' + codpedvendfechamento, Headers);
  }

  addPedvendaFechamento(pedvendaFechamento) {
    return axios.post(config.baseURL + '/pedvendafechamentos/', pedvendaFechamento, Headers);
  }

  editPedvendaFechamento(pedvendaFechamento) {
    return axios.put(config.baseURL + '/pedvendafechamentos/' + pedvendaFechamento.codpedvendfechamento, pedvendaFechamento, Headers);
  }
}

export default new PedvendaFechamentoService();