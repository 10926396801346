import { combineReducers } from "redux";

import auth from "./auth";
import dashboard from "./dashboard";
import spinner from "./spinner";
import cadastros from "./cadastros";
import user from "./user";

export default combineReducers({
  auth,
  dashboard,
  spinner,
  cadastros,
  user
});
