import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListParGeralComponent() {
  const colunas = [
    { title: "Código", field: "codpargeral" },
    { title: "Empresa", field: "empresa" },
    { title: "Endereço", field: "endereco" },
    { title: "Bairro", field: "bairro" },
    { title: "Cidade", field: "cidade" },
    { title: "UF", field: "uf" },
    { title: "CEP", field: "cep" },
    { title: "Telefone", field: "telefone" },
    { title: "CPF", field: "cpfcgc" },
    { title: "IE", field: "ie" }
  ];

  return (
    <div>
      <ListComponent
        route="pargerals"
        listKeys={colunas}
        title="Parâmetros Gerais"
      />
    </div>
  );
}
