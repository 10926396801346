import React, { Component } from "react";
import ResGarconService from "../../service/ResGarconService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddResGarconComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: "",
      senha: "",
      perc: "",
      entregador: "",
      message: null
    };
    this.saveResGarcon = this.saveResGarcon.bind(this);
  }

  saveResGarcon = e => {
    e.preventDefault();
    let resGarcons = {
      nome: this.state.nome,
      senha: this.state.senha,
      perc: this.state.perc,
      entregador: this.state.entregador,
    };
    ResGarconService.addResGarcon(resGarcons).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/resgarcons");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar ResGarcon
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="nome"
	            fullWidth
	            margin="normal"
	            name="nome"
	            value={this.state.nome}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="senha"
	            fullWidth
	            margin="normal"
	            name="senha"
	            value={this.state.senha}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="perc"
	            fullWidth
	            margin="normal"
	            name="perc"
	            value={this.state.perc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="entregador"
	            fullWidth
	            margin="normal"
	            name="entregador"
	            value={this.state.entregador}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.saveResGarcon}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddResGarconComponent);
