import React, { Component } from "react";
import ApiService from "../../service/ApiService";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";
import { Container } from "../ui/base";
import { bindActionCreators } from "redux";
import * as UsersActions from "../../redux/actions/user";

class ListUserComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      message: null,
      onDeleting: false,
      deletedUser: false,
      deletedUserMessage: ""
    };
    this.deleteUser = this.deleteUser.bind(this);
    this.editUser = this.editUser.bind(this);
    this.addUser = this.addUser.bind(this);
    this.reloadUserList = this.reloadUserList.bind(this);
    console.log(props);
  }

  componentDidMount() {
    this.reloadUserList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.onDeleting !== prevProps.onDeleting) {
      this.reloadUserList();
    }
  }

  reloadUserList() {
    ApiService.fetchUsers(this.props.auth.token).then(res => {
      this.setState({ users: res.data.data });
    });
  }

  deleteUser(userId) {
    this.setState({
      onDeleting: true
    });
    const { removeUserRequest } = this.props;
    removeUserRequest(userId);
  }

  editUser({ id, cellphone, name, email }) {
    const { setUserToEdit } = this.props;
    const userToEdit = { id, name, email, cellphone };
    setUserToEdit(userToEdit);
    this.props.history.push("/editarusuario");
  }

  addUser() {
    window.localStorage.removeItem("userId");
    this.props.history.push("/adicionarusuario");
  }

  render() {
    let spinner;

    if (this.props.onDeleting) {
      spinner = (
        <CircularProgress size={20} color="inherit" style={styles.spinner} />
      );
    } else {
      spinner = "";
    }
    return (
      <div>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h3" style={styles.style}>
                Usuários
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box display="flex" justifyContent="flex-end">
                {spinner}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.addUser()}
                  style={styles.button}
                >
                  Adicionar Usuário{" "}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">E-mail</TableCell>
                    <TableCell align="left">Telefone</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.users.map(row => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.cellphone}</TableCell>
                      <TableCell
                        align="right"
                        onClick={() => this.editUser(row)}
                      >
                        <CreateIcon style={styles.pointer} />
                      </TableCell>
                      <TableCell
                        align="right"
                        onClick={() => this.deleteUser(row.id)}
                      >
                        <DeleteIcon style={styles.pointer} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

const styles = {
  style: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Poppins",
    fontWeight: "bold",
    margin: "50px auto 10px",
    color: "#5a9495"
  },
  button: {
    margin: "0",
    width: "95%",
    backgroundColor: "#5a9495",
    maxWidth: "300px"
  },
  pointer: {
    cursor: "pointer",
    color: "#5a9495"
  },
  spinner: {
    margin: "10px"
  }
};

const mapStateToProps = state => ({
  auth: state.auth,
  onDeleting: state.user.onDeleting,
  deletedUser: state.user.status,
  deletedUserMessage: state.user.status
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(UsersActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListUserComponent);
