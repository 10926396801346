import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class ResGarconService {
  fetchResGarcons() {
    return axios.get(config.baseURL + '/resgarcons/', Headers);
  }

  fetchResGarconById(codgarcon) {
    return axios.get(config.baseURL + '/resgarcons/' + codgarcon, Headers);
  }

  deleteResGarcon(codgarcon) {
    return axios.delete(config.baseURL + '/resgarcons/' + codgarcon, Headers);
  }

  addResGarcon(resGarcon) {
    return axios.post(config.baseURL + '/resgarcons/', resGarcon, Headers);
  }

  editResGarcon(resGarcon) {
    return axios.put(config.baseURL + '/resgarcons/' + resGarcon.codgarcon, resGarcon, Headers);
  }
}

export default new ResGarconService();