import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditComponent from "../ui/EditComponent";

export default function EditResControle() {
  const history = useHistory();
  const dadosRedux = useSelector(state => state.cadastros.cadastro);
  const dadosStorage = JSON.parse(localStorage.getItem("resgarcons"));

  const colunas = [
    { title: "Código", field: "codgarcon" },
    { title: "Nome", field: "nome" },
    { title: "Senha", field: "senha" },
    { title: "Percentual", field: "perc" },
    { title: "Entregador?", field: "entregador" }
  ];

  var retorno;

  if (!dadosRedux && !dadosStorage) {
    retorno = <></>;
    history.push("resgarcons");
  } else {
    retorno = (
      <div>
        <EditComponent
          title="Garçon"
          data={dadosRedux || dadosStorage}
          colunas={colunas}
        />
      </div>
    );
  }

  return retorno;
}
