import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class ParGeralService {
  fetchParGerals() {
    return axios.get(config.baseURL + '/pargerals/', Headers);
  }

  fetchParGeralById(codpargeral) {
    return axios.get(config.baseURL + '/pargerals/' + codpargeral, Headers);
  }

  deleteParGeral(codpargeral) {
    return axios.delete(config.baseURL + '/pargerals/' + codpargeral, Headers);
  }

  addParGeral(parGeral) {
    return axios.post(config.baseURL + '/pargerals/', parGeral, Headers);
  }

  editParGeral(parGeral) {
    return axios.put(config.baseURL + '/pargerals/' + parGeral.codpargeral, parGeral, Headers);
  }
}

export default new ParGeralService();