import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

function createData(name, total) {
  return { name, total };
}

var rows = [
  createData("Frozen yoghurt", 159),
  createData("Ice cream sandwich", 237),
  createData("Eclair", 262),
  createData("Cupcake", 305),
  createData("Gingerbread", 356)
];

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2),
    overflowX: "auto"
  },
  table: {}
}));

export default function CustomizedTables(props) {
  const classes = useStyles();
  if (props.dados !== undefined) {
    rows = props.dados;
  }

  const BRL = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  });

  const content = rows.map(dados => (
    <StyledTableRow key={dados.descricao}>
      <StyledTableCell component="th" scope="row">
        {dados.descricao}
      </StyledTableCell>
      <StyledTableCell align="right">
        {props.tipo === "moeda" ? BRL.format(dados.total) : dados.total}
      </StyledTableCell>
    </StyledTableRow>
  ));

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="customized table">
        <TableBody>{content}</TableBody>
      </Table>
    </Paper>
  );
}
