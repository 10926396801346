import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class PedvendaItemService {
  fetchPedvendaItems() {
    return axios.get(config.baseURL + '/pedvendaitems/', Headers);
  }

  fetchPedvendaItemById(codpedvendaitem) {
    return axios.get(config.baseURL + '/pedvendaitems/' + codpedvendaitem, Headers);
  }

  deletePedvendaItem(codpedvendaitem) {
    return axios.delete(config.baseURL + '/pedvendaitems/' + codpedvendaitem, Headers);
  }

  addPedvendaItem(pedvendaItem) {
    return axios.post(config.baseURL + '/pedvendaitems/', pedvendaItem, Headers);
  }

  editPedvendaItem(pedvendaItem) {
    return axios.put(config.baseURL + '/pedvendaitems/' + pedvendaItem.codpedvendaitem, pedvendaItem, Headers);
  }
}

export default new PedvendaItemService();