export default function auth(state = [], action) {
  switch (action.type) {
    case "LOGIN_USER":
      return {
        ...state,
        token: action.payload.token,
        isLogged: action.payload.logged
      };
    case "LOGOUT_USER":
      return {
        ...state,
        token: action.payload.token,
        isLogged: action.payload.logged
      };
    default:
      return state;
  }
}
