import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditComponent from "../ui/EditComponent";

export default function EditParGeralComponent() {
  const history = useHistory();
  const dadosRedux = useSelector(state => state.cadastros.cadastro);
  const dadosStorage = JSON.parse(localStorage.getItem("pargerals"));

  const colunas = [
    { title: "Código", field: "codpargeral" },
    { title: "Empresa", field: "empresa" },
    { title: "Endereço", field: "endereco" },
    { title: "Bairro", field: "bairro" },
    { title: "Cidade", field: "cidade" },
    { title: "UF", field: "uf" },
    { title: "CEP", field: "cep" },
    { title: "Telefone", field: "telefone" },
    { title: "CPF", field: "cpfcgc" },
    { title: "IE", field: "ie" }
  ];

  var retorno;

  if (!dadosRedux && !dadosStorage) {
    retorno = <></>;
    history.push("pargerals");
  } else {
    retorno = (
      <div>
        <EditComponent
          title="Parâmetro Geral"
          data={dadosRedux || dadosStorage}
          colunas={colunas}
        />
      </div>
    );
  }

  return retorno;
}
