import React, { Component } from "react";
import ParRestauranteService from "../../service/ParRestauranteService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddParRestauranteComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      controleNome: "",
      trabalharRest: "",
      impCozinha: "",
      linhaFinalImpressao: "",
      impExtrato: "",
      vlrCovert: "",
      imprimirIeim: "",
      selecionarGarcon: "",
      codpesqVenda: "",
      somenteDuracao: "",
      impBar: "",
      vlrServico: "",
      impPizza: "",
      impChurrasqueira: "",
      message: null
    };
    this.saveParRestaurante = this.saveParRestaurante.bind(this);
  }

  saveParRestaurante = e => {
    e.preventDefault();
    let parRestaurantes = {
      controleNome: this.state.controleNome,
      trabalharRest: this.state.trabalharRest,
      impCozinha: this.state.impCozinha,
      linhaFinalImpressao: this.state.linhaFinalImpressao,
      impExtrato: this.state.impExtrato,
      vlrCovert: this.state.vlrCovert,
      imprimirIeim: this.state.imprimirIeim,
      selecionarGarcon: this.state.selecionarGarcon,
      codpesqVenda: this.state.codpesqVenda,
      somenteDuracao: this.state.somenteDuracao,
      impBar: this.state.impBar,
      vlrServico: this.state.vlrServico,
      impPizza: this.state.impPizza,
      impChurrasqueira: this.state.impChurrasqueira,
    };
    ParRestauranteService.addParRestaurante(parRestaurantes).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/parrestaurantes");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar ParRestaurante
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="controleNome"
	            fullWidth
	            margin="normal"
	            name="controleNome"
	            value={this.state.controleNome}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="trabalharRest"
	            fullWidth
	            margin="normal"
	            name="trabalharRest"
	            value={this.state.trabalharRest}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="impCozinha"
	            fullWidth
	            margin="normal"
	            name="impCozinha"
	            value={this.state.impCozinha}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="linhaFinalImpressao"
	            fullWidth
	            margin="normal"
	            name="linhaFinalImpressao"
	            value={this.state.linhaFinalImpressao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="impExtrato"
	            fullWidth
	            margin="normal"
	            name="impExtrato"
	            value={this.state.impExtrato}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrCovert"
	            fullWidth
	            margin="normal"
	            name="vlrCovert"
	            value={this.state.vlrCovert}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="imprimirIeim"
	            fullWidth
	            margin="normal"
	            name="imprimirIeim"
	            value={this.state.imprimirIeim}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="selecionarGarcon"
	            fullWidth
	            margin="normal"
	            name="selecionarGarcon"
	            value={this.state.selecionarGarcon}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codpesqVenda"
	            fullWidth
	            margin="normal"
	            name="codpesqVenda"
	            value={this.state.codpesqVenda}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="somenteDuracao"
	            fullWidth
	            margin="normal"
	            name="somenteDuracao"
	            value={this.state.somenteDuracao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="impBar"
	            fullWidth
	            margin="normal"
	            name="impBar"
	            value={this.state.impBar}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlrServico"
	            fullWidth
	            margin="normal"
	            name="vlrServico"
	            value={this.state.vlrServico}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="impPizza"
	            fullWidth
	            margin="normal"
	            name="impPizza"
	            value={this.state.impPizza}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="impChurrasqueira"
	            fullWidth
	            margin="normal"
	            name="impChurrasqueira"
	            value={this.state.impChurrasqueira}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.saveParRestaurante}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddParRestauranteComponent);
