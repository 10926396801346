import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditComponent from "../ui/EditComponent";

export default function EditResControle() {
  const history = useHistory();
  const dadosRedux = useSelector(state => state.cadastros.cadastro);
  const dadosStorage = JSON.parse(localStorage.getItem("moedas"));

  const colunas = [
    { title: "Codigo", field: "codmoeda" },
    { title: "Descrição", field: "descricao" },
    { title: "Tipo", field: "tipo" },
    { title: "Desconto", field: "perDesc" },
    { title: "Compensação", field: "diasComp" },
    { title: "ECF", field: "codecf" },
    { title: "Ver no Fech.", field: "naoVisFech" },
    { title: "Dados Pos", field: "dadosPos" },
    { title: "Baixar Sempre", field: "baixarSempre" },
    { title: "Dias", field: "numdias" }
  ];

  var retorno;

  if (!dadosRedux && !dadosStorage) {
    retorno = <></>;
    history.push("moedas");
  } else {
    retorno = (
      <div>
        <EditComponent
          title="Moeda"
          data={dadosRedux || dadosStorage}
          colunas={colunas}
        />
      </div>
    );
  }

  return retorno;
}
