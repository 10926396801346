import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListParRestauranteComponent() {
  const colunas = [
    { title: "Código", field: "codparrestaurante" },
    { title: "Controle Nome", field: "controleNome" },
    { title: "Trabalhar Restaurante", field: "trabalharRest" },
    { title: "Imp. Cozinha", field: "impCozinha" },
    { title: "Linha Final Impressão", field: "linhaFinalImpressao" },
    { title: "Imp. Extrato", field: "impExtrato" },
    { title: "Valor Couvert", field: "vlrCovert" },
    { title: "Imprimir Teim.", field: "imprimirIeim" },
    { title: "Selecionar Garçon", field: "selecionarGarcon" },
    { title: "Cod. Pesq. Venda", field: "codpesqVenda" }
  ];

  return (
    <div>
      <ListComponent
        route="parrestaurantes"
        listKeys={colunas}
        title="Parâmetros Restaurante"
      />
    </div>
  );
}
