import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class PessoaGrupoService {
  fetchPessoaGrupos() {
    return axios.get(config.baseURL + '/pessoagrupos/', Headers);
  }

  fetchPessoaGrupoById(codpessoagrupo) {
    return axios.get(config.baseURL + '/pessoagrupos/' + codpessoagrupo, Headers);
  }

  deletePessoaGrupo(codpessoagrupo) {
    return axios.delete(config.baseURL + '/pessoagrupos/' + codpessoagrupo, Headers);
  }

  addPessoaGrupo(pessoaGrupo) {
    return axios.post(config.baseURL + '/pessoagrupos/', pessoaGrupo, Headers);
  }

  editPessoaGrupo(pessoaGrupo) {
    return axios.put(config.baseURL + '/pessoagrupos/' + pessoaGrupo.codpessoagrupo, pessoaGrupo, Headers);
  }
}

export default new PessoaGrupoService();