export default function spinner(state = [], action) {
  switch (action.type) {
    case "LOADING_ON":
      return {
        ...state,
        spinner: true
      };
    case "LOADING_OFF":
      return {
        ...state,
        spinner: false
      };

    default:
      return state;
  }
}
