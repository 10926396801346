import React, { Component } from "react";
import PessoaGrupoService from "../../service/PessoaGrupoService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddPessoaGrupoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descricao: "",
      taxa: "",
      percDesconto: "",
      message: null
    };
    this.savePessoaGrupo = this.savePessoaGrupo.bind(this);
  }

  savePessoaGrupo = e => {
    e.preventDefault();
    let pessoaGrupos = {
      descricao: this.state.descricao,
      taxa: this.state.taxa,
      percDesconto: this.state.percDesconto,
    };
    PessoaGrupoService.addPessoaGrupo(pessoaGrupos).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/pessoagrupos");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar PessoaGrupo
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="descricao"
	            fullWidth
	            margin="normal"
	            name="descricao"
	            value={this.state.descricao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="taxa"
	            fullWidth
	            margin="normal"
	            name="taxa"
	            value={this.state.taxa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="percDesconto"
	            fullWidth
	            margin="normal"
	            name="percDesconto"
	            value={this.state.percDesconto}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.savePessoaGrupo}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddPessoaGrupoComponent);
