import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import * as CadastrosActions from "../../redux/actions/cadastros";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Container } from "../ui/base";

export default function ListComponent({ route, title, listKeys }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const lista = useSelector(state => state.cadastros.lista);
  const loading = useSelector(state => state.cadastros.loading);

  const [message, setMessage] = useState("");

  useEffect(() => {
    reloadList();
  }, []);

  function reloadList() {
    dispatch(CadastrosActions.listaCadastrosRequest(route));
  }

  function deleteItem(codigo) {
    alert(JSON.stringify(codigo));
  }

  function editItem(data) {
    const id = data.id;
    delete data.created_at;
    delete data.updated_at;
    delete data.tableData;
    delete data.id;
    localStorage.setItem(route, JSON.stringify(data));
    dispatch(CadastrosActions.alteraCadastroSet(route, id, data));
    history.push("/edit-" + route);
  }

  function addItem() {
    history.push("/add-" + route);
  }

  let spinner;

  if (loading) {
    spinner = (
      <CircularProgress size={20} color="inherit" style={styles.spinner} />
    );
  } else {
    spinner = "";
  }

  const styles = {
    style: {
      display: "flex",
      justifyContent: "center",
      fontFamily: "Poppins",
      fontWeight: "bold",
      margin: "50px auto 10px",
      color: "#5a9495"
    },
    button: {
      margin: "0",
      width: "95%",
      backgroundColor: "#5a9495",
      maxWidth: "300px"
    },
    pointer: {
      cursor: "pointer",
      color: "#5a9495"
    },
    spinner: {
      margin: "10px"
    }
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h4" style={styles.style}>
            {title}
          </Typography>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Box display="flex" justifyContent="flex-end">
              {spinner}
              <Button
                variant="contained"
                color="primary"
                onClick={() => addItem()}
                style={styles.button}
              >
                Adicionar {title}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <MaterialTable
              title={title}
              columns={listKeys}
              data={lista}
              editable={false}
              options={{
                paging: false,
                actionsColumnIndex: -1
              }}
              actions={[
                {
                  icon: () => <CreateIcon />,
                  tooltip: "Alterar",
                  onClick: (event, rowData) => {
                    editItem(rowData);
                  }
                },
                {
                  icon: () => <DeleteIcon />,
                  tooltip: "Excluir",
                  onClick: (event, rowData) => {
                    deleteItem(rowData);
                  }
                }
              ]}
              localization={{
                toolbar: {
                  searchPlaceholder: "filtrar"
                },
                header: {
                  actions: "Ações"
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
