import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditComponent from "../ui/EditComponent";

export default function EditPedvendaItemComponent() {
  const history = useHistory();
  const dadosRedux = useSelector(state => state.cadastros.cadastro);
  const dadosStorage = JSON.parse(localStorage.getItem("pedvendaitems"));

  const colunas = [
    { title: "Código", field: "codpedvendaitem" },
    { title: "Cód. Pedido de Venda.", field: "codpedvenda" },
    { title: "Produto", field: "codproduto" },
    { title: "Tipo Preço", field: "tipopreco" },
    { title: "Qtde.", field: "qtde" },
    { title: "Valor Desconto", field: "descvlr" },
    { title: "Valor Unitário", field: "vlrunit" },
    { title: "Cód. Pequisa", field: "codpesquisa" },
    { title: "Item", field: "item" },
    { title: "Qtde. Faturada.", field: "qtdeFat" }
  ];

  var retorno;

  if (!dadosRedux && !dadosStorage) {
    retorno = <></>;
    history.push("pedvendaitems");
  } else {
    retorno = (
      <div>
        <EditComponent
          title="Pedidos de Venda - Item"
          data={dadosRedux || dadosStorage}
          colunas={colunas}
        />
      </div>
    );
  }

  return retorno;
}
