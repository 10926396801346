import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListPessoaComponent() {
  const colunas = [
    { title: "Código", field: "codpessoa" },
    { title: "Tipo", field: "tipoPessoa" },
    { title: "Nome", field: "nome" },
    { title: "Razão Social", field: "razaoSocial" },
    { title: "Doc.", field: "cpfcnpj" },
    { title: "IE", field: "ie" },
    { title: "IM", field: "im" },
    { title: "Endereço", field: "endereco" },
    { title: "Número", field: "enderecoNumero" },
    { title: "Complemento", field: "enderecoComplemento" }
  ];

  return (
    <div>
      <ListComponent route="pessoas" listKeys={colunas} title="Pessoas" />
    </div>
  );
}
