import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class ConfiguracaoGeralService {
  fetchConfiguracaoGerals() {
    return axios.get(config.baseURL + '/configuracaogerals/', Headers);
  }

  fetchConfiguracaoGeralById(chave) {
    return axios.get(config.baseURL + '/configuracaogerals/' + chave, Headers);
  }

  deleteConfiguracaoGeral(chave) {
    return axios.delete(config.baseURL + '/configuracaogerals/' + chave, Headers);
  }

  addConfiguracaoGeral(configuracaoGeral) {
    return axios.post(config.baseURL + '/configuracaogerals/', configuracaoGeral, Headers);
  }

  editConfiguracaoGeral(configuracaoGeral) {
    return axios.put(config.baseURL + '/configuracaogerals/' + configuracaoGeral.chave, configuracaoGeral, Headers);
  }
}

export default new ConfiguracaoGeralService();