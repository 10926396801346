import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class PessoaService {
  fetchPessoas() {
    return axios.get(config.baseURL + '/pessoas/', Headers);
  }

  fetchPessoaById(codpessoa) {
    return axios.get(config.baseURL + '/pessoas/' + codpessoa, Headers);
  }

  deletePessoa(codpessoa) {
    return axios.delete(config.baseURL + '/pessoas/' + codpessoa, Headers);
  }

  addPessoa(pessoa) {
    return axios.post(config.baseURL + '/pessoas/', pessoa, Headers);
  }

  editPessoa(pessoa) {
    return axios.put(config.baseURL + '/pessoas/' + pessoa.codpessoa, pessoa, Headers);
  }
}

export default new PessoaService();