import React, { Component } from "react";
import CfopService from "../../service/CfopService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddCfopComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descricao: "",
      gerafinanceiro: "",
      classificacao: "",
      tipo: "",
      message: null
    };
    this.saveCfop = this.saveCfop.bind(this);
  }

  saveCfop = e => {
    e.preventDefault();
    let cfops = {
      descricao: this.state.descricao,
      gerafinanceiro: this.state.gerafinanceiro,
      classificacao: this.state.classificacao,
      tipo: this.state.tipo,
    };
    CfopService.addCfop(cfops).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/cfops");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar Cfop
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="descricao"
	            fullWidth
	            margin="normal"
	            name="descricao"
	            value={this.state.descricao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="gerafinanceiro"
	            fullWidth
	            margin="normal"
	            name="gerafinanceiro"
	            value={this.state.gerafinanceiro}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="classificacao"
	            fullWidth
	            margin="normal"
	            name="classificacao"
	            value={this.state.classificacao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tipo"
	            fullWidth
	            margin="normal"
	            name="tipo"
	            value={this.state.tipo}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.saveCfop}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddCfopComponent);
