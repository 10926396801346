import React, { Component } from "react";
import ParVendaService from "../../service/ParVendaService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddParVendaComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      default: "",
      ecfModelo: "",
      ecfPorta: "",
      ecfTimeout: "",
      ecfIntervalo: "",
      ecfTentNova: "",
      ecfBloqTeclMouse: "",
      ecfExibMsgAguarde: "",
      ecfArreQtde: "",
      ecfGavSinInv: "",
      ecfVerifGav: "",
      ecfAbriGav: "",
      ecfAliq: "",
      ecfFpg: "",
      ecfMoe: "",
      ecf: "",
      margem01: "",
      margem02: "",
      margem03: "",
      margem04: "",
      margem05: "",
      int: "",
      ext: "",
      custoAdm: "",
      impFed: "",
      superSimp: "",
      impFront: "",
      impSaida: "",
      nfvenda: "",
      nfcompra: "",
      assinaturaPedido: "",
      obsPedido: "",
      impVendedor: "",
      assinatura2Pedido: "",
      senhaDesconto: "",
      principal: "",
      secundario: "",
      aberturaCaixa: "",
      fechamentoTipo: "",
      desconto1: "",
      desconto2: "",
      desconto3: "",
      senhaDesconto2: "",
      senhaDesconto3: "",
      custoTpLente1: "",
      custoTpLente2: "",
      custoTpLente3: "",
      qtdeLinhasRel: "",
      imprimirPdv: "",
      naoGerarCodigo: "",
      cancelSomenteDia: "",
      senhaCancelDepois: "",
      fundoCaixa: "",
      margProd: "",
      precProdRo: "",
      qtdeProdPreco: "",
      preVendRp: "",
      senhaConsulta: "",
      custAjuste: "",
      msgEcf: "",
      itDesc1: "",
      itDesc2: "",
      itDesc3: "",
      itDesc4: "",
      itDescSenha1: "",
      itDescSenha2: "",
      itDescSenha3: "",
      itDescSenha4: "",
      diasCarencia: "",
      jurosDia: "",
      menorParcela: "",
      senhaMenorParcela: "",
      freteCif: "",
      freteFob: "",
      frete: "",
      estacVlrDiaria: "",
      estacTolHoras: "",
      aquisicaoVeiculo: "",
      freteVlrCombustivel: "",
      freteIndKmRodados: "",
      freteIndGastosPneu: "",
      freteIndManutencao: "",
      estacVlrDiariaBitrem: "",
      estacVlrDiariaTruck: "",
      estacVlrDiariaCarreta: "",
      estacQtdeHorasGratis: "",
      mensalidades: "",
      urlGeradorBoletos: "",
      boletoDemonstrativo1: "",
      boletoDemonstrativo2: "",
      boletoDemonstrativo3: "",
      custoKm: "",
      estacVlrDiariaTreminha: "",
      obsPedcompra: "",
      textoTermoEntrEpi: "",
      txtFinTermoEntrEpi: "",
      balIntervalo: "",
      balModelo: "",
      balMonitorar: "",
      balPorta: "",
      balModeloBalanca: "",
      balBaud: "",
      balData: "",
      balHandshake: "",
      balHardflow: "",
      balParity: "",
      balProcessMessages: "",
      balSendbytescount: "",
      balSendbytesinterval: "",
      balStop: "",
      balSoftflow: "",
      bolTipoCobranca: "",
      bolCedAgencia: "",
      bolCedAgenciaDigito: "",
      bolCedCodigo: "",
      bolCedCodTransmissao: "",
      bolCedConta: "",
      bolCedContaDig: "",
      bolCedRespTransmissao: "",
      boletos: "",
      bolMsgPadraoEmail: "",
      gadoVlrArrobaMerc: "",
      obsCarne: "",
      ecfVeloc115200: "",
      obsOrcamento: "",
      endAssLadoEsquerdo: "",
      bolCedConvenio: "",
      percMulta: "",
      message: null
    };
    this.saveParVenda = this.saveParVenda.bind(this);
  }

  saveParVenda = e => {
    e.preventDefault();
    let parVendas = {
      default: this.state.default,
      ecfModelo: this.state.ecfModelo,
      ecfPorta: this.state.ecfPorta,
      ecfTimeout: this.state.ecfTimeout,
      ecfIntervalo: this.state.ecfIntervalo,
      ecfTentNova: this.state.ecfTentNova,
      ecfBloqTeclMouse: this.state.ecfBloqTeclMouse,
      ecfExibMsgAguarde: this.state.ecfExibMsgAguarde,
      ecfArreQtde: this.state.ecfArreQtde,
      ecfGavSinInv: this.state.ecfGavSinInv,
      ecfVerifGav: this.state.ecfVerifGav,
      ecfAbriGav: this.state.ecfAbriGav,
      ecfAliq: this.state.ecfAliq,
      ecfFpg: this.state.ecfFpg,
      ecfMoe: this.state.ecfMoe,
      ecf: this.state.ecf,
      margem01: this.state.margem01,
      margem02: this.state.margem02,
      margem03: this.state.margem03,
      margem04: this.state.margem04,
      margem05: this.state.margem05,
      int: this.state.int,
      ext: this.state.ext,
      custoAdm: this.state.custoAdm,
      impFed: this.state.impFed,
      superSimp: this.state.superSimp,
      impFront: this.state.impFront,
      impSaida: this.state.impSaida,
      nfvenda: this.state.nfvenda,
      nfcompra: this.state.nfcompra,
      assinaturaPedido: this.state.assinaturaPedido,
      obsPedido: this.state.obsPedido,
      impVendedor: this.state.impVendedor,
      assinatura2Pedido: this.state.assinatura2Pedido,
      senhaDesconto: this.state.senhaDesconto,
      principal: this.state.principal,
      secundario: this.state.secundario,
      aberturaCaixa: this.state.aberturaCaixa,
      fechamentoTipo: this.state.fechamentoTipo,
      desconto1: this.state.desconto1,
      desconto2: this.state.desconto2,
      desconto3: this.state.desconto3,
      senhaDesconto2: this.state.senhaDesconto2,
      senhaDesconto3: this.state.senhaDesconto3,
      custoTpLente1: this.state.custoTpLente1,
      custoTpLente2: this.state.custoTpLente2,
      custoTpLente3: this.state.custoTpLente3,
      qtdeLinhasRel: this.state.qtdeLinhasRel,
      imprimirPdv: this.state.imprimirPdv,
      naoGerarCodigo: this.state.naoGerarCodigo,
      cancelSomenteDia: this.state.cancelSomenteDia,
      senhaCancelDepois: this.state.senhaCancelDepois,
      fundoCaixa: this.state.fundoCaixa,
      margProd: this.state.margProd,
      precProdRo: this.state.precProdRo,
      qtdeProdPreco: this.state.qtdeProdPreco,
      preVendRp: this.state.preVendRp,
      senhaConsulta: this.state.senhaConsulta,
      custAjuste: this.state.custAjuste,
      msgEcf: this.state.msgEcf,
      itDesc1: this.state.itDesc1,
      itDesc2: this.state.itDesc2,
      itDesc3: this.state.itDesc3,
      itDesc4: this.state.itDesc4,
      itDescSenha1: this.state.itDescSenha1,
      itDescSenha2: this.state.itDescSenha2,
      itDescSenha3: this.state.itDescSenha3,
      itDescSenha4: this.state.itDescSenha4,
      diasCarencia: this.state.diasCarencia,
      jurosDia: this.state.jurosDia,
      menorParcela: this.state.menorParcela,
      senhaMenorParcela: this.state.senhaMenorParcela,
      freteCif: this.state.freteCif,
      freteFob: this.state.freteFob,
      frete: this.state.frete,
      estacVlrDiaria: this.state.estacVlrDiaria,
      estacTolHoras: this.state.estacTolHoras,
      aquisicaoVeiculo: this.state.aquisicaoVeiculo,
      freteVlrCombustivel: this.state.freteVlrCombustivel,
      freteIndKmRodados: this.state.freteIndKmRodados,
      freteIndGastosPneu: this.state.freteIndGastosPneu,
      freteIndManutencao: this.state.freteIndManutencao,
      estacVlrDiariaBitrem: this.state.estacVlrDiariaBitrem,
      estacVlrDiariaTruck: this.state.estacVlrDiariaTruck,
      estacVlrDiariaCarreta: this.state.estacVlrDiariaCarreta,
      estacQtdeHorasGratis: this.state.estacQtdeHorasGratis,
      mensalidades: this.state.mensalidades,
      urlGeradorBoletos: this.state.urlGeradorBoletos,
      boletoDemonstrativo1: this.state.boletoDemonstrativo1,
      boletoDemonstrativo2: this.state.boletoDemonstrativo2,
      boletoDemonstrativo3: this.state.boletoDemonstrativo3,
      custoKm: this.state.custoKm,
      estacVlrDiariaTreminha: this.state.estacVlrDiariaTreminha,
      obsPedcompra: this.state.obsPedcompra,
      textoTermoEntrEpi: this.state.textoTermoEntrEpi,
      txtFinTermoEntrEpi: this.state.txtFinTermoEntrEpi,
      balIntervalo: this.state.balIntervalo,
      balModelo: this.state.balModelo,
      balMonitorar: this.state.balMonitorar,
      balPorta: this.state.balPorta,
      balModeloBalanca: this.state.balModeloBalanca,
      balBaud: this.state.balBaud,
      balData: this.state.balData,
      balHandshake: this.state.balHandshake,
      balHardflow: this.state.balHardflow,
      balParity: this.state.balParity,
      balProcessMessages: this.state.balProcessMessages,
      balSendbytescount: this.state.balSendbytescount,
      balSendbytesinterval: this.state.balSendbytesinterval,
      balStop: this.state.balStop,
      balSoftflow: this.state.balSoftflow,
      bolTipoCobranca: this.state.bolTipoCobranca,
      bolCedAgencia: this.state.bolCedAgencia,
      bolCedAgenciaDigito: this.state.bolCedAgenciaDigito,
      bolCedCodigo: this.state.bolCedCodigo,
      bolCedCodTransmissao: this.state.bolCedCodTransmissao,
      bolCedConta: this.state.bolCedConta,
      bolCedContaDig: this.state.bolCedContaDig,
      bolCedRespTransmissao: this.state.bolCedRespTransmissao,
      boletos: this.state.boletos,
      bolMsgPadraoEmail: this.state.bolMsgPadraoEmail,
      gadoVlrArrobaMerc: this.state.gadoVlrArrobaMerc,
      obsCarne: this.state.obsCarne,
      ecfVeloc115200: this.state.ecfVeloc115200,
      obsOrcamento: this.state.obsOrcamento,
      endAssLadoEsquerdo: this.state.endAssLadoEsquerdo,
      bolCedConvenio: this.state.bolCedConvenio,
      percMulta: this.state.percMulta,
    };
    ParVendaService.addParVenda(parVendas).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/parvendas");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar ParVenda
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="default"
	            fullWidth
	            margin="normal"
	            name="default"
	            value={this.state.default}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfModelo"
	            fullWidth
	            margin="normal"
	            name="ecfModelo"
	            value={this.state.ecfModelo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfPorta"
	            fullWidth
	            margin="normal"
	            name="ecfPorta"
	            value={this.state.ecfPorta}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfTimeout"
	            fullWidth
	            margin="normal"
	            name="ecfTimeout"
	            value={this.state.ecfTimeout}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfIntervalo"
	            fullWidth
	            margin="normal"
	            name="ecfIntervalo"
	            value={this.state.ecfIntervalo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfTentNova"
	            fullWidth
	            margin="normal"
	            name="ecfTentNova"
	            value={this.state.ecfTentNova}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfBloqTeclMouse"
	            fullWidth
	            margin="normal"
	            name="ecfBloqTeclMouse"
	            value={this.state.ecfBloqTeclMouse}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfExibMsgAguarde"
	            fullWidth
	            margin="normal"
	            name="ecfExibMsgAguarde"
	            value={this.state.ecfExibMsgAguarde}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfArreQtde"
	            fullWidth
	            margin="normal"
	            name="ecfArreQtde"
	            value={this.state.ecfArreQtde}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfGavSinInv"
	            fullWidth
	            margin="normal"
	            name="ecfGavSinInv"
	            value={this.state.ecfGavSinInv}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfVerifGav"
	            fullWidth
	            margin="normal"
	            name="ecfVerifGav"
	            value={this.state.ecfVerifGav}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfAbriGav"
	            fullWidth
	            margin="normal"
	            name="ecfAbriGav"
	            value={this.state.ecfAbriGav}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfAliq"
	            fullWidth
	            margin="normal"
	            name="ecfAliq"
	            value={this.state.ecfAliq}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfFpg"
	            fullWidth
	            margin="normal"
	            name="ecfFpg"
	            value={this.state.ecfFpg}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfMoe"
	            fullWidth
	            margin="normal"
	            name="ecfMoe"
	            value={this.state.ecfMoe}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecf"
	            fullWidth
	            margin="normal"
	            name="ecf"
	            value={this.state.ecf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="margem01"
	            fullWidth
	            margin="normal"
	            name="margem01"
	            value={this.state.margem01}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="margem02"
	            fullWidth
	            margin="normal"
	            name="margem02"
	            value={this.state.margem02}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="margem03"
	            fullWidth
	            margin="normal"
	            name="margem03"
	            value={this.state.margem03}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="margem04"
	            fullWidth
	            margin="normal"
	            name="margem04"
	            value={this.state.margem04}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="margem05"
	            fullWidth
	            margin="normal"
	            name="margem05"
	            value={this.state.margem05}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="int"
	            fullWidth
	            margin="normal"
	            name="int"
	            value={this.state.int}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ext"
	            fullWidth
	            margin="normal"
	            name="ext"
	            value={this.state.ext}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="custoAdm"
	            fullWidth
	            margin="normal"
	            name="custoAdm"
	            value={this.state.custoAdm}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="impFed"
	            fullWidth
	            margin="normal"
	            name="impFed"
	            value={this.state.impFed}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="superSimp"
	            fullWidth
	            margin="normal"
	            name="superSimp"
	            value={this.state.superSimp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="impFront"
	            fullWidth
	            margin="normal"
	            name="impFront"
	            value={this.state.impFront}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="impSaida"
	            fullWidth
	            margin="normal"
	            name="impSaida"
	            value={this.state.impSaida}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfvenda"
	            fullWidth
	            margin="normal"
	            name="nfvenda"
	            value={this.state.nfvenda}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfcompra"
	            fullWidth
	            margin="normal"
	            name="nfcompra"
	            value={this.state.nfcompra}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="assinaturaPedido"
	            fullWidth
	            margin="normal"
	            name="assinaturaPedido"
	            value={this.state.assinaturaPedido}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="obsPedido"
	            fullWidth
	            margin="normal"
	            name="obsPedido"
	            value={this.state.obsPedido}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="impVendedor"
	            fullWidth
	            margin="normal"
	            name="impVendedor"
	            value={this.state.impVendedor}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="assinatura2Pedido"
	            fullWidth
	            margin="normal"
	            name="assinatura2Pedido"
	            value={this.state.assinatura2Pedido}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="senhaDesconto"
	            fullWidth
	            margin="normal"
	            name="senhaDesconto"
	            value={this.state.senhaDesconto}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="principal"
	            fullWidth
	            margin="normal"
	            name="principal"
	            value={this.state.principal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="secundario"
	            fullWidth
	            margin="normal"
	            name="secundario"
	            value={this.state.secundario}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="aberturaCaixa"
	            fullWidth
	            margin="normal"
	            name="aberturaCaixa"
	            value={this.state.aberturaCaixa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="fechamentoTipo"
	            fullWidth
	            margin="normal"
	            name="fechamentoTipo"
	            value={this.state.fechamentoTipo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="desconto1"
	            fullWidth
	            margin="normal"
	            name="desconto1"
	            value={this.state.desconto1}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="desconto2"
	            fullWidth
	            margin="normal"
	            name="desconto2"
	            value={this.state.desconto2}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="desconto3"
	            fullWidth
	            margin="normal"
	            name="desconto3"
	            value={this.state.desconto3}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="senhaDesconto2"
	            fullWidth
	            margin="normal"
	            name="senhaDesconto2"
	            value={this.state.senhaDesconto2}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="senhaDesconto3"
	            fullWidth
	            margin="normal"
	            name="senhaDesconto3"
	            value={this.state.senhaDesconto3}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="custoTpLente1"
	            fullWidth
	            margin="normal"
	            name="custoTpLente1"
	            value={this.state.custoTpLente1}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="custoTpLente2"
	            fullWidth
	            margin="normal"
	            name="custoTpLente2"
	            value={this.state.custoTpLente2}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="custoTpLente3"
	            fullWidth
	            margin="normal"
	            name="custoTpLente3"
	            value={this.state.custoTpLente3}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="qtdeLinhasRel"
	            fullWidth
	            margin="normal"
	            name="qtdeLinhasRel"
	            value={this.state.qtdeLinhasRel}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="imprimirPdv"
	            fullWidth
	            margin="normal"
	            name="imprimirPdv"
	            value={this.state.imprimirPdv}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="naoGerarCodigo"
	            fullWidth
	            margin="normal"
	            name="naoGerarCodigo"
	            value={this.state.naoGerarCodigo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cancelSomenteDia"
	            fullWidth
	            margin="normal"
	            name="cancelSomenteDia"
	            value={this.state.cancelSomenteDia}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="senhaCancelDepois"
	            fullWidth
	            margin="normal"
	            name="senhaCancelDepois"
	            value={this.state.senhaCancelDepois}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="fundoCaixa"
	            fullWidth
	            margin="normal"
	            name="fundoCaixa"
	            value={this.state.fundoCaixa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="margProd"
	            fullWidth
	            margin="normal"
	            name="margProd"
	            value={this.state.margProd}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="precProdRo"
	            fullWidth
	            margin="normal"
	            name="precProdRo"
	            value={this.state.precProdRo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="qtdeProdPreco"
	            fullWidth
	            margin="normal"
	            name="qtdeProdPreco"
	            value={this.state.qtdeProdPreco}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="preVendRp"
	            fullWidth
	            margin="normal"
	            name="preVendRp"
	            value={this.state.preVendRp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="senhaConsulta"
	            fullWidth
	            margin="normal"
	            name="senhaConsulta"
	            value={this.state.senhaConsulta}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="custAjuste"
	            fullWidth
	            margin="normal"
	            name="custAjuste"
	            value={this.state.custAjuste}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="msgEcf"
	            fullWidth
	            margin="normal"
	            name="msgEcf"
	            value={this.state.msgEcf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="itDesc1"
	            fullWidth
	            margin="normal"
	            name="itDesc1"
	            value={this.state.itDesc1}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="itDesc2"
	            fullWidth
	            margin="normal"
	            name="itDesc2"
	            value={this.state.itDesc2}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="itDesc3"
	            fullWidth
	            margin="normal"
	            name="itDesc3"
	            value={this.state.itDesc3}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="itDesc4"
	            fullWidth
	            margin="normal"
	            name="itDesc4"
	            value={this.state.itDesc4}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="itDescSenha1"
	            fullWidth
	            margin="normal"
	            name="itDescSenha1"
	            value={this.state.itDescSenha1}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="itDescSenha2"
	            fullWidth
	            margin="normal"
	            name="itDescSenha2"
	            value={this.state.itDescSenha2}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="itDescSenha3"
	            fullWidth
	            margin="normal"
	            name="itDescSenha3"
	            value={this.state.itDescSenha3}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="itDescSenha4"
	            fullWidth
	            margin="normal"
	            name="itDescSenha4"
	            value={this.state.itDescSenha4}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="diasCarencia"
	            fullWidth
	            margin="normal"
	            name="diasCarencia"
	            value={this.state.diasCarencia}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="jurosDia"
	            fullWidth
	            margin="normal"
	            name="jurosDia"
	            value={this.state.jurosDia}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="menorParcela"
	            fullWidth
	            margin="normal"
	            name="menorParcela"
	            value={this.state.menorParcela}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="senhaMenorParcela"
	            fullWidth
	            margin="normal"
	            name="senhaMenorParcela"
	            value={this.state.senhaMenorParcela}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="freteCif"
	            fullWidth
	            margin="normal"
	            name="freteCif"
	            value={this.state.freteCif}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="freteFob"
	            fullWidth
	            margin="normal"
	            name="freteFob"
	            value={this.state.freteFob}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="frete"
	            fullWidth
	            margin="normal"
	            name="frete"
	            value={this.state.frete}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="estacVlrDiaria"
	            fullWidth
	            margin="normal"
	            name="estacVlrDiaria"
	            value={this.state.estacVlrDiaria}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="estacTolHoras"
	            fullWidth
	            margin="normal"
	            name="estacTolHoras"
	            value={this.state.estacTolHoras}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="aquisicaoVeiculo"
	            fullWidth
	            margin="normal"
	            name="aquisicaoVeiculo"
	            value={this.state.aquisicaoVeiculo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="freteVlrCombustivel"
	            fullWidth
	            margin="normal"
	            name="freteVlrCombustivel"
	            value={this.state.freteVlrCombustivel}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="freteIndKmRodados"
	            fullWidth
	            margin="normal"
	            name="freteIndKmRodados"
	            value={this.state.freteIndKmRodados}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="freteIndGastosPneu"
	            fullWidth
	            margin="normal"
	            name="freteIndGastosPneu"
	            value={this.state.freteIndGastosPneu}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="freteIndManutencao"
	            fullWidth
	            margin="normal"
	            name="freteIndManutencao"
	            value={this.state.freteIndManutencao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="estacVlrDiariaBitrem"
	            fullWidth
	            margin="normal"
	            name="estacVlrDiariaBitrem"
	            value={this.state.estacVlrDiariaBitrem}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="estacVlrDiariaTruck"
	            fullWidth
	            margin="normal"
	            name="estacVlrDiariaTruck"
	            value={this.state.estacVlrDiariaTruck}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="estacVlrDiariaCarreta"
	            fullWidth
	            margin="normal"
	            name="estacVlrDiariaCarreta"
	            value={this.state.estacVlrDiariaCarreta}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="estacQtdeHorasGratis"
	            fullWidth
	            margin="normal"
	            name="estacQtdeHorasGratis"
	            value={this.state.estacQtdeHorasGratis}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="mensalidades"
	            fullWidth
	            margin="normal"
	            name="mensalidades"
	            value={this.state.mensalidades}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="urlGeradorBoletos"
	            fullWidth
	            margin="normal"
	            name="urlGeradorBoletos"
	            value={this.state.urlGeradorBoletos}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="boletoDemonstrativo1"
	            fullWidth
	            margin="normal"
	            name="boletoDemonstrativo1"
	            value={this.state.boletoDemonstrativo1}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="boletoDemonstrativo2"
	            fullWidth
	            margin="normal"
	            name="boletoDemonstrativo2"
	            value={this.state.boletoDemonstrativo2}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="boletoDemonstrativo3"
	            fullWidth
	            margin="normal"
	            name="boletoDemonstrativo3"
	            value={this.state.boletoDemonstrativo3}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="custoKm"
	            fullWidth
	            margin="normal"
	            name="custoKm"
	            value={this.state.custoKm}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="estacVlrDiariaTreminha"
	            fullWidth
	            margin="normal"
	            name="estacVlrDiariaTreminha"
	            value={this.state.estacVlrDiariaTreminha}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="obsPedcompra"
	            fullWidth
	            margin="normal"
	            name="obsPedcompra"
	            value={this.state.obsPedcompra}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="textoTermoEntrEpi"
	            fullWidth
	            margin="normal"
	            name="textoTermoEntrEpi"
	            value={this.state.textoTermoEntrEpi}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="txtFinTermoEntrEpi"
	            fullWidth
	            margin="normal"
	            name="txtFinTermoEntrEpi"
	            value={this.state.txtFinTermoEntrEpi}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balIntervalo"
	            fullWidth
	            margin="normal"
	            name="balIntervalo"
	            value={this.state.balIntervalo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balModelo"
	            fullWidth
	            margin="normal"
	            name="balModelo"
	            value={this.state.balModelo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balMonitorar"
	            fullWidth
	            margin="normal"
	            name="balMonitorar"
	            value={this.state.balMonitorar}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balPorta"
	            fullWidth
	            margin="normal"
	            name="balPorta"
	            value={this.state.balPorta}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balModeloBalanca"
	            fullWidth
	            margin="normal"
	            name="balModeloBalanca"
	            value={this.state.balModeloBalanca}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balBaud"
	            fullWidth
	            margin="normal"
	            name="balBaud"
	            value={this.state.balBaud}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balData"
	            fullWidth
	            margin="normal"
	            name="balData"
	            value={this.state.balData}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balHandshake"
	            fullWidth
	            margin="normal"
	            name="balHandshake"
	            value={this.state.balHandshake}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balHardflow"
	            fullWidth
	            margin="normal"
	            name="balHardflow"
	            value={this.state.balHardflow}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balParity"
	            fullWidth
	            margin="normal"
	            name="balParity"
	            value={this.state.balParity}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balProcessMessages"
	            fullWidth
	            margin="normal"
	            name="balProcessMessages"
	            value={this.state.balProcessMessages}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balSendbytescount"
	            fullWidth
	            margin="normal"
	            name="balSendbytescount"
	            value={this.state.balSendbytescount}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balSendbytesinterval"
	            fullWidth
	            margin="normal"
	            name="balSendbytesinterval"
	            value={this.state.balSendbytesinterval}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balStop"
	            fullWidth
	            margin="normal"
	            name="balStop"
	            value={this.state.balStop}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="balSoftflow"
	            fullWidth
	            margin="normal"
	            name="balSoftflow"
	            value={this.state.balSoftflow}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="bolTipoCobranca"
	            fullWidth
	            margin="normal"
	            name="bolTipoCobranca"
	            value={this.state.bolTipoCobranca}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="bolCedAgencia"
	            fullWidth
	            margin="normal"
	            name="bolCedAgencia"
	            value={this.state.bolCedAgencia}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="bolCedAgenciaDigito"
	            fullWidth
	            margin="normal"
	            name="bolCedAgenciaDigito"
	            value={this.state.bolCedAgenciaDigito}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="bolCedCodigo"
	            fullWidth
	            margin="normal"
	            name="bolCedCodigo"
	            value={this.state.bolCedCodigo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="bolCedCodTransmissao"
	            fullWidth
	            margin="normal"
	            name="bolCedCodTransmissao"
	            value={this.state.bolCedCodTransmissao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="bolCedConta"
	            fullWidth
	            margin="normal"
	            name="bolCedConta"
	            value={this.state.bolCedConta}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="bolCedContaDig"
	            fullWidth
	            margin="normal"
	            name="bolCedContaDig"
	            value={this.state.bolCedContaDig}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="bolCedRespTransmissao"
	            fullWidth
	            margin="normal"
	            name="bolCedRespTransmissao"
	            value={this.state.bolCedRespTransmissao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="boletos"
	            fullWidth
	            margin="normal"
	            name="boletos"
	            value={this.state.boletos}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="bolMsgPadraoEmail"
	            fullWidth
	            margin="normal"
	            name="bolMsgPadraoEmail"
	            value={this.state.bolMsgPadraoEmail}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="gadoVlrArrobaMerc"
	            fullWidth
	            margin="normal"
	            name="gadoVlrArrobaMerc"
	            value={this.state.gadoVlrArrobaMerc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="obsCarne"
	            fullWidth
	            margin="normal"
	            name="obsCarne"
	            value={this.state.obsCarne}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ecfVeloc115200"
	            fullWidth
	            margin="normal"
	            name="ecfVeloc115200"
	            value={this.state.ecfVeloc115200}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="obsOrcamento"
	            fullWidth
	            margin="normal"
	            name="obsOrcamento"
	            value={this.state.obsOrcamento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="endAssLadoEsquerdo"
	            fullWidth
	            margin="normal"
	            name="endAssLadoEsquerdo"
	            value={this.state.endAssLadoEsquerdo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="bolCedConvenio"
	            fullWidth
	            margin="normal"
	            name="bolCedConvenio"
	            value={this.state.bolCedConvenio}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="percMulta"
	            fullWidth
	            margin="normal"
	            name="percMulta"
	            value={this.state.percMulta}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.saveParVenda}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddParVendaComponent);
