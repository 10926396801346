import React from "react";
// importar todos os componentes do Material UI com prefixo "MUI"
import MUIContainer from "@material-ui/core/Container";
import NavBar from "./Navbar";
import Dialog from "./Dialog";

const styles = {
  container: {
    marginTop: "20px"
  }
};

export class Container extends React.Component {
  // passar prop 'navbar' como 'off' caso deseje desabilitar a nav
  render() {
    if (this.props.navbar === "off") {
      return (
        <div>
          <MUIContainer style={styles.container}>
            {this.props.children}
            <Dialog disableBackdropClick={true} />
          </MUIContainer>
        </div>
      );
    } else {
      return (
        <div>
          <NavBar />
          <MUIContainer style={styles.container}>
            {this.props.children}
            <Dialog disableBackdropClick={true} />
          </MUIContainer>
        </div>
      );
    }
  }
}
