import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListProdutoGrupoComponent() {
  const colunas = [
    { title: "Código", field: "codprodutogrupo" },
    { title: "Descrição", field: "descricao" },
    { title: "IPI Padrão", field: "ipiPadrao" },
    { title: "ICMS Padrão", field: "icmsPadrao" },
    { title: "Grau 1", field: "grau1" },
    { title: "Grau 2", field: "grau2" },
    { title: "Grau 3", field: "grau3" },
    { title: "Grau 4", field: "grau4" },
    { title: "Grau 5", field: "grau5" },
    { title: "Grau 6", field: "grau6" }
  ];

  return (
    <div>
      <ListComponent
        route="produtogrupos"
        listKeys={colunas}
        title="Grupos de Produto"
      />
    </div>
  );
}
