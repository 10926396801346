import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import rootSaga from "./redux/sagas/index";

import reducers from "./redux/reducers";
import {
  Vendas,
  VendasPorMoeda,
  MaisVendidos,
  VendasPorHorario,
  VendasDiaSemana,
  MiniHistVendas,
  MiniTicket,
  MiniDescontos
} from "./component/charts/chartConfig";

const logado = localStorage.getItem("userInfo") !== null;

var initialstate = {
  auth: {
    isLogged: logado,
    token: logado ? localStorage.getItem("userInfo") : undefined
  },
  dashboard: {
    nomeCliente: "Firebox",
    ticketMedio: "",
    fatPeloCusto: "",
    fatTotal: "",
    resOpera: "",
    qtdPedidos: "",
    totalDsc: "",
    vendas: Vendas,
    vendasPorMoeda: VendasPorMoeda,
    maisVendidos: MaisVendidos,
    vendasPorHorario: VendasPorHorario,
    vendasDiaSemana: VendasDiaSemana,
    miniHistVendas: MiniHistVendas,
    miniTicket: MiniTicket,
    miniDescontos: MiniDescontos
  },
  spinner: false
};

const sagaMonitor =
  process.env.NODE_ENV === "development"
    ? console.tron.createSagaMonitor()
    : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const enhancer =
  process.env.NODE_ENV === "development"
    ? compose(
        console.tron.createEnhancer(),
        applyMiddleware(sagaMiddleware)
      )
    : applyMiddleware(sagaMiddleware);

const store = createStore(reducers, initialstate, enhancer);

console.log(store.getState());

sagaMiddleware.run(rootSaga);

export default store;
