export const localConfig = {
  baseURL: "http://127.0.0.1:3333",
  headers: {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("userInfo")
    }
  }
};

export const ssmservidor = {
  baseURL: "http://dashboard.fireboxsoftware.com.br:8080",
  headers: {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("userInfo")
    }
  }
};

export const Config = ssmservidor; // alterar para o ambiente desejado
