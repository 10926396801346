import React, { Component } from "react";
import ParGeralService from "../../service/ParGeralService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddParGeralComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empresa: "",
      endereco: "",
      bairro: "",
      cidade: "",
      uf: "",
      cep: "",
      telefone: "",
      cpfcgc: "",
      ie: "",
      figura: "",
      atualizacao: "",
      logoRel: "",
      im: "",
      emailNome: "",
      email: "",
      emailLogin: "",
      emailSenha: "",
      emailSmtp: "",
      emailPort: "",
      emailSsl: "",
      site: "",
      razaosocial: "",
      numero: "",
      complemento: "",
      codcidade: "",
      nfe: "",
      nfeCertCaminho: "",
      nfeCertNr: "",
      nfeCertSenha: "",
      nfeDanfe: "",
      nfeFe: "",
      nfeLog: "",
      nfeLogo: "",
      nfeWsUf: "",
      nfeWsAmbiente: "",
      nfeProxyHost: "",
      nfeProxyPorta: "",
      nfeProxyUser: "",
      nfeProxyPassword: "",
      versao: "",
      opcaoTributaria: "",
      segmento: "",
      sistemaExclusivo: "",
      enviarEmailErro: "",
      consultaDll: "",
      enderecoPastaArquivos: "",
      pafversao: "",
      plcTxSupPer: "",
      plcTxSupAlt: "",
      plcTxAdmPer: "",
      plcTxAdmAlt: "",
      plcTxComPer: "",
      plcTxComAlt: "",
      plcOtPrvPer: "",
      plcOtPrvAlt: "",
      plcOtConPer: "",
      plcOtConAlt: "",
      plcOtComPer: "",
      plcOtComAlt: "",
      plcOtOnuPer: "",
      plcOtOnuAlt: "",
      plcOtMngPer: "",
      plcOtMngAlt: "",
      plcOtMlcPer: "",
      plcOtMlcAlt: "",
      plcImIrlPer: "",
      plcImIrlAlt: "",
      plcImCslPer: "",
      plcImCslAlt: "",
      plcImIrcPer: "",
      plcImIrcAlt: "",
      plcImCpmPer: "",
      plcImCpmAlt: "",
      plcImIssPer: "",
      plcImIssAlt: "",
      plcImPisPer: "",
      plcImPisAlt: "",
      plcImCofPer: "",
      plcImCofAlt: "",
      versaoQtde: "",
      message: null
    };
    this.saveParGeral = this.saveParGeral.bind(this);
  }

  saveParGeral = e => {
    e.preventDefault();
    let parGerals = {
      empresa: this.state.empresa,
      endereco: this.state.endereco,
      bairro: this.state.bairro,
      cidade: this.state.cidade,
      uf: this.state.uf,
      cep: this.state.cep,
      telefone: this.state.telefone,
      cpfcgc: this.state.cpfcgc,
      ie: this.state.ie,
      figura: this.state.figura,
      atualizacao: this.state.atualizacao,
      logoRel: this.state.logoRel,
      im: this.state.im,
      emailNome: this.state.emailNome,
      email: this.state.email,
      emailLogin: this.state.emailLogin,
      emailSenha: this.state.emailSenha,
      emailSmtp: this.state.emailSmtp,
      emailPort: this.state.emailPort,
      emailSsl: this.state.emailSsl,
      site: this.state.site,
      razaosocial: this.state.razaosocial,
      numero: this.state.numero,
      complemento: this.state.complemento,
      codcidade: this.state.codcidade,
      nfe: this.state.nfe,
      nfeCertCaminho: this.state.nfeCertCaminho,
      nfeCertNr: this.state.nfeCertNr,
      nfeCertSenha: this.state.nfeCertSenha,
      nfeDanfe: this.state.nfeDanfe,
      nfeFe: this.state.nfeFe,
      nfeLog: this.state.nfeLog,
      nfeLogo: this.state.nfeLogo,
      nfeWsUf: this.state.nfeWsUf,
      nfeWsAmbiente: this.state.nfeWsAmbiente,
      nfeProxyHost: this.state.nfeProxyHost,
      nfeProxyPorta: this.state.nfeProxyPorta,
      nfeProxyUser: this.state.nfeProxyUser,
      nfeProxyPassword: this.state.nfeProxyPassword,
      versao: this.state.versao,
      opcaoTributaria: this.state.opcaoTributaria,
      segmento: this.state.segmento,
      sistemaExclusivo: this.state.sistemaExclusivo,
      enviarEmailErro: this.state.enviarEmailErro,
      consultaDll: this.state.consultaDll,
      enderecoPastaArquivos: this.state.enderecoPastaArquivos,
      pafversao: this.state.pafversao,
      plcTxSupPer: this.state.plcTxSupPer,
      plcTxSupAlt: this.state.plcTxSupAlt,
      plcTxAdmPer: this.state.plcTxAdmPer,
      plcTxAdmAlt: this.state.plcTxAdmAlt,
      plcTxComPer: this.state.plcTxComPer,
      plcTxComAlt: this.state.plcTxComAlt,
      plcOtPrvPer: this.state.plcOtPrvPer,
      plcOtPrvAlt: this.state.plcOtPrvAlt,
      plcOtConPer: this.state.plcOtConPer,
      plcOtConAlt: this.state.plcOtConAlt,
      plcOtComPer: this.state.plcOtComPer,
      plcOtComAlt: this.state.plcOtComAlt,
      plcOtOnuPer: this.state.plcOtOnuPer,
      plcOtOnuAlt: this.state.plcOtOnuAlt,
      plcOtMngPer: this.state.plcOtMngPer,
      plcOtMngAlt: this.state.plcOtMngAlt,
      plcOtMlcPer: this.state.plcOtMlcPer,
      plcOtMlcAlt: this.state.plcOtMlcAlt,
      plcImIrlPer: this.state.plcImIrlPer,
      plcImIrlAlt: this.state.plcImIrlAlt,
      plcImCslPer: this.state.plcImCslPer,
      plcImCslAlt: this.state.plcImCslAlt,
      plcImIrcPer: this.state.plcImIrcPer,
      plcImIrcAlt: this.state.plcImIrcAlt,
      plcImCpmPer: this.state.plcImCpmPer,
      plcImCpmAlt: this.state.plcImCpmAlt,
      plcImIssPer: this.state.plcImIssPer,
      plcImIssAlt: this.state.plcImIssAlt,
      plcImPisPer: this.state.plcImPisPer,
      plcImPisAlt: this.state.plcImPisAlt,
      plcImCofPer: this.state.plcImCofPer,
      plcImCofAlt: this.state.plcImCofAlt,
      versaoQtde: this.state.versaoQtde,
    };
    ParGeralService.addParGeral(parGerals).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/pargerals");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar ParGeral
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="empresa"
	            fullWidth
	            margin="normal"
	            name="empresa"
	            value={this.state.empresa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="endereco"
	            fullWidth
	            margin="normal"
	            name="endereco"
	            value={this.state.endereco}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="bairro"
	            fullWidth
	            margin="normal"
	            name="bairro"
	            value={this.state.bairro}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cidade"
	            fullWidth
	            margin="normal"
	            name="cidade"
	            value={this.state.cidade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="uf"
	            fullWidth
	            margin="normal"
	            name="uf"
	            value={this.state.uf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cep"
	            fullWidth
	            margin="normal"
	            name="cep"
	            value={this.state.cep}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="telefone"
	            fullWidth
	            margin="normal"
	            name="telefone"
	            value={this.state.telefone}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cpfcgc"
	            fullWidth
	            margin="normal"
	            name="cpfcgc"
	            value={this.state.cpfcgc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ie"
	            fullWidth
	            margin="normal"
	            name="ie"
	            value={this.state.ie}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="figura"
	            fullWidth
	            margin="normal"
	            name="figura"
	            value={this.state.figura}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="atualizacao"
	            fullWidth
	            margin="normal"
	            name="atualizacao"
	            value={this.state.atualizacao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="logoRel"
	            fullWidth
	            margin="normal"
	            name="logoRel"
	            value={this.state.logoRel}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="im"
	            fullWidth
	            margin="normal"
	            name="im"
	            value={this.state.im}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="emailNome"
	            fullWidth
	            margin="normal"
	            name="emailNome"
	            value={this.state.emailNome}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="email"
	            fullWidth
	            margin="normal"
	            name="email"
	            value={this.state.email}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="emailLogin"
	            fullWidth
	            margin="normal"
	            name="emailLogin"
	            value={this.state.emailLogin}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="emailSenha"
	            fullWidth
	            margin="normal"
	            name="emailSenha"
	            value={this.state.emailSenha}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="emailSmtp"
	            fullWidth
	            margin="normal"
	            name="emailSmtp"
	            value={this.state.emailSmtp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="emailPort"
	            fullWidth
	            margin="normal"
	            name="emailPort"
	            value={this.state.emailPort}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="emailSsl"
	            fullWidth
	            margin="normal"
	            name="emailSsl"
	            value={this.state.emailSsl}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="site"
	            fullWidth
	            margin="normal"
	            name="site"
	            value={this.state.site}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="razaosocial"
	            fullWidth
	            margin="normal"
	            name="razaosocial"
	            value={this.state.razaosocial}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="numero"
	            fullWidth
	            margin="normal"
	            name="numero"
	            value={this.state.numero}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="complemento"
	            fullWidth
	            margin="normal"
	            name="complemento"
	            value={this.state.complemento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codcidade"
	            fullWidth
	            margin="normal"
	            name="codcidade"
	            value={this.state.codcidade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfe"
	            fullWidth
	            margin="normal"
	            name="nfe"
	            value={this.state.nfe}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfeCertCaminho"
	            fullWidth
	            margin="normal"
	            name="nfeCertCaminho"
	            value={this.state.nfeCertCaminho}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfeCertNr"
	            fullWidth
	            margin="normal"
	            name="nfeCertNr"
	            value={this.state.nfeCertNr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfeCertSenha"
	            fullWidth
	            margin="normal"
	            name="nfeCertSenha"
	            value={this.state.nfeCertSenha}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfeDanfe"
	            fullWidth
	            margin="normal"
	            name="nfeDanfe"
	            value={this.state.nfeDanfe}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfeFe"
	            fullWidth
	            margin="normal"
	            name="nfeFe"
	            value={this.state.nfeFe}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfeLog"
	            fullWidth
	            margin="normal"
	            name="nfeLog"
	            value={this.state.nfeLog}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfeLogo"
	            fullWidth
	            margin="normal"
	            name="nfeLogo"
	            value={this.state.nfeLogo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfeWsUf"
	            fullWidth
	            margin="normal"
	            name="nfeWsUf"
	            value={this.state.nfeWsUf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfeWsAmbiente"
	            fullWidth
	            margin="normal"
	            name="nfeWsAmbiente"
	            value={this.state.nfeWsAmbiente}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfeProxyHost"
	            fullWidth
	            margin="normal"
	            name="nfeProxyHost"
	            value={this.state.nfeProxyHost}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfeProxyPorta"
	            fullWidth
	            margin="normal"
	            name="nfeProxyPorta"
	            value={this.state.nfeProxyPorta}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfeProxyUser"
	            fullWidth
	            margin="normal"
	            name="nfeProxyUser"
	            value={this.state.nfeProxyUser}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nfeProxyPassword"
	            fullWidth
	            margin="normal"
	            name="nfeProxyPassword"
	            value={this.state.nfeProxyPassword}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="versao"
	            fullWidth
	            margin="normal"
	            name="versao"
	            value={this.state.versao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="opcaoTributaria"
	            fullWidth
	            margin="normal"
	            name="opcaoTributaria"
	            value={this.state.opcaoTributaria}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="segmento"
	            fullWidth
	            margin="normal"
	            name="segmento"
	            value={this.state.segmento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="sistemaExclusivo"
	            fullWidth
	            margin="normal"
	            name="sistemaExclusivo"
	            value={this.state.sistemaExclusivo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="enviarEmailErro"
	            fullWidth
	            margin="normal"
	            name="enviarEmailErro"
	            value={this.state.enviarEmailErro}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="consultaDll"
	            fullWidth
	            margin="normal"
	            name="consultaDll"
	            value={this.state.consultaDll}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="enderecoPastaArquivos"
	            fullWidth
	            margin="normal"
	            name="enderecoPastaArquivos"
	            value={this.state.enderecoPastaArquivos}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="pafversao"
	            fullWidth
	            margin="normal"
	            name="pafversao"
	            value={this.state.pafversao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcTxSupPer"
	            fullWidth
	            margin="normal"
	            name="plcTxSupPer"
	            value={this.state.plcTxSupPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcTxSupAlt"
	            fullWidth
	            margin="normal"
	            name="plcTxSupAlt"
	            value={this.state.plcTxSupAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcTxAdmPer"
	            fullWidth
	            margin="normal"
	            name="plcTxAdmPer"
	            value={this.state.plcTxAdmPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcTxAdmAlt"
	            fullWidth
	            margin="normal"
	            name="plcTxAdmAlt"
	            value={this.state.plcTxAdmAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcTxComPer"
	            fullWidth
	            margin="normal"
	            name="plcTxComPer"
	            value={this.state.plcTxComPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcTxComAlt"
	            fullWidth
	            margin="normal"
	            name="plcTxComAlt"
	            value={this.state.plcTxComAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcOtPrvPer"
	            fullWidth
	            margin="normal"
	            name="plcOtPrvPer"
	            value={this.state.plcOtPrvPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcOtPrvAlt"
	            fullWidth
	            margin="normal"
	            name="plcOtPrvAlt"
	            value={this.state.plcOtPrvAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcOtConPer"
	            fullWidth
	            margin="normal"
	            name="plcOtConPer"
	            value={this.state.plcOtConPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcOtConAlt"
	            fullWidth
	            margin="normal"
	            name="plcOtConAlt"
	            value={this.state.plcOtConAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcOtComPer"
	            fullWidth
	            margin="normal"
	            name="plcOtComPer"
	            value={this.state.plcOtComPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcOtComAlt"
	            fullWidth
	            margin="normal"
	            name="plcOtComAlt"
	            value={this.state.plcOtComAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcOtOnuPer"
	            fullWidth
	            margin="normal"
	            name="plcOtOnuPer"
	            value={this.state.plcOtOnuPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcOtOnuAlt"
	            fullWidth
	            margin="normal"
	            name="plcOtOnuAlt"
	            value={this.state.plcOtOnuAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcOtMngPer"
	            fullWidth
	            margin="normal"
	            name="plcOtMngPer"
	            value={this.state.plcOtMngPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcOtMngAlt"
	            fullWidth
	            margin="normal"
	            name="plcOtMngAlt"
	            value={this.state.plcOtMngAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcOtMlcPer"
	            fullWidth
	            margin="normal"
	            name="plcOtMlcPer"
	            value={this.state.plcOtMlcPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcOtMlcAlt"
	            fullWidth
	            margin="normal"
	            name="plcOtMlcAlt"
	            value={this.state.plcOtMlcAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcImIrlPer"
	            fullWidth
	            margin="normal"
	            name="plcImIrlPer"
	            value={this.state.plcImIrlPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcImIrlAlt"
	            fullWidth
	            margin="normal"
	            name="plcImIrlAlt"
	            value={this.state.plcImIrlAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcImCslPer"
	            fullWidth
	            margin="normal"
	            name="plcImCslPer"
	            value={this.state.plcImCslPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcImCslAlt"
	            fullWidth
	            margin="normal"
	            name="plcImCslAlt"
	            value={this.state.plcImCslAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcImIrcPer"
	            fullWidth
	            margin="normal"
	            name="plcImIrcPer"
	            value={this.state.plcImIrcPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcImIrcAlt"
	            fullWidth
	            margin="normal"
	            name="plcImIrcAlt"
	            value={this.state.plcImIrcAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcImCpmPer"
	            fullWidth
	            margin="normal"
	            name="plcImCpmPer"
	            value={this.state.plcImCpmPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcImCpmAlt"
	            fullWidth
	            margin="normal"
	            name="plcImCpmAlt"
	            value={this.state.plcImCpmAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcImIssPer"
	            fullWidth
	            margin="normal"
	            name="plcImIssPer"
	            value={this.state.plcImIssPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcImIssAlt"
	            fullWidth
	            margin="normal"
	            name="plcImIssAlt"
	            value={this.state.plcImIssAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcImPisPer"
	            fullWidth
	            margin="normal"
	            name="plcImPisPer"
	            value={this.state.plcImPisPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcImPisAlt"
	            fullWidth
	            margin="normal"
	            name="plcImPisAlt"
	            value={this.state.plcImPisAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="plcImCofPer"
	            fullWidth
	            margin="normal"
	            name="plcImCofPer"
	            value={this.state.plcImCofPer}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="plcImCofAlt"
	            fullWidth
	            margin="normal"
	            name="plcImCofAlt"
	            value={this.state.plcImCofAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="versaoQtde"
	            fullWidth
	            margin="normal"
	            name="versaoQtde"
	            value={this.state.versaoQtde}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.saveParGeral}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddParGeralComponent);
