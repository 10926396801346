import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListConfiguracaoGeralComponent() {
  const colunas = [
    { title: "chave", field: "chave" },
    { title: "descricao", field: "descricao" },
    { title: "vlr", field: "vlr" },
    { title: "tipo", field: "tipo" },
    { title: "funcionalidade", field: "funcionalidade" },
    { title: "md5", field: "md5" }
  ];

  return (
    <div>
      <ListComponent
        route="configuracaogerals"
        listKeys={colunas}
        title="Configurações Gerais"
      />
    </div>
  );
}
