import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class FormapagamentoService {
  fetchFormapagamentos() {
    return axios.get(config.baseURL + '/formapagamentos/', Headers);
  }

  fetchFormapagamentoById(codformapagamento) {
    return axios.get(config.baseURL + '/formapagamentos/' + codformapagamento, Headers);
  }

  deleteFormapagamento(codformapagamento) {
    return axios.delete(config.baseURL + '/formapagamentos/' + codformapagamento, Headers);
  }

  addFormapagamento(formapagamento) {
    return axios.post(config.baseURL + '/formapagamentos/', formapagamento, Headers);
  }

  editFormapagamento(formapagamento) {
    return axios.put(config.baseURL + '/formapagamentos/' + formapagamento.codformapagamento, formapagamento, Headers);
  }
}

export default new FormapagamentoService();