export function addUserRequest(payload) {
  return {
    type: "@user/ADD_REQUEST",
    name: payload.name,
    email: payload.login,
    cellphone: payload.cellphone,
    password: payload.password
  };
}

export function addUserSuccess(status, message) {
  return {
    type: "@user/ADD_SUCCESS",
    status,
    message
  };
}

export function removeUserRequest(id) {
  return {
    type: "@user/REMOVE_REQUEST",
    onDeleting: true,
    id
  };
}

export function removeUserSuccess(status, message) {
  return {
    type: "@user/REMOVE_SUCCESS",
    status,
    message
  };
}

export function setUserToEdit(payload) {
  return {
    type: "@user/EDIT_SET",
    payload
  };
}

export function editUserRequest(userData) {
  return {
    type: "@user/EDIT_REQUEST",
    onEditing: true,
    userData
  };
}

export function editUserSuccess(status, message) {
  return {
    type: "@user/EDIT_SUCCESS",
    status,
    message
  };
}
