import axios from "axios";
import { Config } from "../constants/config";
const config = Config;

class PasswordService {

  requestToken(email) {
    return axios.post(config.baseURL + "/passwords",{ 
          email,
          redirect_url: "http://www.meucaixarapido.com.br/alterarsenha"
        }
    );
  }
  passUpdate(token, newpass) {
    return axios.post(config.baseURL + "/passwords", { 
        newpass,
        token
      });
  }
}

export default new PasswordService();
