import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class CfopService {
  fetchCfops() {
    return axios.get(config.baseURL + '/cfops/', Headers);
  }

  fetchCfopById(codcfop) {
    return axios.get(config.baseURL + '/cfops/' + codcfop, Headers);
  }

  deleteCfop(codcfop) {
    return axios.delete(config.baseURL + '/cfops/' + codcfop, Headers);
  }

  addCfop(cfop) {
    return axios.post(config.baseURL + '/cfops/', cfop, Headers);
  }

  editCfop(cfop) {
    return axios.put(config.baseURL + '/cfops/' + cfop.codcfop, cfop, Headers);
  }
}

export default new CfopService();