import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class MoedaService {
  fetchMoedas() {
    return axios.get(config.baseURL + '/moedas/', Headers);
  }

  fetchMoedaById(codmoeda) {
    return axios.get(config.baseURL + '/moedas/' + codmoeda, Headers);
  }

  deleteMoeda(codmoeda) {
    return axios.delete(config.baseURL + '/moedas/' + codmoeda, Headers);
  }

  addMoeda(moeda) {
    return axios.post(config.baseURL + '/moedas/', moeda, Headers);
  }

  editMoeda(moeda) {
    return axios.put(config.baseURL + '/moedas/' + moeda.codmoeda, moeda, Headers);
  }
}

export default new MoedaService();