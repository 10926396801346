import React from "react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import PrintIcon from "@material-ui/icons/Print";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AddBox from "@material-ui/icons/AddBox";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import QueueIcon from "@material-ui/icons/Queue";
import AssignmentIcon from "@material-ui/icons/Assignment";
import HelpIcon from "@material-ui/icons/Help";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GroupIcon from "@material-ui/icons/Group";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as authActions from "../../redux/actions/auth";

const style = {
  flexGrow: 1,
  fontFamily: "Poppins"
};

const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  root: {
    width: "100%",
    maxWidth: 360
  },
  nested: {
    paddingLeft: "30px"
  },
  barColor: {
    backgroundColor: "#36A9E0"
  }
});

const NavBar = props => {
  const classes = useStyles();
  // drawer
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const [openConfig, setOpenConfig] = React.useState(false);
  const handleClickConfig = () => {
    setOpenConfig(!openConfig);
  };

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const imprimir = () => {
    window.print();
  };

  const handleClickSair = () => {
    props.logoutUser();
  };

  const ListItemLink = props => {
    return <ListItem button component={Link} to="/moedas" {...props} />;
  };

  const sideList = side => {
    return (
      <div
        className={classes.list}
        role="presentation"
        onKeyDown={toggleDrawer(side, false)}
      >
        <List component="nav">
          <ListItemLink
            key="Dashboard"
            to="/dashboards"
            onClick={toggleDrawer(side, false)}
          >
            <ListItemIcon>
              <InsertChartIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemLink>
        </List>
        <Divider />
        <List>
          <ListItem button key="Configuracoes" onClick={handleClickConfig}>
            <ListItemIcon>
              <QueueIcon />
            </ListItemIcon>
            <ListItemText primary="Configurações" />
            {openConfig ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openConfig} timeout="auto" unmountOnExit>
            <ListItemLink
              to="/alterarsenha"
              key="Alterarsenha"
              onClick={toggleDrawer(side, false)}
              className={classes.nested}
            >
              <ListItemIcon>
                <VpnKeyIcon />
              </ListItemIcon>
              <ListItemText primary="Alterar Senha" />
            </ListItemLink>
            <ListItemLink
              to="/users"
              key="users"
              onClick={toggleDrawer(side, false)}
              className={classes.nested}
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="Usuários" />
            </ListItemLink>
          </Collapse>
          <ListItem button key="Ajuda">
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <a
              href="https://softwaresobmedida.com/suporte/"
              target="_blank"
              style={{ textDecoration: "none", color: "#333" }}
            >
              <ListItemText primary="Ajuda" />
            </a>
          </ListItem>
          <ListItem button key="Sair">
            <ListItemIcon>
              <HighlightOffIcon />
            </ListItemIcon>
            <ListItemText primary="Sair" onClick={() => handleClickSair()} />
          </ListItem>
        </List>
      </div>
    );
  };
  // fim drawer
  return (
    <div>
      <AppBar position="static" className={classes.barColor}>
        <Toolbar>
          <Typography variant="h6" style={style}>
            {props.clientName.toUpperCase()}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Menu"
            onClick={() => imprimir()}
          >
            <PrintIcon />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Menu"
            onClick={() => handleClickSair()}
          >
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
  auth: state.auth,
  clientName: state.dashboard.nomeCliente
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(authActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
