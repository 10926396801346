import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditComponent from "../ui/EditComponent";

export default function EditResControle() {
  const history = useHistory();
  const dadosRedux = useSelector(state => state.cadastros.cadastro);
  const dadosStorage = JSON.parse(localStorage.getItem("rescontroles"));

  const colunas = [
    { title: "codcontrole", field: "codcontrole" },
    { title: "status", field: "status" },
    { title: "cobrarServico", field: "cobrarServico" }
  ];

  var retorno;

  if (!dadosRedux && !dadosStorage) {
    retorno = <></>;
    history.push("rescontroles");
  } else {
    retorno = (
      <div>
        <EditComponent
          title="Controle"
          data={dadosRedux || dadosStorage}
          colunas={colunas}
        />
      </div>
    );
  }

  return retorno;
}
