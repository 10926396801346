import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListPedVendaComponent() {
  const colunas = [
    { title: "Código", field: "codpedvenda" },
    { title: "Pessoa", field: "codpessoa" },
    { title: "Tipo Preço", field: "tipopreco" },
    { title: "Data Entrada", field: "dataEntrada" },
    { title: "Status", field: "status" },
    { title: "Desconto Valor", field: "descVlr" },
    { title: "Desconto Percentual", field: "descPerc" },
    { title: "Acréscimo Percentual", field: "acrePerc" },
    { title: "Acréscimo Valor", field: "acreVlr" },
    { title: "Total de Produtos", field: "totalProdutos" }
  ];

  return (
    <div>
      <ListComponent
        route="pedvendas"
        listKeys={colunas}
        title="Pedidos de Venda"
      />
    </div>
  );
}
