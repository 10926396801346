import React, { Component, Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

export class NumberBoxes extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const styles = {
      paper: {
        padding: "10px",
        textAlign: "center",
        color: "#333",
        height: "80%"
      }
    };

    var BRL = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    });

    var GRP = new Intl.NumberFormat("pt-BR", {
      type: "group",
      value: "."
    });

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Paper style={styles.paper}>
            <h5>Total faturado</h5>
            <h3>{BRL.format(this.props.faturamento) || "0,00"}</h3>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Paper style={styles.paper}>
            <h5>Total do Custo</h5>
            <h3>{BRL.format(this.props.fatPorCusto) || "0,00"}</h3>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Paper style={styles.paper}>
            <h5>Resultado Operacional</h5>
            <h3>{BRL.format(this.props.resOpera) || "0,00"}</h3>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Paper style={styles.paper}>
            <h5>Qtde de Pedidos</h5>
            <h3>{GRP.format(this.props.qtdPedidos) || "0"}</h3>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Paper style={styles.paper}>
            <h5>Ticket Médio</h5>
            <h3>{BRL.format(this.props.ticketMedio) || "0,00"}</h3>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Paper style={styles.paper}>
            <h5>Total de Descontos</h5>
            <h3>{BRL.format(this.props.totalDescontos) || "0,00"}</h3>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
