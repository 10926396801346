import React, { Component, Fragment } from "react";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import ThreeSixtyIcon from "@material-ui/icons/ThreeSixty";
import { bindActionCreators } from "redux";
import { updateData } from "../../redux/actions/dashboard";
import { isLoading, notLoading } from "../../redux/actions/spinner";
import { connect } from "react-redux";
import moment from "moment";
import { isMobile } from "react-device-detect";

import ChartService from "../../service/ChartServices";

class RgPeriodo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      typeDate: "periodo",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      monthDate: moment().format("MM"),
      yearDate: moment().format("YYYY"),
      chartData: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickAtualizar = this.handleClickAtualizar.bind(this);
    this.handleChangeYearDate = this.handleChangeYearDate.bind(this);
    this.handleChangeMonthDate = this.handleChangeMonthDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleClickAtualiza = this.handleClickAtualiza.bind(this);
  }

  handleChange = event => {
    this.setState({
      typeDate: event.target.value
    });
  };

  handleChangeType = event => {
    this.setState({
      typeDate: event.target.value,
      startDate: this.state.yearDate + "-" + this.state.monthDate,
      monthDate: this.state.monthDate
    });
  };

  handleChangePeriod = event => {
    this.setState({
      typeDate: event.target.value,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),    
    });
  };


  handleChangeStartDate = event => {
    this.setState({
      startDate: event.target.value
    });
  };
  handleChangeEndDate = event => {
    this.setState({
      endDate: event.target.value
    });
  };
  handleChangeMonthDate = event => {
    this.setState({
      startDate: this.state.yearDate + "-" + event.target.value,
      monthDate: event.target.value
    });
  };
  handleChangeYearOnlyDate = event => {
    this.setState({
      startDate: event.target.value,
      yearDate: event.target.value
    });
  };
  handleChangeYearDate = event => {
    this.setState({
      startDate: event.target.value + "-" + this.state.monthDate,
      yearDate: event.target.value
    });
  };
  handleClickAtualizar = () => {
    this.props.setPeriod(
      this.state.typeDate,
      this.state.startDate,
      this.state.endDate
    );
  };
  async handleClickAtualiza() {
    await this.reloadChartData();
  }

  reloadChartData = async () => {
    var requestedData = {};
    this.props.spinnerActions.isLoading();
    await ChartService.fetchHome(
      this.props.auth.token,
      this.state.startDate,
      this.state.endDate
    ).then(res => {
      requestedData = res.data;
    });

    if (requestedData != undefined) {
      var updated = requestedData;
      var iniDash = this.props.dashboard;
      const initialChart = {
        ...iniDash,
        prodTop10Und: updated.produtos_mais_vendidos_und,
        prodTop10Val: updated.produtos_mais_vendidos_val,
        nomeCliente: updated.nome_cliente,
        ticketMedio: updated.ticket_medio,
        fatPeloCusto: updated.faturamento_pelo_custo,
        fatTotal: updated.faturamento_total,
        resOpera: updated.resultado_operacional_total,
        qtdPedidos: updated.quantidade_pedidos,
        totalDsc: updated.total_descontos,

        vendas: {
          ...iniDash.vendas,

          series: [
            {
              ...iniDash.vendas.series[0],
              name: "Últimos 30 dias",
              data: updated.faturamento.data
            }
          ],
          options: {
            ...iniDash.vendas.options,

            chart: {
              id: "chart-vendas"
            },
            subtitle: {
              text:
                "De " +
                moment(this.state.startDate).format("DD/MM/YYYY") +
                " até " +
                moment(this.state.endDate).format("DD/MM/YYYY")
            },
            xaxis: {
              ...iniDash.vendas.options.xaxis,
              convertedCatToNumeric: false,
              categories: updated.faturamento.labels,
              labels: {
                ...iniDash.vendas.options.xaxis.labels,
                show: true
              }
            }
          }
        },
        vendasPorMoeda: {
          ...iniDash.vendasPorMoeda,
          options: {
            ...iniDash.vendasPorMoeda.options,
            subtitle: {
              text:
                "De " +
                moment(this.state.startDate).format("DD/MM/YYYY") +
                " até " +
                moment(this.state.endDate).format("DD/MM/YYYY")
            },
            legend: {
              show: !isMobile
            },
            xaxis: {
              ...iniDash.vendasPorMoeda.options.xaxis,
              categories: updated.pedidos_por_moeda.labels
            },
            labels: updated.pedidos_por_moeda.labels
          },
          series: updated.pedidos_por_moeda.data
        },
        maisVendidos: {
          ...iniDash.maisVendidos,
          options: {
            ...iniDash.maisVendidos.options,
            subtitle: {
              text:
                "De " +
                moment(this.state.startDate).format("DD/MM/YYYY") +
                " até " +
                moment(this.state.endDate).format("DD/MM/YYYY")
            },
            legend: {
              show: !isMobile
            },
            labels: updated.grupos_mais_vendidos_vlr.labels
          },
          series: updated.grupos_mais_vendidos_vlr.data
        },
        vendasPorHorario: {
          ...iniDash.vendasPorHorario,
          options: {
            ...iniDash.vendasPorHorario.options,
            subtitle: {
              text:
                "De " +
                moment(this.state.startDate).format("DD/MM/YYYY") +
                " até " +
                moment(this.state.endDate).format("DD/MM/YYYY")
            },
            xaxis: {
              ...iniDash.vendasPorHorario.options.xaxis,
              categories: updated.vendas_por_hora.labels
            }
          },
          series: [
            {
              ...iniDash.vendasPorHorario.series[0],
              name: "Total em R$",
              type: "column",
              data: updated.vendas_por_hora.data2
            },
            {
              name: "Total de Pedidos",
              type: "line",
              data: updated.vendas_por_hora.data1
            }
          ]
        },
        vendasDiaSemana: {
          ...iniDash.vendasDiaSemana,
          options: {
            ...iniDash.vendasDiaSemana.options,
            subtitle: {
              text:
                "De " +
                moment(this.state.startDate).format("DD/MM/YYYY") +
                " até " +
                moment(this.state.endDate).format("DD/MM/YYYY")
            },
            xaxis: {
              ...iniDash.vendasDiaSemana.options.xaxis,
              categories: updated.vendas_por_dia.labels
            }
          },
          series: [
            {
              ...iniDash.vendasDiaSemana.series[0],
              name: "Total em R$",
              type: "column",
              data: updated.vendas_por_dia.data2
            },
            {
              name: "Total de Pedidos",
              type: "line",
              data: updated.vendas_por_dia.data1
            }
          ]
        },
        miniHistVendas: {
          ...iniDash.miniHistVendas,
          options: {
            ...iniDash.miniHistVendas.options,
            subtitle: {
              text:
                "Últimos " + updated.historico_vendas.labels.length + " meses"
            },
            xaxis: {
              ...iniDash.miniHistVendas.options.xaxis,
              categories: updated.historico_vendas.labels
            }
          },
          series: [
            {
              ...iniDash.miniHistVendas.series[0],
              name: "Total",
              data: updated.historico_vendas.data
            }
          ]
        },
        miniTicket: {
          ...iniDash.miniTicket,
          options: {
            ...iniDash.miniTicket.options,
            subtitle: {
              text:
                "Últimos " +
                updated.ticket_medio_historico.labels.length +
                " meses"
            },
            xaxis: {
              ...iniDash.miniTicket.options.xaxis,
              categories: updated.ticket_medio_historico.labels
            }
          },
          series: [
            {
              ...iniDash.miniTicket.series[0],
              name: "Total",
              data: updated.ticket_medio_historico.data
            }
          ]
        },
        miniDescontos: {
          ...iniDash.miniDescontos,
          options: {
            ...iniDash.miniDescontos.options,
            subtitle: {
              text:
                "Últimos " +
                updated.historico_descontos.labels.length +
                " meses"
            },
            xaxis: {
              ...iniDash.miniDescontos.options.xaxis,
              categories: updated.historico_descontos.labels
            }
          },
          series: [
            {
              ...iniDash.miniDescontos.series[0],
              name: "Total",
              data: updated.historico_descontos.data
            }
          ]
        }
      };
      this.props.dashboardActions.updateData(initialChart);
      this.props.spinnerActions.notLoading();
    }
  };

  componentDidMount() {
    this.reloadChartData();
  }

  render() {
    const wraperStyle = {
      paper: {
        padding: "10px",
        textAlign: "center",
        color: "#333"
      },
      textField: {
        marginLeft: "0.5%",
        marginRight: "0.5%",
        width: "48%"
      },
      radio: {
        float: "left",
        width: "32%",
        display: "inline-block",
        backgroundColor: "#999"
      },
      radioGroup: {
        width: "100%"
      },
      selectEmpty: {
        margin: "5%",
        width: "100%"
      },
      formControl: {
        marginRight: "5px"
      }
    };

    const yearDate = (
      <Fragment>
        <FormControl style={wraperStyle.formControl}>
          <Select
            name="age"
            displayEmpty
            style={wraperStyle.selectEmpty}
            value={this.state.yearDate}
            onChange={this.handleChangeYearOnlyDate}
          >
            <MenuItem value="2023" selected>
              2023
            </MenuItem>
            <MenuItem value="2024" selected>
              2024
            </MenuItem>
          </Select>
          <FormHelperText>Escolha o ano</FormHelperText>
        </FormControl>
      </Fragment>
    );

    const monthDate = (
      <Fragment>
        <FormControl style={wraperStyle.formControl}>
          <Select
            name="age"
            displayEmpty
            style={wraperStyle.selectEmpty}
            value={this.state.monthDate}
            onChange={this.handleChangeMonthDate}
          >
            <MenuItem value="01">Janeiro</MenuItem>
            <MenuItem value="02">Fevereiro</MenuItem>
            <MenuItem value="03">Março</MenuItem>
            <MenuItem value="04">Abril</MenuItem>
            <MenuItem value="05">Maio</MenuItem>
            <MenuItem value="06">Junho</MenuItem>
            <MenuItem value="07">Julho</MenuItem>
            <MenuItem value="08">Agosto</MenuItem>
            <MenuItem value="09">Setembro</MenuItem>
            <MenuItem value="10">Outubro</MenuItem>
            <MenuItem value="11">Novembro</MenuItem>
            <MenuItem value="12">Dezembro</MenuItem>
          </Select>
          <FormHelperText>Escolha o mês</FormHelperText>
        </FormControl>
        <FormControl style={wraperStyle.formControl}>
          <Select
            name="age"
            displayEmpty
            style={wraperStyle.selectEmpty}
            value={this.state.yearDate}
            onClick={this.handleChangeYearDate}
          >
            <MenuItem value="2023" selected>
              2023
            </MenuItem>
            <MenuItem value="2024" selected>
              2024
            </MenuItem>
          </Select>
          <FormHelperText>Escolha o Ano</FormHelperText>
        </FormControl>
      </Fragment>
    );

    const rangeDate = (
      <Fragment>
        <TextField
          id="start-date"
          label="De"
          type="date"
          value={this.state.startDate}
          onChange={this.handleChangeStartDate}
          style={wraperStyle.textField}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          id="end-date"
          label="Até"
          type="date"
          value={this.state.endDate}
          onChange={this.handleChangeEndDate}
          style={wraperStyle.textField}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Fragment>
    );

    var renderDate;

    switch (this.state.typeDate) {
      case "periodo":
        renderDate = rangeDate;
        break;

      case "mes":
        renderDate = monthDate;
        break;

      case "ano":
        renderDate = yearDate;
        break;
    }

    return (
      <Fragment>
        <Grid container spacing={3}>
          <Grid item xs={4} sm={2}>
            <Radio
              checked={this.state.typeDate === "periodo"}
              value="periodo"
              onChange={this.handleChangePeriod}
              name="radio-button-demo"
              inputProps={{ "aria-label": "A" }}
            />
            <span>Período</span>
          </Grid>
          <Grid item xs={4} sm={2}>
            <Radio
              checked={this.state.typeDate === "mes"}
              value="mes"
              onChange={this.handleChangeType}
              name="radio-button-demo"
              inputProps={{ "aria-label": "B" }}
            />
            <span>Mês</span>
          </Grid>
          <Grid item xs={4} sm={2}>
            <Radio
              checked={this.state.typeDate === "ano"}
              value="ano"
              onChange={this.handleChange}
              name="radio-button-demo"
              inputProps={{ "aria-label": "B" }}
            />
            <span>Ano</span>
          </Grid>
          <Grid item xs={12} sm={4}>
            {renderDate}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Fab
              variant="extended"
              aria-label="delete"
              size="medium"
              color="primary"
              onClick={this.handleClickAtualiza}
              style={wraperStyle.button}
            >
              <ThreeSixtyIcon />
              Atualizar
            </Fab>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  dashboard: state.dashboard,
  spinner: state.spinner.spinner
});

const mapDispatchToProps = dispatch => {
  return {
    dashboardActions: bindActionCreators({ updateData }, dispatch),
    spinnerActions: bindActionCreators({ isLoading, notLoading }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RgPeriodo);
