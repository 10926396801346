export function listaCadastrosRequest(rota) {
  return {
    type: "@cadastro/REQUEST",
    rota
  };
}
export function listaCadastrosSuccess(lista) {
  return {
    type: "@cadastro/SUCCESS",
    lista
  };
}

export function listaCadastroPorIdRequest(rota, id) {
  return {
    type: "@cadastro/REQUEST_ID",
    rota,
    id
  };
}
export function listaCadastroPorIdSuccess(payload) {
  return {
    type: "@cadastro/SUCCESS_ID",
    payload
  };
}
export function removeCadastroRequest(rota, id) {
  return {
    type: "@cadastro/DEL_REQUEST",
    rota,
    id
  };
}

export function removeCadastroSuccess(result) {
  return {
    type: "@cadastro/DEL_SUCCESS",
    result
  };
}

export function adicionaCadastroRequest(rota, cadastro) {
  return {
    type: "@cadastro/ADD_REQUEST",
    rota,
    cadastro
  };
}
export function adicionaCadastroSuccess(lista) {
  return {
    type: "@cadastro/ADD_SUCCESS",
    lista
  };
}

export function alteraCadastroSet(rota, id, cadastro) {
  return {
    type: "@cadastro/EDIT_SET",
    rota,
    id,
    cadastro
  };
}

export function alteraCadastroRequest(rota, id, cadastro) {
  return {
    type: "@cadastro/EDIT_REQUEST",
    rota,
    id,
    cadastro
  };
}
export function alteraCadastroSuccess(lista) {
  return {
    type: "@cadastro/EDIT_SUCCESS",
    lista
  };
}
