import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditComponent from "../ui/EditComponent";

export default function EditPedvendaItemComponent() {
  const history = useHistory();
  const dadosRedux = useSelector(state => state.cadastros.cadastro);
  const dadosStorage = JSON.parse(localStorage.getItem("pedvendafechamentos"));

  const colunas = [
    { title: "Código", field: "codpedvendfechamento" },
    { title: "Cód. Ped. Venda", field: "codpedvenda" },
    { title: "Cod. Moeda", field: "codmoeda" },
    { title: "Valor", field: "vlr" },
    { title: "Cod. Aber. Caixa", field: "codcaixaabertura" },
    { title: "Pos. Nr. Autorização", field: "posNrAutorizacao" },
    { title: "Pos. Pessoa", field: "posPessoa" },
    { title: "Qtde", field: "qtde" },
    { title: "Revisão", field: "revisao" },
    { title: "Cod. Empresa", field: "codempresa" }
  ];

  var retorno;

  if (!dadosRedux && !dadosStorage) {
    retorno = <></>;
    history.push("pedvendafechamentos");
  } else {
    retorno = (
      <div>
        <EditComponent
          title="Pedidos de Venda - Item"
          data={dadosRedux || dadosStorage}
          colunas={colunas}
        />
      </div>
    );
  }

  return retorno;
}
