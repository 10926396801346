import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditComponent from "../ui/EditComponent";

export default function EditProdutoComponent() {
  const history = useHistory();
  const dadosRedux = useSelector(state => state.cadastros.cadastro);
  const dadosStorage = JSON.parse(localStorage.getItem("produtogrupos"));

  const colunas = [
    { title: "Código", field: "codproduto" },
    { title: "Código Pesq 1", field: "codpesq1" },
    { title: "Descrição", field: "descricao" },
    { title: "Ativo", field: "ativo" },
    { title: "Tipo", field: "tipoProduto" },
    { title: "Código Grupo", field: "codprodutogrupo" },
    { title: "Código Subgrupo", field: "codprodutogruposubgrp" },
    { title: "Código Unidade", field: "codprodutounidade" },
    { title: "Estoque Atual", field: "estqAtual" },
    { title: "Estoque Mínimo", field: "estqMin" }
  ];

  var retorno;

  if (!dadosRedux && !dadosStorage) {
    retorno = <></>;
    history.push("produtogrupos");
  } else {
    retorno = (
      <div>
        <EditComponent
          title="Grupo de Produto"
          data={dadosRedux || dadosStorage}
          colunas={colunas}
        />
      </div>
    );
  }

  return retorno;
}
