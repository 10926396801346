import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListResControleComponent() {
  const colunas = [
    { title: "codcontrole", field: "codcontrole" },
    { title: "status", field: "status" },
    { title: "cobrarServico", field: "cobrarServico" }
  ];

  return (
    <div>
      <ListComponent
        route="rescontroles"
        listKeys={colunas}
        title="Controles"
      />
    </div>
  );
}
