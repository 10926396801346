import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditComponent from "../ui/EditComponent";

export default function EditParVendaComponent() {
  const history = useHistory();
  const dadosRedux = useSelector(state => state.cadastros.cadastro);
  const dadosStorage = JSON.parse(localStorage.getItem("parvendas"));

  const colunas = [
    { title: "Código", field: "codparvenda" },
    { title: "Default", field: "default" },
    { title: "ECF Modelo", field: "ecfModelo" },
    { title: "ECF Porta", field: "ecfPorta" },
    { title: "ECF Timeout", field: "ecfTimeout" },
    { title: "ECF Intervalo", field: "ecfIntervalo" },
    { title: "ECF Tent. Nova", field: "ecfTentNova" },
    { title: "ECF Bloq. Tecl. Mouse", field: "ecfBloqTeclMouse" },
    { title: "ECF Exib. Mens. Aguarde", field: "ecfExibMsgAguarde" },
    { title: "ECF Arred. Qtd.", field: "ecfArreQtde" }
  ];

  var retorno;

  if (!dadosRedux && !dadosStorage) {
    retorno = <></>;
    history.push("parvendas");
  } else {
    retorno = (
      <div>
        <EditComponent
          title="Parâmetro Restaurante"
          data={dadosRedux || dadosStorage}
          colunas={colunas}
        />
      </div>
    );
  }

  return retorno;
}
