import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListBancoComponent() {
  const colunas = [
    { title: "Código", field: "codbanco" },
    { title: "Descrição", field: "descricao" },
    { title: "Saldo Inicial", field: "saldoInicial" },
    { title: "Saldo Atual", field: "saldoAtual" },
    { title: "Contas", field: "contas" },
    { title: "Revisão", field: "revisao" },
    { title: "Agência", field: "agencia" },
    { title: "Operação", field: "operacao" },
    { title: "Conta", field: "conta" },
    { title: "Data Inicial", field: "dataInicial" }
  ];

  return (
    <div>
      <ListComponent route="bancos" listKeys={colunas} title="Bancos" />
    </div>
  );
}
