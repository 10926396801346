import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class ProdutoGrupoService {
  fetchProdutoGrupos() {
    return axios.get(config.baseURL + '/produtogrupos/', Headers);
  }

  fetchProdutoGrupoById(codprodutogrupo) {
    return axios.get(config.baseURL + '/produtogrupos/' + codprodutogrupo, Headers);
  }

  deleteProdutoGrupo(codprodutogrupo) {
    return axios.delete(config.baseURL + '/produtogrupos/' + codprodutogrupo, Headers);
  }

  addProdutoGrupo(produtoGrupo) {
    return axios.post(config.baseURL + '/produtogrupos/', produtoGrupo, Headers);
  }

  editProdutoGrupo(produtoGrupo) {
    return axios.put(config.baseURL + '/produtogrupos/' + produtoGrupo.codprodutogrupo, produtoGrupo, Headers);
  }
}

export default new ProdutoGrupoService();