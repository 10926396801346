import axios from "axios";
import { Config } from "../constants/config";
const config = Config;

class ApiService {
  fetchUsers(token) {
    return axios.get(config.baseURL + "/users", {
      headers: { Authorization: "Bearer " + token }
    });
  }

  fetchUserById(userId, token) {
    return axios.get(config.baseURL + "/users/" + userId, {
      headers: { Authorization: "Bearer " + token }
    });
  }

  deleteUser(userId, token) {
    return axios.delete(config.baseURL + "/users/" + userId, {
      headers: { Authorization: "Bearer " + token }
    });
  }

  addUser(user, token) {
    return axios.post("" + config.baseURL, user, {
      headers: { Authorization: "Bearer " + token }
    });
  }

  editUser(user, token) {
    return axios.put(config.baseURL + "/users/" + user.id, user, {
      headers: { Authorization: "Bearer " + token }
    });
  }
}

export default new ApiService();
