import axios from "axios";
import { call, select, put, all, takeLatest } from "redux-saga/effects";
import {
  addUserSuccess,
  removeUserSuccess,
  editUserSuccess
} from "../actions/user";
import { Config } from "../../constants/config";

function* addUser({ name, email, cellphone, password }) {
  const token = yield select(state => state.auth.token);
  const Headers = { headers: { Authorization: "Bearer " + token } };
  const Body = { name, email, cellphone, password };
  const response = yield call(
    axios.post,
    `${Config.baseURL}/users/`,
    Body,
    Headers
  );
  if (response.status === 200) {
    yield put(addUserSuccess(true, "Usuário cadastrado com sucesso."));
  } else {
    yield put(addUserSuccess(false, "Não foi possível cadastrar o usuário."));
  }
}

function* removeUser({ id }) {
  const token = yield select(state => state.auth.token);
  const Headers = { headers: { Authorization: "Bearer " + token } };

  const response = yield call(
    axios.delete,
    `${Config.baseURL}/users/${id}`,
    Headers
  );

  if (response.status === 200) {
    yield put(removeUserSuccess(true, "Usuário excluído com sucesso."));
  } else {
    yield put(removeUserSuccess(false, "Não foi possível excluir o usuário."));
  }
}

function* editUser({ userData }) {
  const token = yield select(state => state.auth.token);
  const Headers = { headers: { Authorization: "Bearer " + token } };
  const { id, name, email, cellphone } = userData;
  const Body = { name, email, cellphone };
  const response = yield call(
    axios.put,
    `${Config.baseURL}/users/${id}`,
    Body,
    Headers
  );

  if (response.status === 200) {
    yield put(editUserSuccess(true, "Usuário alterado com sucesso."));
  } else {
    yield put(editUserSuccess(false, "Não foi possível alterar o usuário."));
  }
}

export default all([
  takeLatest("@user/ADD_REQUEST", addUser),
  takeLatest("@user/REMOVE_REQUEST", removeUser),
  takeLatest("@user/EDIT_REQUEST", editUser)
]);
