import React, { Component } from "react";
import ApiService from "../../service/ApiService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import * as UsersActions from "../../redux/actions/user";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Container } from "../ui/base";

class EditUserComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        id: "",
        name: "",
        cellphone: "",
        email: ""
      }
    };
    this.saveUser = this.saveUser.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {}

  /*static getDerivedStateFromProps(props, state) {
    if (props.user !== state.user) {
      return {
        ...state,
        user: {
          ...props.user
        }
      };
    }
    return null;
  }
*/
  onChange = e => {
    this.setState({
      user: {
        ...this.state.user,
        [e.target.name]: e.target.value
      }
    });
  };

  saveUser = e => {
    e.preventDefault();

    const { editUserRequest } = this.props;
    editUserRequest(this.state.user);
  };

  render() {
    return (
      <div>
        <Container>
          <Typography variant="h4" style={style}>
            Edit User
          </Typography>
          <form>
            <TextField
              type="text"
              label="Nome"
              fullWidth
              margin="normal"
              name="name"
              readonly="true"
              value={this.state.user.name || ""}
              onChange={this.onChange}
            />

            <TextField
              label="Email"
              fullWidth
              margin="normal"
              name="email"
              value={this.state.user.email || ""}
              onChange={this.onChange}
            />

            <TextField
              label="Telefone"
              fullWidth
              margin="normal"
              name="cellphone"
              value={this.state.user.cellphone}
              onChange={this.onChange}
            />

            <Button variant="contained" color="primary" onClick={this.saveUser}>
              Save
            </Button>
          </form>
        </Container>
      </div>
    );
  }
}

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user.payload
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(UsersActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUserComponent);
