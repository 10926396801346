import React from "react";
import ListComponent from "../ui/ListComponent";

export default function ListProdutoComponent() {
  const colunas = [
    { title: "Código", field: "codproduto" },
    { title: "Código Pesq 1", field: "codpesq1" },
    { title: "Descrição", field: "descricao" },
    { title: "Ativo", field: "ativo" },
    { title: "Tipo", field: "tipoProduto" },
    { title: "Código Grupo", field: "codprodutogrupo" },
    { title: "Código Subgrupo", field: "codprodutogruposubgrp" },
    { title: "Código Unidade", field: "codprodutounidade" },
    { title: "Estoque Atual", field: "estqAtual" },
    { title: "Estoque Mínimo", field: "estqMin" }
  ];

  return (
    <div>
      <ListComponent route="produtos" listKeys={colunas} title="Produtos" />
    </div>
  );
}
