export function setPeriod(p, s, e) {
  return {
    type: "SET_PERIOD",
    payload: {
      periodo: p,
      startDate: s,
      endDate: e
    }
  };
}

export function updateData(data) {
  return {
    type: "UPDATE_DATA",
    payload: {
      data,
      at: Date.now()
    }
  };
}
