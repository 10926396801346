import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class ProdutoUnidadeService {
  fetchProdutoUnidades() {
    return axios.get(config.baseURL + '/produtounidades/', Headers);
  }

  fetchProdutoUnidadeById(codprodutounidade) {
    return axios.get(config.baseURL + '/produtounidades/' + codprodutounidade, Headers);
  }

  deleteProdutoUnidade(codprodutounidade) {
    return axios.delete(config.baseURL + '/produtounidades/' + codprodutounidade, Headers);
  }

  addProdutoUnidade(produtoUnidade) {
    return axios.post(config.baseURL + '/produtounidades/', produtoUnidade, Headers);
  }

  editProdutoUnidade(produtoUnidade) {
    return axios.put(config.baseURL + '/produtounidades/' + produtoUnidade.codprodutounidade, produtoUnidade, Headers);
  }
}

export default new ProdutoUnidadeService();