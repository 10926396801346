export function notLoading() {
  return {
    type: "LOADING_OFF"
  };
}

export function isLoading() {
  return {
    type: "LOADING_ON"
  };
}
