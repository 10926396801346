import React from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardMedia";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SessionService from "../../service/SessionService";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "../../redux/actions/auth";
import { Link } from "react-router-dom";
import PasswordService from "../../service/passwordService";

import { Container } from "../ui/base";
import { Box } from "@material-ui/core";
import logo from '../../assets/logo.png';
import styled from 'styled-components';

const Background = styled(Box)(({ theme }) => ({
  backgroundPosition: 'bottom right',
  backgroundSize: 'contain',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#36A9E0',
}));

class LoginComponent extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      message: ""
    };
    this.btnLogin = this.btnLogin.bind(this);
    this.keyLogin = this.keyLogin.bind(this);
  }

  componentDidMount() {
    localStorage.clear();
//    document.body.style.backgroundImage = "url(images/bg_login.jpg)";
    this.setState({
      email: "",
      password: ""
    });
  }
  keyLogin = e => {
    if (e.keyCode === 13) {
      const credentials = {
        email: this.state.email,
        password: this.state.password
      };
      SessionService.login(credentials).then(
        res => {
          console.log("logou");
          if (res.status === 200) {
            this.props.loginUser(res.data.token);
            localStorage.setItem("userInfo", res.data.token);
            this.props.history.push("/dashboards/principal");
          }
        },
        err => {
          this.setState({
            message: "Nome de usuário ou senha inválidos!",
            errMessage: err.message
          });
        }
      );
    }
  };

  btnLogin = e => {
    e.preventDefault();
    const credentials = {
      email: this.state.email,
      password: this.state.password
    };
    SessionService.login(credentials).then(
      res => {
        console.log("logou");
        if (res.status === 200) {
          this.props.loginUser(res.data.token);
          localStorage.setItem("userInfo", res.data.token);
          this.props.history.push("/dashboards/principal");
        }
      },
      err => {
        this.setState({
          message: "Nome de usuário ou senha inválidos!",
          errMessage: err.message
        });
      }
    );
  };

  onChange = e =>
    this.setState({ [e.target.name]: e.target.value, message: "" });
  render() {
    return (
      <Background>
        <Container navbar="off">
          <Box sx={{ mb: 3 }}>
            <center>
              <img src={logo} alt="Logo" style={{ width: 180 }} />
            </center>
          </Box>

          <Card style={styles.card}>
            <CardContent>
              <form>
                <TextField
                  style={styles.textField}
                  type="text"
                  label="E-MAIL"
                  margin="normal"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  tabIndex="0"
                  onKeyDown={this.keyLogin}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Digite seu login"
                />
                <TextField
                  style={styles.textField}
                  type="password"
                  margin="normal"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  label="SENHA"
                  tabIndex="1"
                  helperText=""
                  onKeyDown={this.keyLogin}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Digite sua senha"
                />
                <Link
                  style={styles.link}
                  to="/alterarsenha"
                >
                  Recuperar senha
                </Link>
                <h5 style={styles.notification}>{this.state.message}</h5>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.btnLogin}
                  style={styles.button}
                  size="large"
                >
                  Acessar
                </Button>
              </form>
            </CardContent>
          </Card>
        </Container>
      </Background>
    );
  }
}

const styles = {
  center: {
    display: "flex",
    justifyContent: "center"
  },
  button: {
    margin: "10px",
    width: "95%",
    backgroundColor: "#5a9495"
  },
  notification: {
    display: "flex",
    justifyContent: "center",
    color: "#dc3545",
    minHeight: "10px"
  },
  card: {
    minWidth: 275,
    maxWidth: 350,
    margin: "50px auto",
    padding: "10px",
    background: "#FFF"
  },
  textField: {
    backgroundColor: "none",
    width: "95%",
    margin: "20px 10px"
  },
  input: {
    backgroundColor: "none"
  },
  margin: {
    margin: "5% auto",
    width: "100%"
  },
  media: {
    width: "100%",
    paddingTop: "10px", // 16:9
    margin: "50px"
  },
  link: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0",
    textAlign: "right",
    width: "calc(100% - 10px)",
    cursor: "pointer",
    fontSize: "14px",
    textDecoration: "none"
  }
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(authActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent);
