import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditComponent from "../ui/EditComponent";

export default function EditResControle() {
  const history = useHistory();
  const dadosRedux = useSelector(state => state.cadastros.cadastro);
  const dadosStorage = JSON.parse(localStorage.getItem("formapagamentos"));

  const colunas = [
    { title: "Codigo", field: "codformapagamento" },
    { title: "Descrição", field: "descricao" },
    { title: "Parcelas", field: "parcelas" },
    { title: "Dias 1ª parc.", field: "numdias" },
    { title: "Dias Demais parc.", field: "numdiasDemais" },
    { title: "Código Moeda", field: "codmoeda" },
    { title: "Acréscimo", field: "perAcres" },
    { title: "Separar", field: "separar" },
    { title: "VRP", field: "vrp" },
    { title: "Tipo de Preço", field: "tipopreco" }
  ];

  var retorno;

  if (!dadosRedux && !dadosStorage) {
    retorno = <></>;
    history.push("formapagamentos");
  } else {
    retorno = (
      <div>
        <EditComponent
          title="Forma de Pagamento"
          data={dadosRedux || dadosStorage}
          colunas={colunas}
        />
      </div>
    );
  }

  return retorno;
}
