import React, { Component } from "react";
import PessoaService from "../../service/PessoaService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddPessoaComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipoPessoa: "",
      nome: "",
      razaoSocial: "",
      cpfcnpj: "",
      ie: "",
      im: "",
      endereco: "",
      enderecoNumero: "",
      enderecoComplemento: "",
      bairro: "",
      cidade: "",
      uf: "",
      cep: "",
      telefoneDdd: "",
      telefone: "",
      faxDdd: "",
      fax: "",
      celularDdd: "",
      celular: "",
      contatos: "",
      email: "",
      homepage: "",
      pontoReferencia: "",
      classCli: "",
      classFor: "",
      classTra: "",
      ativo: "",
      classFab: "",
      limiteCredito: "",
      consumidorFinal: "",
      dsp: "",
      obs: "",
      codvendedor: "",
      venComissao: "",
      preco01: "",
      preco02: "",
      preco03: "",
      preco04: "",
      preco05: "",
      descMax: "",
      medico: "",
      optica: "",
      data: "",
      sexo: "",
      profissao: "",
      filhos: "",
      tpCadLoja: "",
      tpCadOutr: "",
      tpCadCart: "",
      adicao: "",
      lngOdEsf: "",
      lngOdCil: "",
      lngOdEixo: "",
      lngOdAlt: "",
      lngOdDnp: "",
      lngOeEsf: "",
      lngOeCil: "",
      lngOeEixo: "",
      lngOeAlt: "",
      lngOeDnp: "",
      prtOdEsf: "",
      prtOdCil: "",
      prtOdEixo: "",
      prtOdAlt: "",
      prtOdDnp: "",
      prtOeEsf: "",
      prtOeCil: "",
      prtOeEixo: "",
      prtOeAlt: "",
      prtOeDnp: "",
      cnhCategoria: "",
      cnhNumero: "",
      cnhDt1cart: "",
      cnhDtEmissao: "",
      cnhObs: "",
      placa: "",
      veiModelo: "",
      veiCor: "",
      dtNasc: "",
      pai: "",
      mae: "",
      escolidade: "",
      fonte: "",
      escola: "",
      estadoCivil: "",
      codcidade: "",
      naturalidade: "",
      escolaSerie: "",
      rg: "",
      parceria: "",
      grade: "",
      faturamento: "",
      codfonte: "",
      codgrade: "",
      codfaixafaturamento: "",
      codparceria: "",
      mediaFaturamento: "",
      ant: "",
      atendimento: "",
      simul: "",
      simulAn: "",
      mediaFaturamentoSim: "",
      pais: "",
      classFun: "",
      prevProxVenda: "",
      classParc: "",
      codcli: "",
      classificacao: "",
      trabEmpresa: "",
      trabDtAdmissao: "",
      trabEndereco: "",
      trabCargo: "",
      trabSalario: "",
      cobrEndereco: "",
      cobrEnderecoNumero: "",
      cobrEnderecoComplemento: "",
      cobrEnderecoBairro: "",
      cobrEnderecoCidade: "",
      cobrEnderecoCep: "",
      cobrEnderecoPontoRef: "",
      avalNome: "",
      avalRg: "",
      avalCpf: "",
      avalDtNasc: "",
      avalEndereco: "",
      avalTelefoneDdd: "",
      avalTelefone: "",
      avalCargoProfissao: "",
      avalEmpresa: "",
      avalSalario: "",
      refPes1Nome: "",
      refPes1Telefones: "",
      refPes1Endereco: "",
      refPes2Nome: "",
      refPes2Telefones: "",
      refPes2Endereco: "",
      refCom1Estabelecimento: "",
      refCom1DtCompra: "",
      refCom1VlrCompra: "",
      refCom1QtdeParcelas: "",
      refCom2Estabelecimento: "",
      refCom2DtCompra: "",
      refCom2VlrCompra: "",
      refCom2QtdeParcelas: "",
      grupo: "",
      codpessoagrupo: "",
      codveiculomarca: "",
      codveiculomodelo: "",
      codveiculocor: "",
      veiAno: "",
      mesReajuste: "",
      usuario: "",
      skype: "",
      msn: "",
      paypal: "",
      codpais: "",
      provincia: "",
      nivel: "",
      pontos: "",
      pontosRecebidos: "",
      comissao1: "",
      comissao2: "",
      comissao3: "",
      comissao4: "",
      comissao5: "",
      codtransportadora: "",
      login: "",
      senha: "",
      fase: "",
      codpessoaempresa: "",
      codcargo: "",
      codformapagamento: "",
      codrotaentrega: "",
      animalRaca: "",
      animalProprietario: "",
      animalNomeVeter: "",
      animalCor: "",
      animalRacao: "",
      animal: "",
      codsoftware: "",
      classInseminador: "",
      responsavelCpf: "",
      responsavelNome: "",
      revisao: "",
      dadosBancarios: "",
      dataDll: "",
      consultaDll: "",
      banFavorecido: "",
      banBanco: "",
      banAgencia: "",
      banContaCorrente: "",
      banCpfcnpjFavorecido: "",
      codescola: "",
      codtrecho: "",
      coddepartamento: "",
      preco06: "",
      preco07: "",
      preco08: "",
      subfornecedor: "",
      codcentcusto: "",
      emailNfe: "",
      sistemaExclusivo: "",
      versao: "",
      pafversao: "",
      asoProprio: "",
      responsavel: "",
      naturalidadeUf: "",
      escolaAno: "",
      escolaCidade: "",
      escolaUf: "",
      rgOrgao: "",
      popular: "",
      matricula: "",
      codprorhperfilescala: "",
      dtTrabalho: "",
      codprorhescala: "",
      guiaAltura: "",
      guiaAlimento: "",
      guiaEletricidade: "",
      message: null
    };
    this.savePessoa = this.savePessoa.bind(this);
  }

  savePessoa = e => {
    e.preventDefault();
    let pessoas = {
      tipoPessoa: this.state.tipoPessoa,
      nome: this.state.nome,
      razaoSocial: this.state.razaoSocial,
      cpfcnpj: this.state.cpfcnpj,
      ie: this.state.ie,
      im: this.state.im,
      endereco: this.state.endereco,
      enderecoNumero: this.state.enderecoNumero,
      enderecoComplemento: this.state.enderecoComplemento,
      bairro: this.state.bairro,
      cidade: this.state.cidade,
      uf: this.state.uf,
      cep: this.state.cep,
      telefoneDdd: this.state.telefoneDdd,
      telefone: this.state.telefone,
      faxDdd: this.state.faxDdd,
      fax: this.state.fax,
      celularDdd: this.state.celularDdd,
      celular: this.state.celular,
      contatos: this.state.contatos,
      email: this.state.email,
      homepage: this.state.homepage,
      pontoReferencia: this.state.pontoReferencia,
      classCli: this.state.classCli,
      classFor: this.state.classFor,
      classTra: this.state.classTra,
      ativo: this.state.ativo,
      classFab: this.state.classFab,
      limiteCredito: this.state.limiteCredito,
      consumidorFinal: this.state.consumidorFinal,
      dsp: this.state.dsp,
      obs: this.state.obs,
      codvendedor: this.state.codvendedor,
      venComissao: this.state.venComissao,
      preco01: this.state.preco01,
      preco02: this.state.preco02,
      preco03: this.state.preco03,
      preco04: this.state.preco04,
      preco05: this.state.preco05,
      descMax: this.state.descMax,
      medico: this.state.medico,
      optica: this.state.optica,
      data: this.state.data,
      sexo: this.state.sexo,
      profissao: this.state.profissao,
      filhos: this.state.filhos,
      tpCadLoja: this.state.tpCadLoja,
      tpCadOutr: this.state.tpCadOutr,
      tpCadCart: this.state.tpCadCart,
      adicao: this.state.adicao,
      lngOdEsf: this.state.lngOdEsf,
      lngOdCil: this.state.lngOdCil,
      lngOdEixo: this.state.lngOdEixo,
      lngOdAlt: this.state.lngOdAlt,
      lngOdDnp: this.state.lngOdDnp,
      lngOeEsf: this.state.lngOeEsf,
      lngOeCil: this.state.lngOeCil,
      lngOeEixo: this.state.lngOeEixo,
      lngOeAlt: this.state.lngOeAlt,
      lngOeDnp: this.state.lngOeDnp,
      prtOdEsf: this.state.prtOdEsf,
      prtOdCil: this.state.prtOdCil,
      prtOdEixo: this.state.prtOdEixo,
      prtOdAlt: this.state.prtOdAlt,
      prtOdDnp: this.state.prtOdDnp,
      prtOeEsf: this.state.prtOeEsf,
      prtOeCil: this.state.prtOeCil,
      prtOeEixo: this.state.prtOeEixo,
      prtOeAlt: this.state.prtOeAlt,
      prtOeDnp: this.state.prtOeDnp,
      cnhCategoria: this.state.cnhCategoria,
      cnhNumero: this.state.cnhNumero,
      cnhDt1cart: this.state.cnhDt1cart,
      cnhDtEmissao: this.state.cnhDtEmissao,
      cnhObs: this.state.cnhObs,
      placa: this.state.placa,
      veiModelo: this.state.veiModelo,
      veiCor: this.state.veiCor,
      dtNasc: this.state.dtNasc,
      pai: this.state.pai,
      mae: this.state.mae,
      escolidade: this.state.escolidade,
      fonte: this.state.fonte,
      escola: this.state.escola,
      estadoCivil: this.state.estadoCivil,
      codcidade: this.state.codcidade,
      naturalidade: this.state.naturalidade,
      escolaSerie: this.state.escolaSerie,
      rg: this.state.rg,
      parceria: this.state.parceria,
      grade: this.state.grade,
      faturamento: this.state.faturamento,
      codfonte: this.state.codfonte,
      codgrade: this.state.codgrade,
      codfaixafaturamento: this.state.codfaixafaturamento,
      codparceria: this.state.codparceria,
      mediaFaturamento: this.state.mediaFaturamento,
      ant: this.state.ant,
      atendimento: this.state.atendimento,
      simul: this.state.simul,
      simulAn: this.state.simulAn,
      mediaFaturamentoSim: this.state.mediaFaturamentoSim,
      pais: this.state.pais,
      classFun: this.state.classFun,
      prevProxVenda: this.state.prevProxVenda,
      classParc: this.state.classParc,
      codcli: this.state.codcli,
      classificacao: this.state.classificacao,
      trabEmpresa: this.state.trabEmpresa,
      trabDtAdmissao: this.state.trabDtAdmissao,
      trabEndereco: this.state.trabEndereco,
      trabCargo: this.state.trabCargo,
      trabSalario: this.state.trabSalario,
      cobrEndereco: this.state.cobrEndereco,
      cobrEnderecoNumero: this.state.cobrEnderecoNumero,
      cobrEnderecoComplemento: this.state.cobrEnderecoComplemento,
      cobrEnderecoBairro: this.state.cobrEnderecoBairro,
      cobrEnderecoCidade: this.state.cobrEnderecoCidade,
      cobrEnderecoCep: this.state.cobrEnderecoCep,
      cobrEnderecoPontoRef: this.state.cobrEnderecoPontoRef,
      avalNome: this.state.avalNome,
      avalRg: this.state.avalRg,
      avalCpf: this.state.avalCpf,
      avalDtNasc: this.state.avalDtNasc,
      avalEndereco: this.state.avalEndereco,
      avalTelefoneDdd: this.state.avalTelefoneDdd,
      avalTelefone: this.state.avalTelefone,
      avalCargoProfissao: this.state.avalCargoProfissao,
      avalEmpresa: this.state.avalEmpresa,
      avalSalario: this.state.avalSalario,
      refPes1Nome: this.state.refPes1Nome,
      refPes1Telefones: this.state.refPes1Telefones,
      refPes1Endereco: this.state.refPes1Endereco,
      refPes2Nome: this.state.refPes2Nome,
      refPes2Telefones: this.state.refPes2Telefones,
      refPes2Endereco: this.state.refPes2Endereco,
      refCom1Estabelecimento: this.state.refCom1Estabelecimento,
      refCom1DtCompra: this.state.refCom1DtCompra,
      refCom1VlrCompra: this.state.refCom1VlrCompra,
      refCom1QtdeParcelas: this.state.refCom1QtdeParcelas,
      refCom2Estabelecimento: this.state.refCom2Estabelecimento,
      refCom2DtCompra: this.state.refCom2DtCompra,
      refCom2VlrCompra: this.state.refCom2VlrCompra,
      refCom2QtdeParcelas: this.state.refCom2QtdeParcelas,
      grupo: this.state.grupo,
      codpessoagrupo: this.state.codpessoagrupo,
      codveiculomarca: this.state.codveiculomarca,
      codveiculomodelo: this.state.codveiculomodelo,
      codveiculocor: this.state.codveiculocor,
      veiAno: this.state.veiAno,
      mesReajuste: this.state.mesReajuste,
      usuario: this.state.usuario,
      skype: this.state.skype,
      msn: this.state.msn,
      paypal: this.state.paypal,
      codpais: this.state.codpais,
      provincia: this.state.provincia,
      nivel: this.state.nivel,
      pontos: this.state.pontos,
      pontosRecebidos: this.state.pontosRecebidos,
      comissao1: this.state.comissao1,
      comissao2: this.state.comissao2,
      comissao3: this.state.comissao3,
      comissao4: this.state.comissao4,
      comissao5: this.state.comissao5,
      codtransportadora: this.state.codtransportadora,
      login: this.state.login,
      senha: this.state.senha,
      fase: this.state.fase,
      codpessoaempresa: this.state.codpessoaempresa,
      codcargo: this.state.codcargo,
      codformapagamento: this.state.codformapagamento,
      codrotaentrega: this.state.codrotaentrega,
      animalRaca: this.state.animalRaca,
      animalProprietario: this.state.animalProprietario,
      animalNomeVeter: this.state.animalNomeVeter,
      animalCor: this.state.animalCor,
      animalRacao: this.state.animalRacao,
      animal: this.state.animal,
      codsoftware: this.state.codsoftware,
      classInseminador: this.state.classInseminador,
      responsavelCpf: this.state.responsavelCpf,
      responsavelNome: this.state.responsavelNome,
      revisao: this.state.revisao,
      dadosBancarios: this.state.dadosBancarios,
      dataDll: this.state.dataDll,
      consultaDll: this.state.consultaDll,
      banFavorecido: this.state.banFavorecido,
      banBanco: this.state.banBanco,
      banAgencia: this.state.banAgencia,
      banContaCorrente: this.state.banContaCorrente,
      banCpfcnpjFavorecido: this.state.banCpfcnpjFavorecido,
      codescola: this.state.codescola,
      codtrecho: this.state.codtrecho,
      coddepartamento: this.state.coddepartamento,
      preco06: this.state.preco06,
      preco07: this.state.preco07,
      preco08: this.state.preco08,
      subfornecedor: this.state.subfornecedor,
      codcentcusto: this.state.codcentcusto,
      emailNfe: this.state.emailNfe,
      sistemaExclusivo: this.state.sistemaExclusivo,
      versao: this.state.versao,
      pafversao: this.state.pafversao,
      asoProprio: this.state.asoProprio,
      responsavel: this.state.responsavel,
      naturalidadeUf: this.state.naturalidadeUf,
      escolaAno: this.state.escolaAno,
      escolaCidade: this.state.escolaCidade,
      escolaUf: this.state.escolaUf,
      rgOrgao: this.state.rgOrgao,
      popular: this.state.popular,
      matricula: this.state.matricula,
      codprorhperfilescala: this.state.codprorhperfilescala,
      dtTrabalho: this.state.dtTrabalho,
      codprorhescala: this.state.codprorhescala,
      guiaAltura: this.state.guiaAltura,
      guiaAlimento: this.state.guiaAlimento,
      guiaEletricidade: this.state.guiaEletricidade,
    };
    PessoaService.addPessoa(pessoas).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/pessoas");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar Pessoa
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="tipoPessoa"
	            fullWidth
	            margin="normal"
	            name="tipoPessoa"
	            value={this.state.tipoPessoa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nome"
	            fullWidth
	            margin="normal"
	            name="nome"
	            value={this.state.nome}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="razaoSocial"
	            fullWidth
	            margin="normal"
	            name="razaoSocial"
	            value={this.state.razaoSocial}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cpfcnpj"
	            fullWidth
	            margin="normal"
	            name="cpfcnpj"
	            value={this.state.cpfcnpj}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ie"
	            fullWidth
	            margin="normal"
	            name="ie"
	            value={this.state.ie}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="im"
	            fullWidth
	            margin="normal"
	            name="im"
	            value={this.state.im}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="endereco"
	            fullWidth
	            margin="normal"
	            name="endereco"
	            value={this.state.endereco}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="enderecoNumero"
	            fullWidth
	            margin="normal"
	            name="enderecoNumero"
	            value={this.state.enderecoNumero}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="enderecoComplemento"
	            fullWidth
	            margin="normal"
	            name="enderecoComplemento"
	            value={this.state.enderecoComplemento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="bairro"
	            fullWidth
	            margin="normal"
	            name="bairro"
	            value={this.state.bairro}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cidade"
	            fullWidth
	            margin="normal"
	            name="cidade"
	            value={this.state.cidade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="uf"
	            fullWidth
	            margin="normal"
	            name="uf"
	            value={this.state.uf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cep"
	            fullWidth
	            margin="normal"
	            name="cep"
	            value={this.state.cep}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="telefoneDdd"
	            fullWidth
	            margin="normal"
	            name="telefoneDdd"
	            value={this.state.telefoneDdd}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="telefone"
	            fullWidth
	            margin="normal"
	            name="telefone"
	            value={this.state.telefone}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="faxDdd"
	            fullWidth
	            margin="normal"
	            name="faxDdd"
	            value={this.state.faxDdd}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="fax"
	            fullWidth
	            margin="normal"
	            name="fax"
	            value={this.state.fax}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="celularDdd"
	            fullWidth
	            margin="normal"
	            name="celularDdd"
	            value={this.state.celularDdd}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="celular"
	            fullWidth
	            margin="normal"
	            name="celular"
	            value={this.state.celular}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="contatos"
	            fullWidth
	            margin="normal"
	            name="contatos"
	            value={this.state.contatos}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="email"
	            fullWidth
	            margin="normal"
	            name="email"
	            value={this.state.email}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="homepage"
	            fullWidth
	            margin="normal"
	            name="homepage"
	            value={this.state.homepage}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="pontoReferencia"
	            fullWidth
	            margin="normal"
	            name="pontoReferencia"
	            value={this.state.pontoReferencia}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="classCli"
	            fullWidth
	            margin="normal"
	            name="classCli"
	            value={this.state.classCli}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="classFor"
	            fullWidth
	            margin="normal"
	            name="classFor"
	            value={this.state.classFor}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="classTra"
	            fullWidth
	            margin="normal"
	            name="classTra"
	            value={this.state.classTra}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ativo"
	            fullWidth
	            margin="normal"
	            name="ativo"
	            value={this.state.ativo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="classFab"
	            fullWidth
	            margin="normal"
	            name="classFab"
	            value={this.state.classFab}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="limiteCredito"
	            fullWidth
	            margin="normal"
	            name="limiteCredito"
	            value={this.state.limiteCredito}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="consumidorFinal"
	            fullWidth
	            margin="normal"
	            name="consumidorFinal"
	            value={this.state.consumidorFinal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dsp"
	            fullWidth
	            margin="normal"
	            name="dsp"
	            value={this.state.dsp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="obs"
	            fullWidth
	            margin="normal"
	            name="obs"
	            value={this.state.obs}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codvendedor"
	            fullWidth
	            margin="normal"
	            name="codvendedor"
	            value={this.state.codvendedor}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="venComissao"
	            fullWidth
	            margin="normal"
	            name="venComissao"
	            value={this.state.venComissao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="preco01"
	            fullWidth
	            margin="normal"
	            name="preco01"
	            value={this.state.preco01}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="preco02"
	            fullWidth
	            margin="normal"
	            name="preco02"
	            value={this.state.preco02}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="preco03"
	            fullWidth
	            margin="normal"
	            name="preco03"
	            value={this.state.preco03}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="preco04"
	            fullWidth
	            margin="normal"
	            name="preco04"
	            value={this.state.preco04}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="preco05"
	            fullWidth
	            margin="normal"
	            name="preco05"
	            value={this.state.preco05}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="descMax"
	            fullWidth
	            margin="normal"
	            name="descMax"
	            value={this.state.descMax}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="medico"
	            fullWidth
	            margin="normal"
	            name="medico"
	            value={this.state.medico}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="optica"
	            fullWidth
	            margin="normal"
	            name="optica"
	            value={this.state.optica}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="data"
	            fullWidth
	            margin="normal"
	            name="data"
	            value={this.state.data}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="sexo"
	            fullWidth
	            margin="normal"
	            name="sexo"
	            value={this.state.sexo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="profissao"
	            fullWidth
	            margin="normal"
	            name="profissao"
	            value={this.state.profissao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="filhos"
	            fullWidth
	            margin="normal"
	            name="filhos"
	            value={this.state.filhos}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tpCadLoja"
	            fullWidth
	            margin="normal"
	            name="tpCadLoja"
	            value={this.state.tpCadLoja}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tpCadOutr"
	            fullWidth
	            margin="normal"
	            name="tpCadOutr"
	            value={this.state.tpCadOutr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="tpCadCart"
	            fullWidth
	            margin="normal"
	            name="tpCadCart"
	            value={this.state.tpCadCart}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="adicao"
	            fullWidth
	            margin="normal"
	            name="adicao"
	            value={this.state.adicao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="lngOdEsf"
	            fullWidth
	            margin="normal"
	            name="lngOdEsf"
	            value={this.state.lngOdEsf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="lngOdCil"
	            fullWidth
	            margin="normal"
	            name="lngOdCil"
	            value={this.state.lngOdCil}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="lngOdEixo"
	            fullWidth
	            margin="normal"
	            name="lngOdEixo"
	            value={this.state.lngOdEixo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="lngOdAlt"
	            fullWidth
	            margin="normal"
	            name="lngOdAlt"
	            value={this.state.lngOdAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="lngOdDnp"
	            fullWidth
	            margin="normal"
	            name="lngOdDnp"
	            value={this.state.lngOdDnp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="lngOeEsf"
	            fullWidth
	            margin="normal"
	            name="lngOeEsf"
	            value={this.state.lngOeEsf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="lngOeCil"
	            fullWidth
	            margin="normal"
	            name="lngOeCil"
	            value={this.state.lngOeCil}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="lngOeEixo"
	            fullWidth
	            margin="normal"
	            name="lngOeEixo"
	            value={this.state.lngOeEixo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="lngOeAlt"
	            fullWidth
	            margin="normal"
	            name="lngOeAlt"
	            value={this.state.lngOeAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="lngOeDnp"
	            fullWidth
	            margin="normal"
	            name="lngOeDnp"
	            value={this.state.lngOeDnp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="prtOdEsf"
	            fullWidth
	            margin="normal"
	            name="prtOdEsf"
	            value={this.state.prtOdEsf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="prtOdCil"
	            fullWidth
	            margin="normal"
	            name="prtOdCil"
	            value={this.state.prtOdCil}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="prtOdEixo"
	            fullWidth
	            margin="normal"
	            name="prtOdEixo"
	            value={this.state.prtOdEixo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="prtOdAlt"
	            fullWidth
	            margin="normal"
	            name="prtOdAlt"
	            value={this.state.prtOdAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="prtOdDnp"
	            fullWidth
	            margin="normal"
	            name="prtOdDnp"
	            value={this.state.prtOdDnp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="prtOeEsf"
	            fullWidth
	            margin="normal"
	            name="prtOeEsf"
	            value={this.state.prtOeEsf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="prtOeCil"
	            fullWidth
	            margin="normal"
	            name="prtOeCil"
	            value={this.state.prtOeCil}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="prtOeEixo"
	            fullWidth
	            margin="normal"
	            name="prtOeEixo"
	            value={this.state.prtOeEixo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="prtOeAlt"
	            fullWidth
	            margin="normal"
	            name="prtOeAlt"
	            value={this.state.prtOeAlt}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="prtOeDnp"
	            fullWidth
	            margin="normal"
	            name="prtOeDnp"
	            value={this.state.prtOeDnp}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cnhCategoria"
	            fullWidth
	            margin="normal"
	            name="cnhCategoria"
	            value={this.state.cnhCategoria}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cnhNumero"
	            fullWidth
	            margin="normal"
	            name="cnhNumero"
	            value={this.state.cnhNumero}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cnhDt1cart"
	            fullWidth
	            margin="normal"
	            name="cnhDt1cart"
	            value={this.state.cnhDt1cart}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cnhDtEmissao"
	            fullWidth
	            margin="normal"
	            name="cnhDtEmissao"
	            value={this.state.cnhDtEmissao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cnhObs"
	            fullWidth
	            margin="normal"
	            name="cnhObs"
	            value={this.state.cnhObs}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="placa"
	            fullWidth
	            margin="normal"
	            name="placa"
	            value={this.state.placa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="veiModelo"
	            fullWidth
	            margin="normal"
	            name="veiModelo"
	            value={this.state.veiModelo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="veiCor"
	            fullWidth
	            margin="normal"
	            name="veiCor"
	            value={this.state.veiCor}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dtNasc"
	            fullWidth
	            margin="normal"
	            name="dtNasc"
	            value={this.state.dtNasc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="pai"
	            fullWidth
	            margin="normal"
	            name="pai"
	            value={this.state.pai}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="mae"
	            fullWidth
	            margin="normal"
	            name="mae"
	            value={this.state.mae}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="escolidade"
	            fullWidth
	            margin="normal"
	            name="escolidade"
	            value={this.state.escolidade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="fonte"
	            fullWidth
	            margin="normal"
	            name="fonte"
	            value={this.state.fonte}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="escola"
	            fullWidth
	            margin="normal"
	            name="escola"
	            value={this.state.escola}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="estadoCivil"
	            fullWidth
	            margin="normal"
	            name="estadoCivil"
	            value={this.state.estadoCivil}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codcidade"
	            fullWidth
	            margin="normal"
	            name="codcidade"
	            value={this.state.codcidade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="naturalidade"
	            fullWidth
	            margin="normal"
	            name="naturalidade"
	            value={this.state.naturalidade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="escolaSerie"
	            fullWidth
	            margin="normal"
	            name="escolaSerie"
	            value={this.state.escolaSerie}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="rg"
	            fullWidth
	            margin="normal"
	            name="rg"
	            value={this.state.rg}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="parceria"
	            fullWidth
	            margin="normal"
	            name="parceria"
	            value={this.state.parceria}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="grade"
	            fullWidth
	            margin="normal"
	            name="grade"
	            value={this.state.grade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="faturamento"
	            fullWidth
	            margin="normal"
	            name="faturamento"
	            value={this.state.faturamento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codfonte"
	            fullWidth
	            margin="normal"
	            name="codfonte"
	            value={this.state.codfonte}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codgrade"
	            fullWidth
	            margin="normal"
	            name="codgrade"
	            value={this.state.codgrade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codfaixafaturamento"
	            fullWidth
	            margin="normal"
	            name="codfaixafaturamento"
	            value={this.state.codfaixafaturamento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codparceria"
	            fullWidth
	            margin="normal"
	            name="codparceria"
	            value={this.state.codparceria}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="mediaFaturamento"
	            fullWidth
	            margin="normal"
	            name="mediaFaturamento"
	            value={this.state.mediaFaturamento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="ant"
	            fullWidth
	            margin="normal"
	            name="ant"
	            value={this.state.ant}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="atendimento"
	            fullWidth
	            margin="normal"
	            name="atendimento"
	            value={this.state.atendimento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="simul"
	            fullWidth
	            margin="normal"
	            name="simul"
	            value={this.state.simul}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="simulAn"
	            fullWidth
	            margin="normal"
	            name="simulAn"
	            value={this.state.simulAn}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="mediaFaturamentoSim"
	            fullWidth
	            margin="normal"
	            name="mediaFaturamentoSim"
	            value={this.state.mediaFaturamentoSim}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="pais"
	            fullWidth
	            margin="normal"
	            name="pais"
	            value={this.state.pais}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="classFun"
	            fullWidth
	            margin="normal"
	            name="classFun"
	            value={this.state.classFun}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="prevProxVenda"
	            fullWidth
	            margin="normal"
	            name="prevProxVenda"
	            value={this.state.prevProxVenda}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="classParc"
	            fullWidth
	            margin="normal"
	            name="classParc"
	            value={this.state.classParc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codcli"
	            fullWidth
	            margin="normal"
	            name="codcli"
	            value={this.state.codcli}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="classificacao"
	            fullWidth
	            margin="normal"
	            name="classificacao"
	            value={this.state.classificacao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="trabEmpresa"
	            fullWidth
	            margin="normal"
	            name="trabEmpresa"
	            value={this.state.trabEmpresa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="trabDtAdmissao"
	            fullWidth
	            margin="normal"
	            name="trabDtAdmissao"
	            value={this.state.trabDtAdmissao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="trabEndereco"
	            fullWidth
	            margin="normal"
	            name="trabEndereco"
	            value={this.state.trabEndereco}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="trabCargo"
	            fullWidth
	            margin="normal"
	            name="trabCargo"
	            value={this.state.trabCargo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="trabSalario"
	            fullWidth
	            margin="normal"
	            name="trabSalario"
	            value={this.state.trabSalario}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cobrEndereco"
	            fullWidth
	            margin="normal"
	            name="cobrEndereco"
	            value={this.state.cobrEndereco}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cobrEnderecoNumero"
	            fullWidth
	            margin="normal"
	            name="cobrEnderecoNumero"
	            value={this.state.cobrEnderecoNumero}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cobrEnderecoComplemento"
	            fullWidth
	            margin="normal"
	            name="cobrEnderecoComplemento"
	            value={this.state.cobrEnderecoComplemento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cobrEnderecoBairro"
	            fullWidth
	            margin="normal"
	            name="cobrEnderecoBairro"
	            value={this.state.cobrEnderecoBairro}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cobrEnderecoCidade"
	            fullWidth
	            margin="normal"
	            name="cobrEnderecoCidade"
	            value={this.state.cobrEnderecoCidade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cobrEnderecoCep"
	            fullWidth
	            margin="normal"
	            name="cobrEnderecoCep"
	            value={this.state.cobrEnderecoCep}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="cobrEnderecoPontoRef"
	            fullWidth
	            margin="normal"
	            name="cobrEnderecoPontoRef"
	            value={this.state.cobrEnderecoPontoRef}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="avalNome"
	            fullWidth
	            margin="normal"
	            name="avalNome"
	            value={this.state.avalNome}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="avalRg"
	            fullWidth
	            margin="normal"
	            name="avalRg"
	            value={this.state.avalRg}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="avalCpf"
	            fullWidth
	            margin="normal"
	            name="avalCpf"
	            value={this.state.avalCpf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="avalDtNasc"
	            fullWidth
	            margin="normal"
	            name="avalDtNasc"
	            value={this.state.avalDtNasc}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="avalEndereco"
	            fullWidth
	            margin="normal"
	            name="avalEndereco"
	            value={this.state.avalEndereco}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="avalTelefoneDdd"
	            fullWidth
	            margin="normal"
	            name="avalTelefoneDdd"
	            value={this.state.avalTelefoneDdd}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="avalTelefone"
	            fullWidth
	            margin="normal"
	            name="avalTelefone"
	            value={this.state.avalTelefone}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="avalCargoProfissao"
	            fullWidth
	            margin="normal"
	            name="avalCargoProfissao"
	            value={this.state.avalCargoProfissao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="avalEmpresa"
	            fullWidth
	            margin="normal"
	            name="avalEmpresa"
	            value={this.state.avalEmpresa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="avalSalario"
	            fullWidth
	            margin="normal"
	            name="avalSalario"
	            value={this.state.avalSalario}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="refPes1Nome"
	            fullWidth
	            margin="normal"
	            name="refPes1Nome"
	            value={this.state.refPes1Nome}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="refPes1Telefones"
	            fullWidth
	            margin="normal"
	            name="refPes1Telefones"
	            value={this.state.refPes1Telefones}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="refPes1Endereco"
	            fullWidth
	            margin="normal"
	            name="refPes1Endereco"
	            value={this.state.refPes1Endereco}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="refPes2Nome"
	            fullWidth
	            margin="normal"
	            name="refPes2Nome"
	            value={this.state.refPes2Nome}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="refPes2Telefones"
	            fullWidth
	            margin="normal"
	            name="refPes2Telefones"
	            value={this.state.refPes2Telefones}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="refPes2Endereco"
	            fullWidth
	            margin="normal"
	            name="refPes2Endereco"
	            value={this.state.refPes2Endereco}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="refCom1Estabelecimento"
	            fullWidth
	            margin="normal"
	            name="refCom1Estabelecimento"
	            value={this.state.refCom1Estabelecimento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="refCom1DtCompra"
	            fullWidth
	            margin="normal"
	            name="refCom1DtCompra"
	            value={this.state.refCom1DtCompra}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="refCom1VlrCompra"
	            fullWidth
	            margin="normal"
	            name="refCom1VlrCompra"
	            value={this.state.refCom1VlrCompra}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="refCom1QtdeParcelas"
	            fullWidth
	            margin="normal"
	            name="refCom1QtdeParcelas"
	            value={this.state.refCom1QtdeParcelas}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="refCom2Estabelecimento"
	            fullWidth
	            margin="normal"
	            name="refCom2Estabelecimento"
	            value={this.state.refCom2Estabelecimento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="refCom2DtCompra"
	            fullWidth
	            margin="normal"
	            name="refCom2DtCompra"
	            value={this.state.refCom2DtCompra}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="refCom2VlrCompra"
	            fullWidth
	            margin="normal"
	            name="refCom2VlrCompra"
	            value={this.state.refCom2VlrCompra}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="refCom2QtdeParcelas"
	            fullWidth
	            margin="normal"
	            name="refCom2QtdeParcelas"
	            value={this.state.refCom2QtdeParcelas}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="grupo"
	            fullWidth
	            margin="normal"
	            name="grupo"
	            value={this.state.grupo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codpessoagrupo"
	            fullWidth
	            margin="normal"
	            name="codpessoagrupo"
	            value={this.state.codpessoagrupo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codveiculomarca"
	            fullWidth
	            margin="normal"
	            name="codveiculomarca"
	            value={this.state.codveiculomarca}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codveiculomodelo"
	            fullWidth
	            margin="normal"
	            name="codveiculomodelo"
	            value={this.state.codveiculomodelo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codveiculocor"
	            fullWidth
	            margin="normal"
	            name="codveiculocor"
	            value={this.state.codveiculocor}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="veiAno"
	            fullWidth
	            margin="normal"
	            name="veiAno"
	            value={this.state.veiAno}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="mesReajuste"
	            fullWidth
	            margin="normal"
	            name="mesReajuste"
	            value={this.state.mesReajuste}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="usuario"
	            fullWidth
	            margin="normal"
	            name="usuario"
	            value={this.state.usuario}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="skype"
	            fullWidth
	            margin="normal"
	            name="skype"
	            value={this.state.skype}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="msn"
	            fullWidth
	            margin="normal"
	            name="msn"
	            value={this.state.msn}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="paypal"
	            fullWidth
	            margin="normal"
	            name="paypal"
	            value={this.state.paypal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codpais"
	            fullWidth
	            margin="normal"
	            name="codpais"
	            value={this.state.codpais}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="provincia"
	            fullWidth
	            margin="normal"
	            name="provincia"
	            value={this.state.provincia}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="nivel"
	            fullWidth
	            margin="normal"
	            name="nivel"
	            value={this.state.nivel}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="pontos"
	            fullWidth
	            margin="normal"
	            name="pontos"
	            value={this.state.pontos}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="pontosRecebidos"
	            fullWidth
	            margin="normal"
	            name="pontosRecebidos"
	            value={this.state.pontosRecebidos}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="comissao1"
	            fullWidth
	            margin="normal"
	            name="comissao1"
	            value={this.state.comissao1}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="comissao2"
	            fullWidth
	            margin="normal"
	            name="comissao2"
	            value={this.state.comissao2}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="comissao3"
	            fullWidth
	            margin="normal"
	            name="comissao3"
	            value={this.state.comissao3}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="comissao4"
	            fullWidth
	            margin="normal"
	            name="comissao4"
	            value={this.state.comissao4}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="comissao5"
	            fullWidth
	            margin="normal"
	            name="comissao5"
	            value={this.state.comissao5}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codtransportadora"
	            fullWidth
	            margin="normal"
	            name="codtransportadora"
	            value={this.state.codtransportadora}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="login"
	            fullWidth
	            margin="normal"
	            name="login"
	            value={this.state.login}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="senha"
	            fullWidth
	            margin="normal"
	            name="senha"
	            value={this.state.senha}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="fase"
	            fullWidth
	            margin="normal"
	            name="fase"
	            value={this.state.fase}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codpessoaempresa"
	            fullWidth
	            margin="normal"
	            name="codpessoaempresa"
	            value={this.state.codpessoaempresa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codcargo"
	            fullWidth
	            margin="normal"
	            name="codcargo"
	            value={this.state.codcargo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codformapagamento"
	            fullWidth
	            margin="normal"
	            name="codformapagamento"
	            value={this.state.codformapagamento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codrotaentrega"
	            fullWidth
	            margin="normal"
	            name="codrotaentrega"
	            value={this.state.codrotaentrega}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="animalRaca"
	            fullWidth
	            margin="normal"
	            name="animalRaca"
	            value={this.state.animalRaca}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="animalProprietario"
	            fullWidth
	            margin="normal"
	            name="animalProprietario"
	            value={this.state.animalProprietario}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="animalNomeVeter"
	            fullWidth
	            margin="normal"
	            name="animalNomeVeter"
	            value={this.state.animalNomeVeter}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="animalCor"
	            fullWidth
	            margin="normal"
	            name="animalCor"
	            value={this.state.animalCor}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="animalRacao"
	            fullWidth
	            margin="normal"
	            name="animalRacao"
	            value={this.state.animalRacao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="animal"
	            fullWidth
	            margin="normal"
	            name="animal"
	            value={this.state.animal}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codsoftware"
	            fullWidth
	            margin="normal"
	            name="codsoftware"
	            value={this.state.codsoftware}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="classInseminador"
	            fullWidth
	            margin="normal"
	            name="classInseminador"
	            value={this.state.classInseminador}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="responsavelCpf"
	            fullWidth
	            margin="normal"
	            name="responsavelCpf"
	            value={this.state.responsavelCpf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="responsavelNome"
	            fullWidth
	            margin="normal"
	            name="responsavelNome"
	            value={this.state.responsavelNome}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="revisao"
	            fullWidth
	            margin="normal"
	            name="revisao"
	            value={this.state.revisao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dadosBancarios"
	            fullWidth
	            margin="normal"
	            name="dadosBancarios"
	            value={this.state.dadosBancarios}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dataDll"
	            fullWidth
	            margin="normal"
	            name="dataDll"
	            value={this.state.dataDll}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="consultaDll"
	            fullWidth
	            margin="normal"
	            name="consultaDll"
	            value={this.state.consultaDll}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="banFavorecido"
	            fullWidth
	            margin="normal"
	            name="banFavorecido"
	            value={this.state.banFavorecido}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="banBanco"
	            fullWidth
	            margin="normal"
	            name="banBanco"
	            value={this.state.banBanco}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="banAgencia"
	            fullWidth
	            margin="normal"
	            name="banAgencia"
	            value={this.state.banAgencia}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="banContaCorrente"
	            fullWidth
	            margin="normal"
	            name="banContaCorrente"
	            value={this.state.banContaCorrente}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="banCpfcnpjFavorecido"
	            fullWidth
	            margin="normal"
	            name="banCpfcnpjFavorecido"
	            value={this.state.banCpfcnpjFavorecido}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codescola"
	            fullWidth
	            margin="normal"
	            name="codescola"
	            value={this.state.codescola}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codtrecho"
	            fullWidth
	            margin="normal"
	            name="codtrecho"
	            value={this.state.codtrecho}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="coddepartamento"
	            fullWidth
	            margin="normal"
	            name="coddepartamento"
	            value={this.state.coddepartamento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="preco06"
	            fullWidth
	            margin="normal"
	            name="preco06"
	            value={this.state.preco06}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="preco07"
	            fullWidth
	            margin="normal"
	            name="preco07"
	            value={this.state.preco07}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="preco08"
	            fullWidth
	            margin="normal"
	            name="preco08"
	            value={this.state.preco08}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="subfornecedor"
	            fullWidth
	            margin="normal"
	            name="subfornecedor"
	            value={this.state.subfornecedor}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codcentcusto"
	            fullWidth
	            margin="normal"
	            name="codcentcusto"
	            value={this.state.codcentcusto}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="emailNfe"
	            fullWidth
	            margin="normal"
	            name="emailNfe"
	            value={this.state.emailNfe}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="sistemaExclusivo"
	            fullWidth
	            margin="normal"
	            name="sistemaExclusivo"
	            value={this.state.sistemaExclusivo}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="versao"
	            fullWidth
	            margin="normal"
	            name="versao"
	            value={this.state.versao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="pafversao"
	            fullWidth
	            margin="normal"
	            name="pafversao"
	            value={this.state.pafversao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="asoProprio"
	            fullWidth
	            margin="normal"
	            name="asoProprio"
	            value={this.state.asoProprio}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="responsavel"
	            fullWidth
	            margin="normal"
	            name="responsavel"
	            value={this.state.responsavel}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="naturalidadeUf"
	            fullWidth
	            margin="normal"
	            name="naturalidadeUf"
	            value={this.state.naturalidadeUf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="escolaAno"
	            fullWidth
	            margin="normal"
	            name="escolaAno"
	            value={this.state.escolaAno}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="escolaCidade"
	            fullWidth
	            margin="normal"
	            name="escolaCidade"
	            value={this.state.escolaCidade}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="escolaUf"
	            fullWidth
	            margin="normal"
	            name="escolaUf"
	            value={this.state.escolaUf}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="rgOrgao"
	            fullWidth
	            margin="normal"
	            name="rgOrgao"
	            value={this.state.rgOrgao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="popular"
	            fullWidth
	            margin="normal"
	            name="popular"
	            value={this.state.popular}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="matricula"
	            fullWidth
	            margin="normal"
	            name="matricula"
	            value={this.state.matricula}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codprorhperfilescala"
	            fullWidth
	            margin="normal"
	            name="codprorhperfilescala"
	            value={this.state.codprorhperfilescala}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="dtTrabalho"
	            fullWidth
	            margin="normal"
	            name="dtTrabalho"
	            value={this.state.dtTrabalho}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codprorhescala"
	            fullWidth
	            margin="normal"
	            name="codprorhescala"
	            value={this.state.codprorhescala}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="guiaAltura"
	            fullWidth
	            margin="normal"
	            name="guiaAltura"
	            value={this.state.guiaAltura}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="guiaAlimento"
	            fullWidth
	            margin="normal"
	            name="guiaAlimento"
	            value={this.state.guiaAlimento}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="guiaEletricidade"
	            fullWidth
	            margin="normal"
	            name="guiaEletricidade"
	            value={this.state.guiaEletricidade}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.savePessoa}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddPessoaComponent);
