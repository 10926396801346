import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter
} from "react-router-dom";
import ListUserComponent from "./user/ListUserComponent";
import AddUserComponent from "./user/AddUserComponent";
import EditUserComponent from "./user/EditUserComponent";
import React from "react";

import LoginComponent from "./login/LoginComponent";

import ListResControleComponent from "./rescontroles/ListResControleComponent";
import PoseidonDashboard from "./dashboards/homeComponent";
import AddResControleComponent from "./rescontroles/AddResControleComponent";
import EditResControleComponent from "./rescontroles/EditResControleComponent";
import ListMoedaComponent from "./moedas/ListMoedaComponent";
import AddMoedaComponent from "./moedas/AddMoedaComponent";
import EditMoedaComponent from "./moedas/EditMoedaComponent";
import ListResGarconComponent from "./resgarcons/ListResGarconComponent";
import AddResGarconComponent from "./resgarcons/AddResGarconComponent";
import EditResGarconComponent from "./resgarcons/EditResGarconComponent";
import ListFormapagamentoComponent from "./formapagamentos/ListFormapagamentoComponent";
import AddFormapagamentoComponent from "./formapagamentos/AddFormapagamentoComponent";
import EditFormapagamentoComponent from "./formapagamentos/EditFormapagamentoComponent";
import ListCentcustoComponent from "./centcustos/ListCentcustoComponent";
import AddCentcustoComponent from "./centcustos/AddCentcustoComponent";
import EditCentcustoComponent from "./centcustos/EditCentcustoComponent";
import ListProdutoUnidadeComponent from "./produtounidades/ListProdutoUnidadeComponent";
import AddProdutoUnidadeComponent from "./produtounidades/AddProdutoUnidadeComponent";
import EditProdutoUnidadeComponent from "./produtounidades/EditProdutoUnidadeComponent";
import ListProdutoGrupoComponent from "./produtogrupos/ListProdutoGrupoComponent";
import AddProdutoGrupoComponent from "./produtogrupos/AddProdutoGrupoComponent";
import EditProdutoGrupoComponent from "./produtogrupos/EditProdutoGrupoComponent";
import ListProdutoComponent from "./produtos/ListProdutoComponent";
import AddProdutoComponent from "./produtos/AddProdutoComponent";
import EditProdutoComponent from "./produtos/EditProdutoComponent";
import ListPessoaGrupoComponent from "./pessoagrupos/ListPessoaGrupoComponent";
import AddPessoaGrupoComponent from "./pessoagrupos/AddPessoaGrupoComponent";
import EditPessoaGrupoComponent from "./pessoagrupos/EditPessoaGrupoComponent";
import ListPessoaComponent from "./pessoas/ListPessoaComponent";
import AddPessoaComponent from "./pessoas/AddPessoaComponent";
import EditPessoaComponent from "./pessoas/EditPessoaComponent";
import ListBancoComponent from "./bancos/ListBancoComponent";
import AddBancoComponent from "./bancos/AddBancoComponent";
import EditBancoComponent from "./bancos/EditBancoComponent";
import ListConfiguracaoGeralComponent from "./configuracaogerals/ListConfiguracaoGeralComponent";
import AddConfiguracaoGeralComponent from "./configuracaogerals/AddConfiguracaoGeralComponent";
import EditConfiguracaoGeralComponent from "./configuracaogerals/EditConfiguracaoGeralComponent";
import ListParGeralComponent from "./pargerals/ListParGeralComponent";
import AddParGeralComponent from "./pargerals/AddParGeralComponent";
import EditParGeralComponent from "./pargerals/EditParGeralComponent";
import ListParRestauranteComponent from "./parrestaurantes/ListParRestauranteComponent";
import AddParRestauranteComponent from "./parrestaurantes/AddParRestauranteComponent";
import EditParRestauranteComponent from "./parrestaurantes/EditParRestauranteComponent";
import ListParVendaComponent from "./parvendas/ListParVendaComponent";
import AddParVendaComponent from "./parvendas/AddParVendaComponent";
import EditParVendaComponent from "./parvendas/EditParVendaComponent";
import ListPedvendaComponent from "./pedvendas/ListPedvendaComponent";
import AddPedvendaComponent from "./pedvendas/AddPedvendaComponent";
import EditPedvendaComponent from "./pedvendas/EditPedvendaComponent";
import ListPedvendaItemComponent from "./pedvendaitems/ListPedvendaItemComponent";
import AddPedvendaItemComponent from "./pedvendaitems/AddPedvendaItemComponent";
import EditPedvendaItemComponent from "./pedvendaitems/EditPedvendaItemComponent";
import ListPedvendaFechamentoComponent from "./pedvendafechamentos/ListPedvendaFechamentoComponent";
import AddPedvendaFechamentoComponent from "./pedvendafechamentos/AddPedvendaFechamentoComponent";
import EditPedvendaFechamentoComponent from "./pedvendafechamentos/EditPedvendaFechamentoComponent";
import ListCfopComponent from "./cfops/ListCfopComponent";
import AddCfopComponent from "./cfops/AddCfopComponent";
import EditCfopComponent from "./cfops/EditCfopComponent";
import GeneralSettings from "./settings/generalSettings";
import AlterarSenha from "./settings/alterarSenha";
import AdicionarUsuario from "./settings/adicionarUsuario";
import EditarUsuario from "./settings/editarUsuario";

import { connect } from "react-redux";
import PrivateRoute from "./PrivateRoute";

class AppRouter extends React.Component {
  render() {
    return (
      <div style={style}>
        <Router>
          <Switch>
            <Route path="/" exact component={LoginComponent} />
            <PrivateRoute
              path="/users"
              component={ListUserComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-user"
              component={AddUserComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-user"
              component={EditUserComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/rescontroles"
              component={ListResControleComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/dashboards"
              component={PoseidonDashboard}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-rescontroles"
              component={AddResControleComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-rescontroles"
              component={EditResControleComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/moedas"
              component={ListMoedaComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-moedas"
              component={AddMoedaComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-moedas"
              component={EditMoedaComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/resgarcons"
              component={ListResGarconComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-resgarcons"
              component={AddResGarconComponent}
            />
            <PrivateRoute
              path="/edit-resgarcons"
              component={EditResGarconComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/formapagamentos"
              component={ListFormapagamentoComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-formapagamentos"
              component={AddFormapagamentoComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-formapagamentos"
              component={EditFormapagamentoComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/centcustos"
              component={ListCentcustoComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-centcustos"
              component={AddCentcustoComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-centcustos"
              component={EditCentcustoComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/produtounidades"
              component={ListProdutoUnidadeComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-produtounidades"
              component={AddProdutoUnidadeComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-produtounidades"
              component={EditProdutoUnidadeComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/produtogrupos"
              component={ListProdutoGrupoComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-produtogrupos"
              component={AddProdutoGrupoComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-produtogrupos"
              component={EditProdutoGrupoComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/produtos"
              component={ListProdutoComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-produtos"
              component={AddProdutoComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-produtos"
              component={EditProdutoComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/pessoagrupos"
              component={ListPessoaGrupoComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-pessoagrupos"
              component={AddPessoaGrupoComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-pessoagrupos"
              component={EditPessoaGrupoComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/pessoas"
              component={ListPessoaComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-pessoas"
              component={AddPessoaComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-pessoas"
              component={EditPessoaComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/bancos"
              component={ListBancoComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-bancos"
              component={AddBancoComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-bancos"
              component={EditBancoComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/configuracaogerals"
              component={ListConfiguracaoGeralComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-configuracaogerals"
              component={AddConfiguracaoGeralComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-configuracaogerals"
              component={EditConfiguracaoGeralComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/pargerals"
              component={ListParGeralComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-pargerals"
              component={AddParGeralComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-pargerals"
              component={EditParGeralComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/parrestaurantes"
              component={ListParRestauranteComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-parrestaurantes"
              component={AddParRestauranteComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-parrestaurantes"
              component={EditParRestauranteComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/parvendas"
              component={ListParVendaComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-parvendas"
              component={AddParVendaComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-parvendas"
              component={EditParVendaComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/pedvendas"
              component={ListPedvendaComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-pedvendas"
              component={AddPedvendaComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-pedvendas"
              component={EditPedvendaComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/pedvendaitems"
              component={ListPedvendaItemComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-pedvendaitems"
              component={AddPedvendaItemComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-pedvendaitems"
              component={EditPedvendaItemComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/pedvendafechamentos"
              component={ListPedvendaFechamentoComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-pedvendafechamentos"
              component={AddPedvendaFechamentoComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-pedvendafechamentos"
              component={EditPedvendaFechamentoComponent}
              auth={this.props.auth}
            />{" "}
            <PrivateRoute
              path="/cfops"
              component={ListCfopComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/add-cfops"
              component={AddCfopComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/edit-cfops"
              component={EditCfopComponent}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/configuracoes"
              component={GeneralSettings}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/alterarsenha"
              component={AlterarSenha}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/adicionarusuario"
              component={AdicionarUsuario}
              auth={this.props.auth}
            />
            <PrivateRoute
              path="/editarusuario"
              component={EditarUsuario}
              auth={this.props.auth}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

const style = {
  marginTop: "0px"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps)(AppRouter));
