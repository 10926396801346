import React, { Component } from "react";
import PedvendaFechamentoService from "../../service/PedvendaFechamentoService";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { Container } from "../ui/base";

class AddPedvendaFechamentoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codpedvenda: "",
      codmoeda: "",
      vlr: "",
      codcaixaabertura: "",
      posNrAutorizacao: "",
      posPessoa: "",
      qtde: "",
      revisao: "",
      codempresa: "",
      usuario: "",
      troco: "",
      message: null
    };
    this.savePedvendaFechamento = this.savePedvendaFechamento.bind(this);
  }

  savePedvendaFechamento = e => {
    e.preventDefault();
    let pedvendaFechamentos = {
      codpedvenda: this.state.codpedvenda,
      codmoeda: this.state.codmoeda,
      vlr: this.state.vlr,
      codcaixaabertura: this.state.codcaixaabertura,
      posNrAutorizacao: this.state.posNrAutorizacao,
      posPessoa: this.state.posPessoa,
      qtde: this.state.qtde,
      revisao: this.state.revisao,
      codempresa: this.state.codempresa,
      usuario: this.state.usuario,
      troco: this.state.troco,
    };
    PedvendaFechamentoService.addPedvendaFechamento(pedvendaFechamentos).then(res => {
      this.setState({ message: "Adicionado com sucesso." });
      this.props.history.push("/pedvendafechamentos");
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div>
        <Container>
	        <Typography variant="h4" style={style}>
	          Adicionar PedvendaFechamento
	        </Typography>
	        <form style={formContainer}>
	          <TextField
	            type="text"
	            label="codpedvenda"
	            fullWidth
	            margin="normal"
	            name="codpedvenda"
	            value={this.state.codpedvenda}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codmoeda"
	            fullWidth
	            margin="normal"
	            name="codmoeda"
	            value={this.state.codmoeda}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="vlr"
	            fullWidth
	            margin="normal"
	            name="vlr"
	            value={this.state.vlr}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codcaixaabertura"
	            fullWidth
	            margin="normal"
	            name="codcaixaabertura"
	            value={this.state.codcaixaabertura}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="posNrAutorizacao"
	            fullWidth
	            margin="normal"
	            name="posNrAutorizacao"
	            value={this.state.posNrAutorizacao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="posPessoa"
	            fullWidth
	            margin="normal"
	            name="posPessoa"
	            value={this.state.posPessoa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="qtde"
	            fullWidth
	            margin="normal"
	            name="qtde"
	            value={this.state.qtde}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="revisao"
	            fullWidth
	            margin="normal"
	            name="revisao"
	            value={this.state.revisao}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="codempresa"
	            fullWidth
	            margin="normal"
	            name="codempresa"
	            value={this.state.codempresa}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="text"
	            label="usuario"
	            fullWidth
	            margin="normal"
	            name="usuario"
	            value={this.state.usuario}
	            onChange={this.onChange}
	          />
	          <TextField
	            type="number"
	            label="troco"
	            fullWidth
	            margin="normal"
	            name="troco"
	            value={this.state.troco}
	            onChange={this.onChange}
	          />
	          <Button variant="contained" color="primary" onClick={this.savePedvendaFechamento}>
	            Salvar
	          </Button>
	        </form>
        </Container>
      </div>
    );
  }
}
const formContainer = {
  display: "flex",
  flexFlow: "row wrap"
};

const style = {
  display: "flex",
  justifyContent: "center"
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AddPedvendaFechamentoComponent);
