import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

const token = localStorage.getItem("userInfo");
const Headers = { headers: { Authorization: "Bearer " + token } };

class ParRestauranteService {
  fetchParRestaurantes() {
    return axios.get(config.baseURL + '/parrestaurantes/', Headers);
  }

  fetchParRestauranteById(codparrestaurante) {
    return axios.get(config.baseURL + '/parrestaurantes/' + codparrestaurante, Headers);
  }

  deleteParRestaurante(codparrestaurante) {
    return axios.delete(config.baseURL + '/parrestaurantes/' + codparrestaurante, Headers);
  }

  addParRestaurante(parRestaurante) {
    return axios.post(config.baseURL + '/parrestaurantes/', parRestaurante, Headers);
  }

  editParRestaurante(parRestaurante) {
    return axios.put(config.baseURL + '/parrestaurantes/' + parRestaurante.codparrestaurante, parRestaurante, Headers);
  }
}

export default new ParRestauranteService();