import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Container } from "../ui/base";
import { isMobile } from "react-device-detect";
import moment from "moment";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import InputMask from "react-input-mask";
import * as UsersActions from "../../redux/actions/user";

export default function EditaUsuario() {
  const userInfo = useSelector(state => state.user.payload);
  const userEdited = useSelector(state => state.user.status);
  const userEditedMessage = useSelector(state => state.user.message);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cellphone, setCellphone] = useState("");

  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (userInfo) {
      setId(userInfo.id);
      setName(userInfo.name);
      setEmail(userInfo.email);
      setCellphone(userInfo.cellphone);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userEdited === true) {
      history.push("/users");
    } else if (userEdited === false) {
      setMessage(userEditedMessage);
    } else {
      setMessage("");
    }
  }, [userEdited]);

  function handleChangeEmail(e) {
    setEmail(e.target.value);
  }
  function handleChangeCell(e) {
    setCellphone(e.target.value);
  }
  function handleChangeName(e) {
    setName(e.target.value);
  }

  function justDoIt() {
    if (email === "") {
      setMessage("Endereço de email inválido");
      return;
    }
    if (name.length < 1) {
      setMessage("Nome inválido");
      return;
    }
    if (cellphone.length <= 9) {
      setMessage("Número de celular inválido.");
      return;
    }
    const userData = { id, name, email, cellphone };

    dispatch(UsersActions.editUserRequest(userData));
  }

  const styles = {
    titCard: {
      fontSize: "14px",
      fontWeight: "600",
      fontFamily: "Poppins"
    },
    style: {
      display: "flex",
      justifyContent: "center",
      fontFamily: "Poppins",
      fontWeight: "bold",
      margin: "50px auto 10px",
      color: "#5a9495"
    },
    center: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      margin: "10px",
      width: "95%",
      backgroundColor: "#5a9495",
      maxWidth: "375px"
    },
    buttonToken: {
      width: "30%",
      height: "100%",
      backgroundColor: "#5a9495",
      maxWidth: "375px",
      margin: "auto"
    },
    notification: {
      display: "flex",
      justifyContent: "center",
      color: "#dc3545",
      minHeight: "10px"
    },
    card: {
      minWidth: 275,
      maxWidth: 600,
      margin: "20px auto 10px",
      padding: "10px",
      background: "#FFF"
    },
    pos: {
      marginBottom: 12
    },
    textField: {
      backgroundColor: "none",
      width: "95%",
      margin: "20px 10px"
    },
    textFieldToken: {
      backgroundColor: "none",
      width: "60%",
      margin: "20px 10px"
    },
    input: {
      backgroundColor: "none"
    },
    margin: {
      margin: "5% auto",
      width: "100%"
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
      backgroundSize: "contain",
      margin: "50px"
    }
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h3" style={styles.style}>
            Editar Usuário
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Card style={styles.card}>
            <CardContent>
              <form>
                <input type="hidden" value="prayer" />

                <TextField
                  style={styles.textField}
                  type="text"
                  label="NOME"
                  margin="normal"
                  name="email"
                  autoComplete="new-password"
                  value={name}
                  onChange={handleChangeName}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Nome do usuário"
                />
                <TextField
                  style={styles.textField}
                  type="text"
                  label="E-MAIL"
                  margin="normal"
                  name="email"
                  autoComplete="new-password"
                  value={email}
                  onChange={handleChangeEmail}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Email do usuário"
                />
                <InputMask
                  mask="(99) 99999 9999"
                  value={cellphone}
                  onChange={handleChangeCell}
                  style={styles.textField}
                >
                  {() => (
                    <TextField
                      label="CELULAR"
                      name="cellphone"
                      style={styles.textField}
                      margin="normal"
                      type="text"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIphoneIcon />
                          </InputAdornment>
                        )
                      }}
                      placeholder="Número do celular"
                    />
                  )}
                </InputMask>

                <Box display="flex" justifyContent="center"></Box>

                <h5 style={styles.notification}>{message}</h5>
                <Box display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => justDoIt()}
                    style={styles.button}
                    size="large"
                  >
                    SALVAR
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
