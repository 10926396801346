import axios from 'axios';
import { Config } from '../constants/config';
const config = Config;

class SessionService {

  login(credentials) {
    console.log(credentials);
    return axios.post(config.baseURL + "/sessions/", credentials);
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem("userInfo"));
  }

  getAuthHeader() {
    return { headers: { Authorization: 'Bearer ' + this.getUserInfo().token } };
  }

  logOut() {
    localStorage.removeItem("userInfo");
    return axios.post(config.baseURL + '/logout/', {}, this.getAuthHeader());
  }
}

export default new SessionService();